import React from 'react';
import BatteryGauge from 'react-battery-gauge'
// STATE



const BatteryInfoDisplay: React.FC = ({connectionsData }) => {


    const custom = {
        batteryBody: {
            strokeWidth: 2
        },
        batteryCap: {
            strokeWidth: 2
        },
        batteryMeter: {
            fill: '#00BF00',
            lowBatteryValue: 3,
            lowBatteryFill: 'red',
            outerGap: 1,
            noOfCells: 1,
            interCellsGap: 1
        },
        readingText: {
            darkContrastColor: 'black'
        }
    }



    return (
        <div>
            <h5 style={{ textAlign: "center" }}><b>Battery Voltage</b></h5>
        <div style={{ display: "flex", justifyContent: "space-evenly", marginBottom: "4%" }}>
            <div style={{width: "40%"} }>
                
                    <div style={{ display: "flex", justifyContent: "center", marginBottom: "5px" } }>
                        <b>{connectionsData.length > 0  ? `${connectionsData[0].readBatteryChannel}V` : `Loading...` }</b>
                    </div>
            </div>
            </div>
        </div>
    );
};

export default BatteryInfoDisplay;
