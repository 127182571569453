import React, { ReactNode } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { withStyles, createStyles } from '@mui/styles';
import ApiFailed from '../../Utils/ApiFailed';
import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';
import ClipLoader from "react-spinners/ClipLoader";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from "@mui/material/IconButton";

import MyLocationIcon from '@mui/icons-material/MyLocation';
import { Row, Col } from 'reactstrap';
import WebMapView from "components/Map/WebMapView";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Map from 'devextreme-react/map';
import { Accordion, Typography } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Loggers from "@mui/icons-material/SettingsRemote";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
//import ReadingsPanel from "../../components/Readings/ReadingsPanel";
import MultiLoggerGraph from '../Groups/MultiLoggerGraph';
import { APILoggerDetail, buildAPILoggerDetail } from 'models/APILoggerDetail';
import { APIGetSitesLoggersModel } from "models/APIGetSitesLoggersModel";
import { APIGetLoggerReadingsModel } from "models/APIGetLoggerReadingsModel";


import BasicSummary from "components/DeviceSummary/BasicSummary";
import TextlogSummary from "components/DeviceSummary/TextlogSummary";
import SWGSummary from "components/DeviceSummary/SWGSummary";
import HYGSummary from "components/DeviceSummary/HYGSummary";
import TORSummary from "components/DeviceSummary/TORSummary";
import EASitePanel from '../../components/EA/EASitePanel';
import RainfallPanel from '../../components/EA/RainfallPanel';
import LCLSummary from "components/DeviceSummary/LCLSummary";
import ImagesPanel from 'components/ImageUpload/ImagesPanel'

import SiteAlarmsPanel from "components/Alarms/SiteAlarmsPanel";

import { isLCLLogger } from 'variables/chariotProductIds';



const styles: Record<string, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        color: "#FFFFFF",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);


// NOTE: CharIots detected by isLCLogger
const deviceSummaries = {
    unknown: BasicSummary,
    textlog: TextlogSummary,
    hyg: HYGSummary,
    tor: TORSummary,
    swg: SWGSummary,
    'tor-n': TORSummary,
    'tor-r': TORSummary,
    'tor-s': TORSummary,
};

interface Site {
    id: number;
    name: string;
    ref: string;
    created: Date;
    deleted: Date | null;
    latitude: number | null;
    longitude: number | null;
    groups: Array<{ id: number; displayName: string }>;
    defaultSite: boolean;
    fkCompanyId: number;
    lastUpdate: Date | null;
    loggers: Array<APILoggerDetail>;
    fkEASiteId: number | null;
}

interface ReadingsState {
    state: {
        loading: boolean;
        readingsData: Array<APIGetLoggerReadingsModel>;
    };
}

interface EASite {
    id: number;
    eaId: string;
    name: string;
    eARegion: string;
    gridReference: string;
    latitude: number;
    longitude: number;
    eAlabel: string;
    measure: string;
    notation: string;
    lastReading: Date | undefined;
    parameterName: string;
    unitName: string;
    period: number;
    active: boolean;
    distance: number;
}


interface Point {
    id: string;
    name: string;
    coordinates: Array<number>;
}

interface Props {
    classes: {
        root: string;
        heading: string;
        cardTitleWhite: string;
    };
    history: {
        goBack: () => void;
    };
    location: {
        state: {
            siteId: number;
            siteName: string;
        };
        search: string;
    };

}

interface State {
    loading: boolean;
    authorized: boolean;
    tableHidden: boolean;
    showAlarmOverlay: boolean;
    site: APIGetSitesLoggersModel | undefined;
    logreadings: Array<APIGetLoggerReadingsModel>;
    loggerType: string;
    eaSites: Array<EASite>;
    startDate: Date;
    endDate: Date;
    loggerIds: number[];
    imageCount: number;
    mapKey: string;
}

export class SiteDetail extends React.Component<Props, State> {

    // REFs to the channel reading components for Data export
    ChannelMLGReadings: React.RefObject<ReadingsState>;

    timer: any = null;     // Check for all readings loaded ready for export

    constructor(props: Readonly<Props>) {
        super(props);

        const endDate = new Date();
        const startDate = new Date();
        startDate.setDate(endDate.getDate() - 7);

        this.state = {
            authorized: true,
            tableHidden: true,
            showAlarmOverlay: false,
            site: undefined,
            loading: true,
            logreadings: [],
            loggerType: "unknown",
            eaSites: [],
            startDate: startDate,
            endDate: endDate,
            loggerIds: [],
            imageCount: 0,
            mapKey: ""
        };
    }

    createSuccessNotification = (): void => {
        NotificationManager.success('Saved Changes', 'Success')

    };

    createErrorNotification = (): void => {
        NotificationManager.error('Error Saving Changes', 'Click me!', 5000, () => {
            alert('callback');
        });
    };

    componentDidMount(): void {
        //get detail on logger here

        const me = this;
        CallGetAPI(CreateUrl('/api/aquaguard/mapkey'), {})
            .then(data => {
                this.setState({ mapKey: data })
                CallGetAPI(CreateUrl('/api/aquaguard/site?id=' + me.props.location.state.siteId), {})
                    .then(data => {

                        const loggers: Array<APILoggerDetail> = [];
                        data.loggers.forEach((element: any) => {
                            loggers.push(buildAPILoggerDetail(element));
                        });

                        const rec: APIGetSitesLoggersModel = {
                            SiteId: data.siteId,
                            SiteNameUserFriendly: data.siteNameUserFriendly ? data.siteNameUserFriendly : data.siteName,
                            SiteName: data.siteName,
                            Created: data.created,
                            Deleted: data.deleted,
                            Latitude: data.latitude,
                            Longitude: data.longitude,
                            FkCompanyId: data.fkCompanyId,
                            LastUpdate: data.lastUpdate,
                            DefaultSite: data.defaultSite,
                            Loggers: loggers,
                            FkEASiteId: data.fkEASiteId,
                            AlarmsEnabled: data.alarmsEnabled,
                            AlarmState: data.alarmState,
                        };

                        const loggerIds: Array<number> = [];
                        rec.Loggers.map((l) => loggerIds.push(l.LoggerId));

                        me.setState(
                            {
                                site: rec,
                                loggerIds: loggerIds,
                                loading: false,
                                tableHidden: false,
                            })
                        console.log(data)

                    })
                    .catch(function () {
                        me.setState(
                            {
                                authorized: false,
                                loading: false,
                            })
                    });

                CallGetAPI(CreateUrl('/api/aquaguard/ImageCount?siteId=' + me.props.location.state.siteId), {})
                    .then(data => {

                        me.setState(
                            {
                                imageCount: data,
                            })
                        console.log(data)

                    })
                    .catch(function () {
                        me.setState(
                            {
                                imageCount: 0,
                            })
                    });
                /*
                CallGetAPI(CreateUrl('/api/aquaguard/GetLogreadingsBySite?siteId=' + me.props.location.state.siteId), {})
                    .then(data => {
                           
                        me.setState(
                            {
                                logreadings: data,
                                loading: false,
                                tableHidden: false,
                            })
                        console.log(data)
                               
                    })
                    .catch(function () {
                        me.setState(
                            {
                                authorized: false,
                                loading: false,
                            })
                   });
                    */
            })
            }
    

    // Function callback from EAPanel when EAsite details loaded (for plotting on map)
    eaCallback = (eaSites: EASite[]): void => {
        this.setState({ eaSites: eaSites });
    }

    selectEAsite = (site: EASite | null): void => {

        const me = this;

        if (site == null) {
            site = {
                id: 0,
                eaId: "REMOVE",
                name: "",
                eARegion: "",
                gridReference: "",
                latitude: 0,
                longitude: 0,
                eAlabel: "",
                measure: "",
                notation: "",
                lastReading: undefined,
                parameterName: "",
                unitName: "",
                period: 0,
                active: false,
                distance: 0,
            };
        }

        //save EAsite
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(site)
        };

        CallGetAPI(CreateUrl('/api/aquaguard/EASite/' + me.props.location.state.siteId), requestOptions)
            .then(data => {

                const tempSite = me.state.site;
                if (tempSite) {
                    if (site?.eaId != "REMOVE") {
                        tempSite.FkEASiteId = data.id;
                    }
                    else {
                        tempSite.FkEASiteId = null;
                    }
                    me.setState({ site: tempSite });
                }
            })
            .catch(function () {
                me.setState(
                    {
                        authorized: false,
                        loading: false,
                    });
            });



    }

    blankFunction = function () {
        return undefined;
    };


    render(): ReactNode {
        const { classes } = this.props;
//        const DeviceSummary = deviceSummaries[(this.state.site?.loggers[0]?.Model || 'unknown').toLowerCase()];

        const singlePoints: Array<Point> = [];

        this.state.eaSites.map((site) => {
            singlePoints.push({
                id: site.id.toString(),
                name: site.notation,
                coordinates: [site.longitude, site.latitude]

            })
        });

        // Determine alarm state by OR'ing all loggers
        let alarmState = 0;
        this.state.site?.Loggers.map((logger) => {
            alarmState = alarmState | logger.AlarmState;
        });

        return (
            <div>
                {this.state.loading &&
                    <div style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <ClipLoader
                            size={150}
                            color={"#123abc"}
                            loading={this.state.loading}
                        />
                    </div>
                }
                {!this.state.loading &&
                    <div>
                        {this.state.tableHidden &&
                                <ApiFailed />
                        }
                        {!this.state.tableHidden &&
                            <GridContainer >
                                <GridItem xs={12} sm={12} md={12}>
                                    <Card>
                                        <CardHeader color="primary" className="view view-cascade  d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
                                             <div style={{display: 'inline'}}>
                                                <IconButton
                                                    style={{display: 'inline'}}
                                                    onClick={(): void =>  this.props.history.goBack()}
                                                    size="large"><ArrowBackIcon style={{fill: "white"}}/></IconButton>
                                                <h4 style={{display: 'inline'}} className={classes.cardTitleWhite}>{this.props.location.state.siteName}</h4>
                                            </div>
                                           
                                        </CardHeader>
                                        <CardBody>
                                        {this.state.site && 
                                        <Row>
                                        <Col md={6}>
                                            {this.state.site.Latitude && this.state.site.Longitude ?
                                                            <div style={{marginBottom: "20px"} }>
                                                    
                                                    <Map
                                                        //zoom={15}
                                                        defaultZoom={15}
                                                        provider="google"
                                                        markers={[
                                                            { location: [this.state.site.Latitude, this.state.site.Longitude] }
                                                        ]}
                                                        apiKey={this.state.mapKey}
                                                        width="100%"
                                                        height={350}
                                                        controls={true}
                                                        type="hybrid"
                                                    />
                                                    
                                                </div>
                                                :
                                                <span>No location set</span>
                                            }
                                            </Col>
                                            <Col  md={6}>
                                                <List className={classes.root}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                        <Avatar>
                                                            <Loggers />
                                                        </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary="Devices" secondary={this.state.site.Loggers.length} />
                                                    </ListItem>
                                                    <Divider variant="inset" component="li" />
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                        <Avatar>
                                                            <MyLocationIcon/>
                                                        </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary="Location" secondary={'Latitude: ' + this.state.site.Latitude +  '° N, longitude: ' + this.state.site.Longitude + '° W'} />
                                                    </ListItem>
                                                    <Divider variant="inset" component="li" />
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                        <Avatar>
                                                            <NotificationsActiveIcon />
                                                        </Avatar>
                                                        </ListItemAvatar>
                                                    <ListItemText primary="Device Alarm state" secondary={alarmState} />
                                                    {this.state.site?.AlarmsEnabled != null && this.state.site?.AlarmsEnabled > 0 &&
                                                        <ListItemText primary="Site Alarm state" secondary={this.state.site?.AlarmState} />
                                                    }
                                                    </ListItem>
                                                    <Divider variant="inset" component="li" />
                                                    <ListItem>
                                                        <div>
                                                        <ListItemText primary="Linked EA site" secondary={this.state.site.FkEASiteId == null ? "Please choose" : ""} />
                                                        <EASitePanel siteId={this.state.site.SiteId}
                                                            siteLat={this.state.site.Latitude}
                                                            siteLong={this.state.site.Longitude}
                                                            eaSiteId={this.state.site.FkEASiteId}
                                                            callback={this.eaCallback}
                                                            mapSite={this.selectEAsite} />
                                                        </div>
                                                    </ListItem>
                                               </List>
                                            </Col>
                                         </Row>
                                    }
                                    {!this.state.site?.DefaultSite &&
                                        <Accordion>
                                            <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            style={{ backgroundColor: "#143C67", color: "white" }}
                                            >
                                                <Typography className={classes.heading}>Graph</Typography>
                                            </AccordionSummary>
                                                    <AccordionDetails>
                                                        <MultiLoggerGraph ref={this.ChannelMLGReadings} showDates={true} startDate={this.state.startDate} endDate={this.state.endDate} location={{ state: { groupId: 0, loggers: this.state.loggerIds, showBackButton: false, site: null } }} history={{ goBack: this.blankFunction }} />
                                            </AccordionDetails>
                                        </Accordion>
                                    }
                                    {!this.state.site?.DefaultSite && this.state.site?.FkEASiteId != null &&
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                    style={{ backgroundColor: "#143C67", color: "white" }}
                                                >
                                                    <Typography className={classes.heading}>Rainfall</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <RainfallPanel EASiteId={this.state.site?.FkEASiteId} />
                                                </AccordionDetails>
                                            </Accordion>
                                    }
                                    {this.state.site?.AlarmsEnabled != null && this.state.site?.AlarmsEnabled > 0 &&
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel3a-content"
                                                id="panel3a-header"
                                                style={{ backgroundColor: "#143C67", color: "white" }}
                                            >
                                                <Typography className={classes.heading}>Site Alarms</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <SiteAlarmsPanel siteId={this.props.location.state.siteId} />
                                            </AccordionDetails>
                                        </Accordion>
                                    }
                                    {this.state.imageCount > 0 &&
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel4a-content"
                                                id="panel4a-header"
                                                style={{ backgroundColor: "#143C67", color: "white" }}
                                            >
                                                <Typography className={classes.heading}>Site Images</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <ImagesPanel siteId={this.props.location.state.siteId} />
                                            </AccordionDetails>
                                        </Accordion>
                                    }
                                        <Accordion>
                                            <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel4a-content"
                                            id="panel4a-header"
                                            style={{ backgroundColor: "#143C67", color: "white" }}
                                            >
                                            <Typography className={classes.heading}>Devices</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <GridContainer >
                                            
                                                {this.state.site?.Loggers.map((logger: APILoggerDetail, idx:number): ReactNode => {
                                                    const DeviceSummary = deviceSummaries[(logger.Model || 'unknown').toLowerCase()];

                                                    if (isLCLLogger(logger.Model)) {
                                                        return <GridItem xs={12} sm={12} md={12} key={logger.Serial}>
                                                            <LCLSummary serial={logger.Serial} key={logger.Serial} />
                                                        </GridItem>

                                                    }
                                                    else if (DeviceSummary != undefined) {
                                                        return <GridItem xs={12} sm={12} md={12} key={logger.Serial}>
                                                            <DeviceSummary serial={logger.Serial} key={logger.Serial} />
                                                        </GridItem>
                                                    }
                                                    else {
                                                        return <div key={idx}>Unknown logger type {logger.Model}</div>
                                                    }
                                                }

                                                )}
                                                </GridContainer>
                                            </AccordionDetails>
                                        </Accordion>
                                    </CardBody>
                                </Card>
                            </GridItem>

                            </GridContainer>
                        }

                    </div>
                }
                <NotificationContainer />

            </div>
        );

    }

}

export default withStyles(style)(SiteDetail);

