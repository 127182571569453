//const lclArray = ['LCL', 'CHR', 'CHFL', 'CH', 'CHRT', 'CHF1', 'CHF2', 'CH1P', 'CH2P', 'CHAR-E-F', 'CHAR-E-F2P-X', 'CHAR-I-F', 'CHAR-I-F1P-X', 'CHAR-E-FR', 'CHFR'];

const lclArray = [
    'LCL', 'CHR', 'CHFL', 'CH', 'CHRT', 'CHF1', 'CHF2', 'CH1P', 'CH2P',
    'CHAR-E-F', 'CHAR-E-F2P-X', 'CHAR-I-F', 'CHAR-I-F1P-X', 'CHAR-E-FR', 'CHFR',
    'CHAR-I-F1P-X', 'CHAR-E-F1P-X', 'CHAR-I-F', 'BATT-CHAR-000', 'CHAR-E-FR',
    'CHAR-I-F2P-X', 'CHAR-E-1P-X', 'CHAR-E-F', 'CHAR-E-F2P-X', 'CHAR-I-1P-X'
];


const isLCLLogger = (model: string | undefined): boolean => {

    if (model?.startsWith("CHAR-"))
        return true
    else
        return model != null ? lclArray.includes(model) : false;
}


export { isLCLLogger }