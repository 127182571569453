export enum enumChariotAlarmInput {
    Disabled = 0,
    FlowA = 1,
    FlowTotal = 2,
    Pressure1Latest = 3,
    Pressure2Latest = 7,
    Pressure1Diff = 11,
    Pressure2Diff = 12,
    ChannelCompare = 20,
    Consumption = 21,       //AQGD-858
    SignalStrength = 30,
    Battery = 31
}