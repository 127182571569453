import React, { Component } from "react";
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { withStyles, createStyles } from '@mui/styles';
import ApiFailed from '../../Utils/ApiFailed';
import TextField from '@mui/material/TextField';
import Select from 'react-select'
import Checkbox from '@mui/material/Checkbox';
import makeAnimated from 'react-select/animated';
import Modal from 'react-bootstrap/Modal'
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ClipLoader from "react-spinners/ClipLoader";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Redirect } from "react-router-dom";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { DataGrid, Column, FilterRow, Pager, Paging, StateStoring, HeaderFilter } from 'devextreme-react/data-grid';
import { CreateUrl, CallGetAPI, CallPutAPI, CallDeleteAPI } from 'Utils/ApiHelper.js';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from "devextreme/data/data_source";

//Moment date/time formatting
//https://momentjs.com/docs/
import Moment from 'moment';

const animatedComponents = makeAnimated();

const styles: Record<any, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    dropZone: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    },
};

const style = createStyles(styles);

interface Task {
    id: number | null;
    taskname: string;
    action: string;
    enabled: boolean;
    startDate: Date;
    endDate: Date | null;
    schedule: string;
    scheduleValue: number;
    parameter1: string | null;
    parameter2: string | null;
    parameter3: string | null;
    FkCompanyId: number;
    lastUpdate: Date | null;
    lastRun: Date | null;
    lastRunOutcome: number | null;
    nextRun: Date | null;
    fkScheduledTaskGroupFilters: Array<{ id: number; fkGroupId: number }>;
    groups: Array<Group>;
}

interface TaskAction {
    id: number;
    action: string;
    method: string;
    desc: string;
    p1Desc: string;
    p2Desc: string;
    p3Desc: string;
}

interface Group {
    label: string;
    value: number;
}

interface Props {
    classes: {
        cardTitleWhite: string;
        formControl: string;
        cardCategoryWhite: string;
    };
}


interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    visible: boolean;
    modal: boolean;
    colour: string;
    edit: boolean;
    editTask: boolean;
    tempTask: Task;
    tempTaskAction: { value: number; label: string } | null;
    anchorEl: HTMLElement | null;
    redirect: boolean;
    redirectPath: string;
    redirectProps: {
        task: Task;
    } | null;
    modalTitle: string;
    deleteModal: boolean;
    p1Desc: string;
    p2Desc: string;
    p3Desc: string;
    actions: Array<{ value: number; label: string }>;
    actionSummary: string;
    defaultGroups: Array<Group>;
}

export class Tasks extends Component<Props, State> {
    store: ArrayStore;
    gridRef: React.RefObject<DataGrid>;
    taskActions: Array<TaskAction> = [];
    groups: Array<Group> = [];
    intervals: Array<{ value: string; label: string }> = [
        { value: "Hourly", label: "Hourly" },
        { value: "Daily", label: "Daily" },
        { value: "Weekly", label: "Weekly" },
    ];

    constructor(props: Readonly<Props>) {
        super(props);

        this.store = new ArrayStore({
            key: 'id',
            data: [],
            errorHandler: (error: Error): void => {
                console.log(error.message);
            }
        });

        this.state = {
            tableHidden: true,
            visible: false,
            modal: false,
            colour: "",
            edit: false,
            editTask: false,
            tempTask: {
                id: -1,
                taskname: 'New Task',
                action: '',
                enabled: false,
                startDate: new Date(),
                endDate: null,
                schedule: '',
                scheduleValue: 0,
                parameter1: null,
                parameter2: null,
                parameter3: null,
                FkCompanyId: parseInt(sessionStorage.getItem('companyId') || '0'),
                lastUpdate: null,
                lastRun: null,
                lastRunOutcome: 0,
                nextRun: null,
                fkScheduledTaskGroupFilters: [],
                groups: [],
            },
            tempTaskAction: null,
            anchorEl: null,
            loading: true,
            authorized: true,
            redirect: false,
            redirectPath: '',
            redirectProps: null,
            modalTitle: "",
            deleteModal: false,
            p1Desc: '',
            p2Desc: '',
            p3Desc: '',
            actions: [],
            actionSummary: '',
            defaultGroups: [],
        };

        this.gridRef = React.createRef();
        this.store.clear();

    }


    createSuccessNotification = (): void => {
        NotificationManager.success('Saved Changes', 'Success')

    };

    createErrorNotification = (): void => {
        NotificationManager.error('Error Saving Changes', 'Click me!', 5000, () => {
            alert('callback');
        });
    };

    createFailedInsertErrorNotification = (): void => {
        NotificationManager.error('Error creating new record', '', 5000);
    };

    createConflictNotification = (): void => {
        NotificationManager.warning('Changes conflict with another user. Refreshing tasks', 'Click to retry', 5000, () => {
            this.reloadData(this.state.tempTask.id);
        });
    };


    componentDidMount(): void {

        this.reloadData(null);
    }

    // if editId is not null, then reload() re-displays Modal with record id = editId after refresh
    reloadData = (editId: number | null): void => {

        const me = this;
        me.groups = [];
       
        //fetch Tasks
        CallGetAPI(CreateUrl('/api/aquaguard/TasksForCompany?companyId=' + sessionStorage.getItem('companyId')), {})
            .then(data => {

                me.store.clear();
                if (data.length > 0) {

                    // Copy the data records into deviceData, adding the clickEvent

                    const records = [];
                    for (let i = 0; i < data.length; i++) {
                        const id = data[i].id;
                        const filters = [];
                        const groups: Array<Group> = [];
                        for (let j = 0; j < data[i].fkScheduledTaskGroupFilters.length; j++) {
                            filters.push({
                                id: data[i].fkScheduledTaskGroupFilters[j].id,
                                fkGroupId: data[i].fkScheduledTaskGroupFilters[j].fkGroupId,
                            })
                            groups.push({
                                value: data[i].fkScheduledTaskGroupFilters[j].id,
                                label: me.groups.filter((g) => g.value == data[i].fkScheduledTaskGroupFilters[j].id)[0]?.label,
                            })
                        }

                        const rec: Task = {
                            id: id,
                            taskname: data[i].taskname,
                            action: data[i].action,
                            enabled: data[i].enabled,
                            startDate: data[i].startDate,
                            endDate: data[i].endDate,
                            schedule: data[i].schedule,
                            scheduleValue: data[i].scheduleValue,
                            parameter1: data[i].parameter1,
                            parameter2: data[i].parameter2,
                            parameter3: data[i].parameter3,
                            FkCompanyId: data[i].fkCompanyId,
                            lastUpdate: data[i].lastUpdate,
                            lastRun: data[i].lastRun,
                            lastRunOutcome: data[i].lastRunOutcome,
                            nextRun: data[i].nextRun,
                            fkScheduledTaskGroupFilters: filters,
                            groups: groups,
                        };
                        records.push({ type: 'insert', data: rec, index: i });
                    }

                    me.store.push(records);
                       

                    me.setState(
                        {
                            tableHidden: false,
                            loading: false
                        })

                    if (editId != null) {
                        me.store.byKey(editId)
                            .then((task) => {

                                this.setState(
                                    {
                                        tempTask: task,
                                        editTask: true,
                                        modal: !me.state.modal,
                                        anchorEl: null,
                                        modalTitle: "Edit Task"
                                    });
                            });
                    }
                            
                }
                else {
                    me.setState(
                        {
                            tableHidden: false,
                            loading: false
                        })
                }
            })
            .catch(function (error) {
                me.setState(
                    {
                        loading: false
                    })
                console.log(error)
            });

        CallGetAPI(CreateUrl('/api/aquaguard/TaskActions'), {})
            .then(data => {
                const actions: Array<{ value: number; label: string }> = [];
                for (let i = 0; i < data.length; i++) {
                    const rec: TaskAction = {
                        id: data[i].id,
                        action: data[i].action,
                        desc: data[i].description,
                        method: data[i].method,
                        p1Desc: data[i].p1Desc,
                        p2Desc: data[i].p2Desc,
                        p3Desc: data[i].p3Desc,
                    };

                    me.taskActions.push(rec);
                    actions.push({ value: rec.id, label: rec.action });
                }

                me.setState({ actions: actions });

            });

        CallGetAPI(CreateUrl('/api/aquaguard/GroupsForCompany?companyId=' + sessionStorage.getItem('companyId')), {})
            .then(data => {
                for (let i = 0; i < data.length; i++) {
                    const rec: Group = {
                        value: data[i].groupId,
                        label: data[i].groupName,
                    };

                    me.groups.push(rec);
                }

            });

        
    }



    //new row
    newTask = (): void => {
        this.setState({
            modal: !this.state.modal,
            editTask: false,
            tempTask: {
                id: -1,
                taskname: 'New Task',
                action: '',
                enabled: false,
                startDate: new Date(),
                endDate: null,
                schedule: '',
                scheduleValue: 0,
                parameter1: null,
                parameter2: null,
                parameter3: null,
                FkCompanyId: parseInt(sessionStorage.getItem('companyId') || '0'),
                lastUpdate: null,
                lastRun: null,
                lastRunOutcome: 0,
                nextRun: null,
                fkScheduledTaskGroupFilters: [],
                groups: [],
            },
            modalTitle: "New Task",
            defaultGroups: [],
        });


        
    }
    //edit row
    editTask = (): void => {
        this.setState({
            editTask: true,
            modal: !this.state.modal,
            modalTitle: "Edit Task",
            anchorEl: null,
        });
        
    }

    
    //save changes, for both edit and new
    saveTask = (): void => {

        const me = this;

        this.setState({
            modal: !this.state.modal,
            loading: true,
        });

        if (this.state.editTask === false) { //adding a new Task
            const tmpTask = this.state.tempTask;

            tmpTask.lastUpdate = new Date();

            //********************
            //send model to API
            //save group
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json-patch+json' },
                //mode: 'no-cors',
                body: JSON.stringify(tmpTask)
            };

            CallGetAPI(CreateUrl('/api/aquaguard/Task'), requestOptions)
                .then(data => {
                    console.log(data);
                    const Task = me.state.tempTask;
                    Task.id = data.id;
                    Task.lastUpdate = data.lastUpdate;
                    me.store.push([{ type: 'insert', key: Task.id, data: Task }]);
                    me.setState({
                        tempTask: Task,
                    });
                    me.createSuccessNotification();
                    //UI update
                    me.reloadData(null);
                    me.setState({
                        loading: false,
                    });

                })
                .catch(function (error) {
                    me.createFailedInsertErrorNotification();
                    console.log(error);
                    me.setState({
                        loading: false,
                    });
                });

        }
        else {//modifying an existing Task

            const Task = this.state.tempTask;

            //********************
            //send model to API
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json-patch+json' },
                //mode: 'no-cors',
                body: JSON.stringify(Task),
            };

            CallPutAPI(CreateUrl('/api/aquaguard/Task?id=' + Task.id), requestOptions)
                .then(async response => {
                    console.log(response);
                    if (response.status == 409) {
                        // Conflicting edit detected
                        me.createConflictNotification();
                        console.log("Edit conflict");
                        me.reloadData(Task.id);
                        me.setState({
                            loading: false,
                        });
                    }
                    else {
                        if (response.status == 204) {
                            me.store.push([{ type: 'update', key: Task.id, data: Task }]);
                            me.setState({
                                tempTask: Task,

                            });
                            me.createSuccessNotification();
                            //UI update
                            me.reloadData(null);
                            me.setState({
                                loading: false,
                            });
                        }
                        else {
                            me.createErrorNotification();
                            me.setState({
                                loading: false,
                            });
                        }
                    }
                })
                .catch(function (error) {
                    me.createFailedInsertErrorNotification();
                    console.log(error);
                    me.setState({
                        loading: false,
                    });
                });
        }

    }

    moreRender(Task: any): React.ReactNode {
        return (
            <IconButton size="small" onClick={(e): void => { this.handleClick(e, Task.data.id) }}><MoreHorizIcon /></IconButton>
        );
    }

    handleClose = (): void => {
        this.setState({ anchorEl: null });
    }

    handleClick(event: React.MouseEvent<HTMLElement>, id: number): void {
        const me = this;
        this.store.byKey(id)
            .then((task: Task) => {
                const selAction = me.taskActions.filter((action) => action.method == task.action)[0];
                const selGroup = me.groups.filter((group) => task.fkScheduledTaskGroupFilters.filter((f) => f.fkGroupId == group.value).length > 0);
                task.groups = selGroup;
                this.setState(
                    {
                        tempTask: task,
                        tempTaskAction: { value: selAction.id, label: selAction.action },
                        actionSummary: selAction.desc,
                        p1Desc: selAction.p1Desc,
                        p2Desc: selAction.p2Desc,
                        p3Desc: selAction.p3Desc,
                        anchorEl: event.currentTarget,
                        defaultGroups: selGroup,
                    });
                },
                (error) => {
                    console.log(error);
                });
       
    }

    //do nothing, close the modal
    cancel = (): void => {
        this.setState({
            modal: !this.state.modal
        });
    }

    toggleModal = (): void => {
        this.setState({
            modal: !this.state.modal
        });
    }
    //show edit buttons
    toggleEdit = (): void => {
        this.setState({
            edit: !this.state.edit
        });
    }

    //field changed events---
    TaskNameChanged = (event: any): void => {
        const tmpTask = this.state.tempTask;
        tmpTask.taskname = event.target.value;
        this.setState( { tempTask: tmpTask });
    }

    handleActionChange = (event: any): void => {
        const tmpTask = this.state.tempTask;
        const taskAction = this.taskActions.filter((action: TaskAction) => action.id == event.value)[0];
        tmpTask.action = taskAction.method;
        this.setState({ tempTask: tmpTask, actionSummary: taskAction.desc, p1Desc: taskAction.p1Desc, p2Desc: taskAction.p2Desc, p3Desc: taskAction.p3Desc,});
    }

    handleEnabledChange = (event: any): void => {
        const tmpTask = this.state.tempTask;
        tmpTask.enabled = event.target.checked;
        this.setState({ tempTask: tmpTask });
    }

    startDateChanged = (event: any): void => {
        const tmpTask = this.state.tempTask;
        tmpTask.startDate = event.target.value;
        this.setState({ tempTask: tmpTask });
    }

    handleScheduleChange = (event: any): void => {
        const tmpTask = this.state.tempTask;
        tmpTask.schedule = event.value;
        this.setState({ tempTask: tmpTask });
    }

    scheduleValueChanged = (event: any): void => {
        const tmpTask = this.state.tempTask;
        tmpTask.scheduleValue = parseInt(event.target.value);
        this.setState({ tempTask: tmpTask });
    }

    endDateChanged = (event: any): void => {
        const tmpTask = this.state.tempTask;
        tmpTask.endDate = event.target.value;
        this.setState({ tempTask: tmpTask });
    }

    TaskP1Changed = (event: any): void => {
        const tmpTask = this.state.tempTask;
        tmpTask.parameter1 = event.target.value;
        this.setState({ tempTask: tmpTask });
    }

    TaskP2Changed = (event: any): void => {
        const tmpTask = this.state.tempTask;
        tmpTask.parameter2 = event.target.value;
        this.setState({ tempTask: tmpTask });
    }

    TaskP3Changed = (event: any): void => {
        const tmpTask = this.state.tempTask;
        tmpTask.parameter3 = event.target.value;
        this.setState({ tempTask: tmpTask });
    }

    handleGroupChange = (event: any): void => {
        const tmpTask = this.state.tempTask;
        tmpTask.groups = event;
        this.setState({ tempTask: tmpTask });
    }

    TaskDetailsClick = (): void => {
        this.setState(
            {
                redirect: true,
                redirectPath: '/portal/TaskDetail',
                redirectProps: {
                    task: this.state.tempTask,
                }
            });
    }

    toggleDeleteModal = (): void => {
        this.setState({
            deleteModal: !this.state.deleteModal,
            anchorEl: null,
            modalTitle: "Warning"
        });

    };

    deleteRow = (): void => {

        const me = this;

        this.setState({
            deleteModal: !this.state.deleteModal,
        });

        //delete from database
        CallDeleteAPI(CreateUrl('/api/aquaguard/Task?id=' + this.state.tempTask.id), {})
            .then(async response => {
                if (response.status == 200) {
                    me.createSuccessNotification();
                    //remove the old version of the row
                    this.store.push([{ type: 'remove', key: me.state.tempTask.id }]);
                }
                else {
                    me.createErrorNotification();
                }
                console.log(response);
            })
            .catch(function (error) {
                me.createErrorNotification();
                console.log(error);
            });

    }

    dateColumnCustomizeText(cellInfo: any): string {
        if (cellInfo.value == null)
            return "";
        else
            return Moment(cellInfo.value).format("DD/MM/YYYY HH:mm");
    }



    render(): React.ReactNode {
        const { classes } = this.props;
        
        return (this.state.authorized) ? //if we are authorized, show page, else redirect to login page
        (
            (!this.state.redirect) ?
            (
        <div>

        {/* Edit Task modal  */}
            <Modal
                                show={this.state.modal}
                                onHide={(): void => this.toggleModal()}
                                dialogClassName="modal-100w"
                                aria-labelledby="example-custom-modal-styling-title"
                                centered
                                style={{margin: "50px 0", width: "80%"}}
                                
                            >
                                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {this.state.modalTitle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Grid container spacing={2}>
                        <Grid item xs={12}  >
                            <TextField
                                id="outlined-input"
                                label="Name"
                                variant="outlined"
                                defaultValue={this.state.tempTask?.taskname}
                                onChange={this.TaskNameChanged}
                                />
                        </Grid>
                        <Grid item xs={12} >
                            <label htmlFor="actionDropdown">Action:</label>
                            <Select
                                components={animatedComponents}
                                label="Action"
                                options={this.state.actions}
                                defaultValue={this.state.tempTaskAction}
                                onChange={this.handleActionChange.bind(this)}               
                            />
                                {this.state.actionSummary}
                        </Grid>
                        <Grid item xs={12} >
                            Enabled: 
                            <Checkbox
                                id="outlined-input-enabled"
                                checked={this.state.tempTask.enabled}
                                onChange={this.handleEnabledChange.bind(this)}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                                id="outlined-input-startdate"
                                                label="Start Date"
                                                type="datetime-local"
                                                variant="outlined"
                                                defaultValue={this.state.tempTask?.startDate}
                                                onChange={this.startDateChanged}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={this.state.editTask}
                                                style={{zIndex: 0}}
                            />
                        </Grid>
                                        <Grid item xs={12} >
                                            <label htmlFor="actionDropdown">Frequecy Interval:</label>
                                            <Select
                                                components={animatedComponents}
                                                label="Frequecy Interval"
                                                variant="outlined"
                                                options={this.intervals}
                                                defaultValue={{ value: this.state.tempTask?.schedule, label: this.state.tempTask?.schedule }}
                                                onChange={this.handleScheduleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} >
                                            <TextField
                                                id="outlined-input"
                                                label="Schedule Value"
                                                
                                                defaultValue={this.state.tempTask?.scheduleValue}
                                                onChange={this.scheduleValueChanged}
                                            />
                                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                id="outlined-input-enddate"
                                label="End Date"
                                type="datetime-local"
                                variant="outlined"
                                defaultValue={this.state.tempTask?.endDate}
                                onChange={this.endDateChanged}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        {this.state.p1Desc &&
                        <Grid item xs={12} >
                                    <TextField
                                        id="outlined-input"
                                        label="Parameter1"
                                        variant="outlined"
                                        defaultValue={this.state.tempTask?.parameter1}
                                        onChange={this.TaskP1Changed.bind(this)}
                                        helperText={this.state.p1Desc}
                                    />
                        </Grid>
                        }
                        {this.state.p2Desc &&
                        <Grid item xs={12} >
                                    <TextField
                                        id="outlined-input"
                                        label="Parameter2"
                                        variant="outlined"
                                        defaultValue={this.state.tempTask?.parameter2}
                                        onChange={this.TaskP2Changed.bind(this)}
                                        helperText={this.state.p2Desc}
                                    />
                        </Grid>
                        }
                        {this.state.p3Desc &&
                        <Grid item xs={12} >
                                    <TextField
                                    id="outlined-input"
                                    label="Parameter3"
                                    variant="outlined"
                                    defaultValue={this.state.tempTask?.parameter3}
                                    onChange={this.TaskP3Changed.bind(this)}
                                    helperText={this.state.p3Desc}
                                    />
                        </Grid>
                        }
                        <Grid item md={12} >
                            <label htmlFor="groups">Filter By Group(s)</label>
                            <Select
                                                isMulti
                                                components={animatedComponents}
                                                options={this.groups}
                                                defaultValue={this.state.defaultGroups}
                                                onChange={this.handleGroupChange}
                                                id="groups"
                                />
                            
                        </Grid>


                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button color="primary" onClick={(): void => this.saveTask()}>Save</Button>
                    <Button color="secondary" onClick={(): void => this.toggleModal()}>Cancel</Button>
                </Modal.Footer>
            </Modal>

           {/* Delete Task modal  */}

            <Modal
                                show={this.state.deleteModal}
                                onHide={(): void => this.toggleDeleteModal()}
                                dialogClassName="modal-100w"
                                aria-labelledby="example-custom-modal-styling-title"
                                centered
                                
                            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {this.state.modalTitle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    Delete Task {this.state.tempTask?.taskname}?
                </Modal.Body>
                <Modal.Footer>
                    <Button color="primary" onClick={(): void => this.deleteRow()}>Continue</Button>
                    <Button color="secondary" onClick={(): void => this.toggleDeleteModal()}>Cancel</Button>
                </Modal.Footer>
            </Modal>

        {this.state.loading &&
                    <div style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <ClipLoader
                            size={150}
                            color={"#123abc"}
                            loading={this.state.loading}
                        />
                    </div>
                }
                {!this.state.loading &&
            <div>

            {this.state.tableHidden &&
                    <ApiFailed />
            }
            {!this.state.tableHidden &&
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                        <CardHeader color="primary" className="view view-cascade  d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
                                <h4 className={classes.cardTitleWhite}>Scheduled Tasks</h4>
                                <IconButton aria-label="add" onClick={(): void => this.newTask()} size="large">
                                    <AddCircleOutlineIcon  style={{fill: "white"}}/>
                                </IconButton>
                            </CardHeader>
                            <CardBody>
                               
                                
                                <DataGrid
                                
                                repaintChangesOnly={true}
                                dataSource={new DataSource({ store: this.store })}
                                ref={this.gridRef} >
                                <StateStoring enabled={true} type="localStorage" storageKey="TaskListGrid" />
                                <FilterRow visible={true} />
                                <HeaderFilter visible={true} />

                                <Column dataField="taskname" visible={true} dataType="string" />
                                <Column dataField="action" visible={true} dataType="string" />
                                                    <Column dataField="enabled" visible={true} dataType="boolean" />
                                                    <Column dataField="schedule" visible={true} dataType="string" />
                                                    <Column dataField="scheduleValue" visible={true} dataType="string" />
                                                    <Column dataField="lastRun" visible={true} dataType="date" customizeText={this.dateColumnCustomizeText} allowHeaderFiltering={false}/>
                                                    <Column dataField="lastRunOutcome" visible={true} dataType="number" allowHeaderFiltering={false}/>
                                                    <Column dataField="nextRun" visible={true} dataType="date" customizeText={this.dateColumnCustomizeText} allowHeaderFiltering={false}/>


                                
                                <Column fixed={true} fixedPosition='right'  type="buttons" cellRender={this.moreRender.bind(this)} />
                                
                                
                                <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                <Paging defaultPageSize={10} />
                                </DataGrid>
                               
                               
                             
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                  }

                  </div>
              
            }
                    <Menu
                    
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleClose}
                                disableScrollLock={true}
                    >
                        <MenuItem onClick={this.editTask}>Quick Edit</MenuItem>
                        <MenuItem onClick={this.toggleDeleteModal}>Delete</MenuItem>
                    </Menu>

                    <NotificationContainer />

        </div>
            )
            :
            (<Redirect push to={{
                pathname: this.state.redirectPath,
                state: this.state.redirectProps
            }}/>)
        )
        :
        (
            <Redirect to={'/Login'} />
        );
    }
}


export default withStyles(style)(Tasks);

