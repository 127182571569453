import React, { Component, ChangeEvent } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { withStyles, createStyles } from '@mui/styles';
import ApiFailed from '../../Utils/ApiFailed';
import { DataGrid, Column, FilterRow, Pager, Paging, StateStoring } from 'devextreme-react/data-grid';

//Moment date/time formatting
//https://momentjs.com/docs/
import Moment from 'moment';

import ClipLoader from "react-spinners/ClipLoader";
//modal window imports
//import InputLabel from '@mui/material/InputLabel';
//import Select from '@mui/material/Select';
//import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import Modal from 'react-bootstrap/Modal'
import DateBox from 'devextreme-react/date-box';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { Redirect } from "react-router-dom";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Select from '@mui/material/Select'
// import makeAnimated from 'react-select/animated';
import { CreateUrl, CallGetAPI, CallPutAPI, CallDeleteAPI } from 'Utils/ApiHelper.js';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from "devextreme/data/data_source";
import { CheckBox } from 'devextreme-react';


const styles: Record<any, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);

interface SystemInfo {
    id: number;
    message: string;
    startFrom: Date;
    displayUntil: Date;
    fkCompanyId: number | null;
    status: string;
}

interface Props {
    classes: {
        cardTitleWhite: string;
        formControl: string;
        cardCategoryWhite: string;
    };
}


interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    visible: boolean;
    modal: boolean;
    selectedUser: number;
    colour: string;
    edit: boolean;
    orgInfo: string | undefined;
    editInfo: boolean;
    tempInfo: SystemInfo;
    anchorEl: HTMLElement | null;
    redirect: boolean;
    redirectPath: string;
    redirectProps: {
        userName: string;
        userId: number;
    } | null;
    modalTitle: string;
    deleteModal: boolean;
    allowSave: boolean;
    checked: boolean;
    email: string;
}

export class SystemInfos extends Component<Props, State> {
    store: ArrayStore;
    gridRef: React.RefObject<DataGrid>;
    companies: Array<{ id: number, name: string }> = [];


    constructor(props: Readonly<Props>) {

        super(props);

        this.store = new ArrayStore({
            key: 'id',
            data: []
        });

        this.state = {
            loading: true,
            tableHidden: false,
            visible: false,
            modal: false,
            selectedUser: 0,
            colour: '',
            edit: false,
            editInfo: false,
            orgInfo: undefined,
            tempInfo: {
                id: 0,
                message: "",
                startFrom: new Date(Date.now()),
                displayUntil: new Date(Date.now()),
                fkCompanyId: null,
                status: "",
            },
            anchorEl: null,
            authorized: true,
            redirect: false,
            redirectPath: '',
            redirectProps: null,
            modalTitle: "",
            deleteModal: false,
            allowSave: false,
            checked: false,
            email: ""
        };

        this.gridRef = React.createRef();
        this.store.clear();

    }



    createSuccessNotification = (): void => {
        NotificationManager.success('Saved Changes', 'Success')

    };

    createErrorNotification = (): void => {
        NotificationManager.error('Error Saving Changes', 'Click me!', 5000, () => {
            alert('callback');
        });
    };

    createFailedInsertErrorNotification = (err: string): void => {
        NotificationManager.error(err, 'Error creating new record', 5000);
    };

    componentDidMount(): void {
        if (sessionStorage.getItem('portalUpdate') === 'true') {
            this.clearState()
        }
        this.reloadData(null);
    }

    clearState = (): void => {
        this.gridRef.current?.instance.state(null);
    }

    // if editId is not null, then reload() re-displays Modal with record id = editId after refresh
    reloadData = (editId: number | null): void => {
        const me = this;

      
        CallGetAPI(CreateUrl('/api/aquaguard/SystemInfos'), {})
            .then(data => {
                if (data.length > 0) {

                    // Copy the data records into store
                    const records: Array<{
                        type: "insert" | "update" | "remove";
                        data: any;
                        index: number | undefined
                    }>  = [];
                    for (let i = 0; i < data.length; i++) {
                        const id = data[i].id;

                        const rec: SystemInfo = {
                            id: id,
                            message: data[i].message,
                            startFrom: new Date(Date.parse(data[i].startFrom)),
                            displayUntil: new Date(Date.parse(data[i].displayUntil)),
                            fkCompanyId: data[i].fkCompanyId,
                            status: "Historical",
                        };

                        const now = new Date().getTime();
                        if (now >= rec.startFrom.getTime() && now <= rec.displayUntil.getTime()) {
                            rec.status = "Current";
                        }

                        //used for data grid
                        records.push({ type: 'insert', data: rec, index: i });

                    }

                    me.store.push(records);

                    me.setState(
                        {
                            tableHidden: false,
                            loading: false
                        })
                }
                else {
                    me.setState(
                        {
                            tableHidden: false,
                            loading: false
                        })
                }
            })
            .catch(function (error) {
                console.log(error);
                me.setState(
                    {
                        authorized: true,
                        tableHidden: true,
                        loading: false
                    })
            });

        CallGetAPI(CreateUrl('/api/aquaguard/Companies'), {})
            .then(data => {
                if (data.length > 0) {

                    // Copy the data records into deviceData, adding the clickEvent
                    const records = [];
                    for (let i = 0; i < data.length; i++) {
                        const id = data[i].id;
                        const name = data[i].name;


                        //used for parent Info dropdown
                        me.companies.push({ id: id, name: name });
                    }

                }
                else {
                    me.companies = [];
               }
            })
            .catch(function (error) {
                console.log(error)
            });
    }

   
    handleClose = (): void => {
        this.setState({ anchorEl: null });
    }

    //new row
    newInfo = (): void => {
        this.setState({
            modal: true,
            editInfo: false,
            tempInfo: {
                id: 0,
                message: "",
                startFrom: new Date(Date.now()),
                displayUntil: new Date(Date.now()),
                fkCompanyId: null,
                status: "",
            },
            modalTitle: "Set Up a New System Info",
            allowSave: false,
        });

    }
    //edit row
    editInfo = (): void => {
        this.setState({
            editInfo: true,
            modal: !this.state.modal,
            anchorEl: null,
            modalTitle: "Modify System Info Details",
            allowSave: true,
        });
    }

    //save changes, for both edit and new
    saveInfo = (): void => {

        const me = this;

        if (this.state.editInfo === false) { //adding a new Info

            this.setState({
                modal: false,
                loading: true,
            });

            //********************
            //send model to API
          
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    id: 0,
                    message: `${this.state.tempInfo?.message} ${this.state.email ? ` Please contact: <a href="${this.state.email}">${this.state.email}</a>` : ""}`,
                    startFrom: me.state.tempInfo?.startFrom,
                    displayUntil: me.state.tempInfo?.displayUntil,
                    fkCompanyId: me.state.tempInfo?.fkCompanyId,
                })
            };

            CallGetAPI(CreateUrl('/api/aquaguard/SystemInfos'), requestOptions)
                .then(data => {
                    if (data.status === undefined) {
                        const Info: SystemInfo = me.state.tempInfo;
                        Info.id = data.id

                        const now = new Date().getTime();
                        if (now >= Info.startFrom.getTime() && now <= Info.displayUntil.getTime()) {
                            Info.status = "Current";
                        }
                        else {
                            Info.status = "Historical";
                        }

                        me.createSuccessNotification();
                        //add to grid array store
                        me.store.push([{ type: 'insert', key: Info.id, data: Info }]);
                        //need to call reload data to get parent details in the grid - see below

                        me.setState({
                            tempInfo: Info,
                            loading: false,
                        });

                        //to refresh the grid properly
                        //me.reloadData(null);
                    }
                    else {
                        me.createFailedInsertErrorNotification(data.statusText);
                        console.log(data);
                        me.setState({
                            loading: false,
                        });
                    }
                })
                .catch(function (error) {
                    me.createFailedInsertErrorNotification(error.statusText);
                    console.log(error)
                    me.toggleModal();
                    me.setState({
                        loading: false,
                    });
                });

        }
        else {//modifying an existing Info

            //********************
            //send model to API
            
            //save User
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    id: me.state.tempInfo.id,
                    message: me.state.tempInfo?.message,
                    startFrom: me.state.tempInfo?.startFrom,
                    displayUntil: me.state.tempInfo?.displayUntil,
                    fkCompanyId: me.state.tempInfo?.fkCompanyId,
                })
            };

            CallPutAPI(CreateUrl('/api/Aquaguard/SystemInfos/' + me.state.tempInfo.id), requestOptions)
                .then(async response => {
                    if (response.status == 200) {

                        const Info: SystemInfo = me.state.tempInfo;
                        const now = new Date().getTime();
                        if (now >= Info.startFrom.getTime() && now <= Info.displayUntil.getTime()) {
                            Info.status = "Current";
                        }
                        else {
                            Info.status = "Historical";
                        }

                        me.createSuccessNotification();

                        me.store.push([{ type: 'update', key: me.state.tempInfo.id, data: Info }]);

                        me.setState({
                            modal: !me.state.modal,
                            loading: false,
                        });
                    }
                    else {
                        me.toggleModal();
                        me.setState({
                            loading: false,
                        });
                    }
                    console.log(response)

                })
                .catch(function (error) {
                    me.createErrorNotification()
                    me.toggleModal();
                    console.log(error)
                    me.setState({
                        loading: false,
                    });
                });

        }
    }


    //do nothing, close the modal
    cancel = (): void => {
        this.setState({
            anchorEl: null
        });
        this.toggleModal();

    }

    toggleModal = (): void => {
        this.setState({
            modal: !this.state.modal
        });
    }

    //field changed events---
    MessageChanged = (event: ChangeEvent<HTMLInputElement>): void => {
        const tempInfo = this.state.tempInfo;
        tempInfo.message = event.target.value;
        this.setState( {
            tempInfo: tempInfo,
            allowSave: this.checkSave(event.target.value, this.state.tempInfo.startFrom, this.state.tempInfo.displayUntil),
            });
    }

    EmailChanged = (e: ChangeEvent<HTMLInputElement>): void => {
        if (this.state.checked) {
            const email = e.target.value
            this.setState({ email: email })
        } else {
            this.setState({ email: "" })
        }
    }

    StartChanged = (event: any): void => {
        const tempInfo = this.state.tempInfo;
        tempInfo.startFrom = event.value;
        this.setState({
            tempInfo: tempInfo,
            allowSave: this.checkSave(this.state.tempInfo.message, event.value, this.state.tempInfo.displayUntil),
        });
    }

    UntilChanged = (event: any): void => {
        const tempInfo = this.state.tempInfo;
        tempInfo.displayUntil = event.value;
        this.setState({
            tempInfo: tempInfo,
            allowSave: this.checkSave(this.state.tempInfo.message, this.state.tempInfo.startFrom, event.value),
        });
    }

    CompanyChanged = (event: any): void => {
        const tempInfo = this.state.tempInfo;
        tempInfo.fkCompanyId = event.target.value;
        this.setState({
            tempInfo: tempInfo,
        });
    }


    checkSave = (message: string, start: Date, until: Date): boolean => {
        return message != ""
            && (start.getTime() != until.getTime())
    }

    toggleDeleteModal = (): void => {
        this.setState({
            deleteModal: !this.state.deleteModal,
            anchorEl: null,
            modalTitle: "Warning"
        });

    }

    deleteRow = (): void => {

        const me = this;

        this.setState({
            deleteModal: !this.state.deleteModal,
        });

        //delete from database
        CallDeleteAPI(CreateUrl('/api/aquaguard/SystemInfos/' + this.state.tempInfo.id), {})
            .then(async response => {
                if (response.status == 200) {
                    me.createSuccessNotification();
                    //remove the old version of the row
                    me.store.push([{ type: 'remove', key: me.state.tempInfo.id }]);
                }
                else {
                    me.createErrorNotification();
                }
                console.log(response);
            })
            .catch(function (error) {
                me.createErrorNotification();
                console.log(error);
            });

    }
    //-------------------------

    handleClick(event: any, id: number): void {
        const me = this;
        this.store.byKey(id)
            .then((Info) => {

                me.setState(
                    {
                        anchorEl: event.currentTarget,
                        tempInfo: Info,
                    });
                },
                (error) => {
                    console.log(error);
                });
    }

    dateColumnCustomizeText(cellInfo: any): string {
        if (cellInfo.value == null)
            return "";
        else
            return Moment(cellInfo.value).format("DD/MM/YYYY HH:mm");
    }

    companyColumnCustomizeText(cellInfo: any): string {
        if (cellInfo.value == null || cellInfo.value == 0)
            return "ALL";
        else
            return this.companies.filter(c => c.id == cellInfo.value)[0].name;
    }


    moreRender(row: any): React.ReactNode {
        return (
            <IconButton size="small" onClick={(e): void => { this.handleClick(e, row.data.id); }}><MoreHorizIcon /></IconButton>
        );
    }



    render(): React.ReactNode {
        const { classes } = this.props;


        return (this.state.authorized) ? //if we are authorized, show page, else redirect to login page
            (
                <div>

                    <Dialog
                        open={this.state.modal}
                        >
                        <Modal.Header closeButton onClick={(): void => this.setState({modal: !this.state.modal})}>
                            <Modal.Title id="example-custom-modal-styling-title">
                                {this.state.modalTitle}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="show-grid">
                            <Grid container spacing={2}>
                                <Grid item xs={12} >
                                    <div style={{ padding: 10 }}>
                                        <TextField
                                            id="outlined-input-message"
                                            label="Message"
                                            value={this.state.tempInfo.message}
                                            onChange={this.MessageChanged}
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                        />
                                    </div>

                                </Grid>
                                <Grid item xs={12} >
                                    <div style={{ padding: 10 }}>
                                        <FormControlLabel control={<Checkbox
                                            checked={this.state.checked}
                                            onChange={(): void => this.setState({ checked: !this.state.checked })}
                                        />} label="Check to add contact email to message" />
                                        {this.state.checked && <TextField
                                            id="outlined-input-email"
                                            label="Email"
                                            type="email"
                                            placeholder="user@example.com"
                                            variant="outlined"
                                            fullWidth
                                            onChange={this.EmailChanged}
                                        />}
                                    </div>

                                </Grid>
                                <Grid item xs={12} >
                                        <div style={{ padding: 10 }}>
                                        <InputLabel>Start From:</InputLabel>
                                        <DateBox defaultValue={this.state.tempInfo.startFrom}
                                            type="datetime"
                                            onValueChanged={this.StartChanged.bind(this)}
                                            displayFormat="dd/MM/yyyy HH:mm" 
                                        />
                                    </div>
                                </Grid>
                                    <Grid item xs={12}>
                                        <div style={{ padding: 10 }}>
                                            <InputLabel>Display Until:</InputLabel>
                                            <DateBox defaultValue={this.state.tempInfo.displayUntil}
                                                type="datetime"
                                                onValueChanged={this.UntilChanged.bind(this)}
                                            displayFormat="dd/MM/yyyy HH:mm"
                                            />
                                       </div>
                                    </Grid>
                                <Grid item xs={12} >
                                    <div style={{ padding: 10 }}>
                                            <Select
                                                id="outlined-select"
                                                label="Apply to (Company)"
                                                value={this.state.tempInfo.fkCompanyId ? this.state.tempInfo.fkCompanyId : 0}
                                                onChange={this.CompanyChanged}
                                                variant="outlined"
                                                style={{ width: 200 }}
                                        >
                                                <MenuItem key="0" value="0">ALL Companies</MenuItem>
                                                {this.companies.map((option): React.ReactNode => {
                                                    return <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                                                })
                                                }
                                            </Select>
                                    </div>
                                </Grid>
                            </Grid>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button color="primary" variant="contained" style={{marginRight: "10px"}} onClick={(): void => this.saveInfo()} disabled={!this.state.allowSave}>Save</Button>
                            <Button color="secondary" variant="contained" onClick={(): void => this.cancel()}>Cancel</Button>
                        </Modal.Footer>
                    </Dialog>

                    <Dialog
                        open={this.state.deleteModal}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                {this.state.modalTitle}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="show-grid">
                            Delete Message {this.state.tempInfo.message }?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button color="primary" variant="contained" style={{marginRight: "10px"}} onClick={(): void => this.deleteRow()}>Continue</Button>
                            <Button color="secondary" variant="contained" onClick={(): void => this.toggleDeleteModal()}>Cancel</Button>
                        </Modal.Footer>
                    </Dialog>

                    {this.state.loading &&
                        <div style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}>
                            <ClipLoader
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>

                    }
                    {!this.state.loading &&

                        <div>


                            {this.state.tableHidden &&
                                    <ApiFailed />
                            }
                            {!this.state.tableHidden &&
                                <GridContainer >
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Card>
                                            <CardHeader color="primary" className="view view-cascade  d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
                                            <h4 className={classes.cardTitleWhite}>System messages</h4>
                                            <IconButton aria-label="add" onClick={(): void => this.newInfo()} size="large">
                                                <AddCircleOutlineIcon style={{ fill: "white" }}/>
                                                </IconButton>
                                            </CardHeader>
                                            <CardBody>
                                            <DataGrid

                                                allowColumnResizing={true}
                                                repaintChangesOnly={true}
                                                dataSource={new DataSource({ store: this.store })}
                                                ref={this.gridRef} >
                                                <StateStoring enabled={true} type="localStorage" storageKey="SystemInfoListGrid" />
                                                <FilterRow visible={false} />

                                                <Column dataField="message" caption="Message" visible={true} dataType="string" />
                                            <Column dataField="startFrom" caption="Start" visible={true} dataType="datetime" customizeText={this.dateColumnCustomizeText} sortIndex={1} sortOrder="desc" />
                                                <Column dataField="displayUntil" caption="Until" visible={true} dataType="datetime" customizeText={this.dateColumnCustomizeText}  />
                                                <Column dataField="fkCompanyId" caption="Company" visible={true} dataType="string" customizeText={this.companyColumnCustomizeText.bind(this)} />
                                                <Column dataField="status" caption="Status" visible={false} dataType="string" groupIndex={0} />

                                                <Column fixed={true} fixedPosition='right' type="buttons" cellRender={this.moreRender.bind(this)} width={35} />


                                                <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                                <Paging defaultPageSize={10} />
                                            </DataGrid>
                                            </CardBody>
                                        </Card>
                                    </GridItem>

                                </GridContainer>
                            }

                        </div>
                    }


                    <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleClose}
                        disableScrollLock={true}
                        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                        transformOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    >
                        <MenuItem onClick={this.editInfo.bind(this)}>Quick Edit</MenuItem>
                        <MenuItem onClick={this.toggleDeleteModal}>Delete</MenuItem>
                    </Menu>

                    <NotificationContainer />

                </div>
                )

            :
            (
                <Redirect to={'/Login'} />
            );

    }

}

export default withStyles(style)(SystemInfos);

