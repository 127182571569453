import React, { ReactNode } from "react";
import Modal from 'react-bootstrap/Modal'
import Dialog from '@mui/material/Dialog';      // Use Dialog to show modal in front of NavBar
import { CreateUrl, CallGetAPI, CallPostAPI } from 'Utils/ApiHelper.js';
import { Grid } from '@mui/material';
import MaterialButton from '@mui/material/Button';
import { DataGrid, Column, FilterRow, Pager, Paging } from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from "devextreme/data/data_source";

import { APIDownloadModel } from 'models/APIDownloadModel'
import ClipLoader from "react-spinners/ClipLoader";

import { withStyles, createStyles } from '@mui/styles';


const styles: Record<string, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        color: "#FFFFFF",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    AGmodal: {
        zIndex: "1300 !important"
    }
};

const style = createStyles(styles);


interface Props {
    classes: {
        cardTitleWhite: string;
        AGmodal: string;
    };
    show: boolean;
    onClose: () => void;
    title: string;
    product: string | undefined;
    image: string;
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    tempDownload: APIDownloadModel;
    fileDownloadUrl: string;
    inProgress: boolean;
}

class ProductPopup extends React.Component<Props, State> {
    store: ArrayStore;
    gridRef: React.RefObject<DataGrid>;
    dofileDownload: HTMLAnchorElement | null;

    constructor(props: Readonly<Props>) {
        super(props);

        this.store = new ArrayStore({
            key: 'id',
            data: []
        });

        this.state = {
            loading: true,
            tableHidden: true,
            authorized: true,
            tempDownload: {
                id: 0,
                title: "",
                category: "",
                filename: "No file selected",
                filetype: "",
                product: "",
                model: "All",
                adminOnly: false,
                description: "",
                filecontent: null
            },
            fileDownloadUrl: "",
            inProgress: false,
        };
    }

    componentDidMount(): void {
        this.reloadData()
    }
    componentDidUpdate(prevProps: Props): void {
        if (prevProps.product != this.props.product) {
            this.reloadData();
        }

    }

    reloadData(): void {
        const me = this;

        CallGetAPI(CreateUrl('/api/aquaguard/Downloads?product=' + this.props.product), {})
            .then(data => {
                if (data.length > 0) {

                    // Copy the data records into deviceData, adding the clickEvent
                    const records = [];
                    for (let i = 0; i < data.length; i++) {
                        const dl = data[i] as APIDownloadModel;
                        if (dl.category == "Spec" || dl.category == "Manual") {
                            if (!dl.adminOnly || (dl.adminOnly && sessionStorage.getItem("userLevel") == "useradmin")) {
                                records.push({ type: 'insert', data: dl, index: i });

                            }
                        }
                    }

                    me.store.push(records);

                    me.setState(
                        {
                            tableHidden: false,
                            loading: false
                        })
                }
                else {
                    me.setState(
                        {
                            tableHidden: false,
                            loading: false
                        })
                }
            })
            .catch(function () {
                me.setState(
                    {
                        authorized: true,
                        tableHidden: true,
                        loading: false

                    })
            });
    }

    // Download content as file
    doDownload = (id: number): void => {
        const me = this;
        this.setState({ inProgress: true });

        this.store.byKey(id)
            .then((dl) => {

                me.setState({ tempDownload: dl });

                CallGetAPI(CreateUrl('/api/aquaguard/Downloads/' + dl.id), {})
                    .then(data => {
                        if (data.fileContent.length > 0) {

                            const blob = me.b64toBlob(data.fileContent);
                            const fileDownloadUrl = URL.createObjectURL(blob);
                            this.setState({ fileDownloadUrl: fileDownloadUrl },
                                () => {
                                    me.dofileDownload?.click();
                                    URL.revokeObjectURL(fileDownloadUrl);  // free up storage--no longer needed.
                                    me.setState({ fileDownloadUrl: "", inProgress: false })
                                })

                        }
                        else {
                            console.log("No file content");
                            me.setState({ inProgress: false });
                        }
                    })
                    .catch(function (err) {
                        console.log(err);
                        me.setState({ inProgress: false });
                    });
            });

    }

    b64toBlob = (b64Data: any, contentType = '', sliceSize = 512): Blob => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }


    itemRender(item: any): React.ReactNode {
        return (
            <div>
                <a href="#" onClick={(number): void => this.doDownload(item.key)}>{item.value}</a>
            </div>
        )
    }

    descRender(item: any): React.ReactNode {
        return (
            <div style={{ width: (item.column.width - 20), height: 100, whiteSpace: "normal", overflowWrap: 'break-word', overflowY: "scroll" }}>{item.value}</div>
        )
    }


    render(): ReactNode {

        const classes = this.props.classes;

        return (
            <div>
                {this.state.inProgress &&
                    <div style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 2000
                    }}>
                        <ClipLoader
                            size={150}
                            color={"#123abc"}
                            loading={this.state.inProgress}
                        />
                    </div>

                }

                <Dialog
                    open={this.props.show}
                >
                    <Modal.Header closeButton onHide={(): void => this.props.onClose()}>
                        <Modal.Title id="example-custom-modal-styling-title">
                            {this.props.title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="show-grid">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div style={{textAlign: "center"}} >
                                    <img src={this.props.image} height={300} />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <DataGrid

                                    repaintChangesOnly={true}
                                    dataSource={new DataSource({ store: this.store })}
                                    ref={this.gridRef} >
                                    <FilterRow visible={false} />

                                    <Column dataField="title" visible={true} dataType="string" cellRender={this.itemRender.bind(this)} />
                                    <Column dataField="description" visible={true} dataType="string" width={200} cellRender={this.descRender} />

                                    <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                    <Paging defaultPageSize={10} />
                                </DataGrid>
                            </Grid>

                        </Grid>
                    </Modal.Body>
                    <Modal.Footer>
                        <MaterialButton color="secondary" onClick={(): void => this.props.onClose()}>Close</MaterialButton>
                    </Modal.Footer>
                </Dialog>

                <a style={{display: 'none'}}
                            download={this.state.tempDownload.filename}
                            href={this.state.fileDownloadUrl}
                            ref={e => this.dofileDownload = e}
                        >download it</a>
            </div>
        )
    }

}

export default withStyles(style)(ProductPopup);