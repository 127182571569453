//import userManager from './UserManager.js';
import { identityUrl } from "../variables/identityUrl";
/*

NOTE - REMEMBER TO SET .env file REACT_APP_AUTH_URL value
TO MATCH identityUrl.js


*/

export const CreateUrl = (method) => {

    let baseUrl = window.location.protocol + '//' + window.location.hostname + ':8664';

    //DEVELOPMENT
    if (window.location.hostname === 'localhost') {
        //baseUrl = 'https://dev.aquaguardapi.cloud:443';
        //baseUrl = 'https://sww.aquaguardapi.cloud';
        //baseUrl = 'https://hungviet.aquaguardapi.cloud';
       //baseUrl = 'https://aquaguardapi.cloud:443';
        baseUrl = 'http://localhost:8665';
        //baseUrl = 'https://api.zonelog.net';		
        //baseUrl = 'https://aquaguard.cloud:8443';
    }


    // OLD DOMAINS
    //if (window.location.hostname.includes('aquaguard.cloud')) {baseUrl = 'https://' + window.location.hostname.replace('aquaguard','aquaguardapi');}
    if (window.location.hostname === 'sww.sewerguard.co.uk') { baseUrl = 'https://sww.sewerguardapi.co.uk'; }

    // NEW DOMAINS
    //default - Hoi Polloi
    if (window.location.hostname.includes('aquaguard.cloud')) { baseUrl = 'https://aquaguardapi.cloud:443'; }

    //Hungviet
    if (window.location.hostname.includes('hungviet.aquaguard.cloud')) { baseUrl = 'https://hungviet.aquaguardapi.cloud:443'; }
    if (window.location.hostname.includes('dataloggers.capnuocthuduc.vn')) { baseUrl = 'https://dataloggersapi.capnuocthuduc.vn:443'; }

    //Gutermann
    if (window.location.hostname.includes('zonelog.net')) { baseUrl = 'https://api.zonelog.net:443'; }

    //sub domains - DEV / SWW / Hydromech etc etc go here
    if (window.location.hostname.includes('dev.aquaguard.cloud')) { baseUrl = 'https://dev.aquaguardapi.cloud:443'; }
    if (window.location.hostname.includes('sww.aquaguard.cloud')) { baseUrl = 'https://sww.aquaguardapi.cloud:443'; }

    return baseUrl + method;
}

/*
 * fetchwithTimeout - default fetch uses Browser timout - 300s for Chrome
 * 
 */
async function fetchWithTimeout(resource, options = {}) {
    const { timeout = 7200000 } = options;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(resource, {
        ...options,
        signal: controller.signal
    });
    clearTimeout(id);
    return response;
}




/*
 *   Call Aquaguard API with GET method, and return data via Promise, or error
 */
export const CallGetAPI = async (method, requestOptions) => {

    //const authContext = JSON.parse(sessionStorage.getItem("oidc.user:https://ashridgeidentityserver.azurewebsites.net:AG_JSClient"));
    var oidcUrl = "oidc.user:" + identityUrl + ":AG_JSClient";

    //console.log(oidcUrl);

    const authContext = JSON.parse(sessionStorage.getItem(oidcUrl));

    //console.log(authContext);

    // Save last API call time for session timeout
    sessionStorage.setItem("lastCallAPI", Date.now());

    if (!requestOptions.headers) {
        requestOptions.headers = {};
    }
    requestOptions.headers.Authorization = 'Bearer ' + authContext.access_token;

    return new Promise(
        function (resolve, reject) {
            fetchWithTimeout(method, requestOptions)
                .then(async response => {
                    if (response.status == 204) {
                        return null;
                    }
                    else if (response.ok) {
                        return response.json();
                    }
                    else {
                        console.log(response);
                        reject(response);
                    }
                })
                .then(data => {
                    resolve(data);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err)
                });
        }
    );

}

/*
 *   Call Aquaguard API with PUT method, and return response via Promise, or error
 */
export const CallPutAPI = async (method, requestOptions) => {

    //const authContext = JSON.parse(sessionStorage.getItem("oidc.user:https://ashridgeidentityserver.azurewebsites.net:AG_JSClient"));
    var oidcUrl = "oidc.user:" + identityUrl + ":AG_JSClient";
    const authContext = JSON.parse(sessionStorage.getItem(oidcUrl));

    // Save last API call time for session timeout
    sessionStorage.setItem("lastCallAPI", Date.now());

    if (!requestOptions.headers) {
        requestOptions.headers = {};
    }
    requestOptions.headers.Authorization = 'Bearer ' + authContext.access_token;
    requestOptions.method = 'PUT';

    return new Promise(
        function (resolve, reject) {
            fetchWithTimeout(method, requestOptions)
                .then(async response => {
                    resolve(response);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        }
    );

}

/*
 *   Call Aquaguard API with POST method, and return response via Promise, or error
 */
export const CallPostAPI = async (method, requestOptions) => {

    //const authContext = JSON.parse(sessionStorage.getItem("oidc.user:https://ashridgeidentityserver.azurewebsites.net:AG_JSClient"));
    var oidcUrl = "oidc.user:" + identityUrl + ":AG_JSClient";
    const authContext = JSON.parse(sessionStorage.getItem(oidcUrl));

    // Save last API call time for session timeout
    sessionStorage.setItem("lastCallAPI", Date.now());

    if (!requestOptions.headers) {
        requestOptions.headers = {};
    }
    requestOptions.headers.Authorization = 'Bearer ' + authContext.access_token;
    requestOptions.method = 'POST';

    return new Promise(
        function (resolve, reject) {
            fetchWithTimeout(method, requestOptions)
                .then(async response => {
                    resolve(response);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        }
    );

}

/*
 *   Call Aquaguard API with DELETE method, and return response via Promise, or error
 */
export const CallDeleteAPI = async (method, requestOptions) => {
    //const authContext = JSON.parse(sessionStorage.getItem("oidc.user:https://ashridgeidentityserver.azurewebsites.net:AG_JSClient"));
    var oidcUrl = "oidc.user:" + identityUrl + ":AG_JSClient";
    const authContext = JSON.parse(sessionStorage.getItem(oidcUrl));

    // Save last API call time for session timeout
    sessionStorage.setItem("lastCallAPI", Date.now());

    if (!requestOptions.headers) {
        requestOptions.headers = { 'Accept': '*/*' };
    }
    requestOptions.headers.Authorization = 'Bearer ' + authContext.access_token;
    requestOptions.method = 'DELETE';

    return new Promise(
        function (resolve, reject) {
            fetchWithTimeout(method, requestOptions)
                .then(async response => {
                    resolve(response);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        }
    );

}


// Promise
/*
export var CheckTokenExpiry = new Promise(
    function (resolve, reject) {
        console.log(localStorage.getItem('tokenExpiry'))
        console.log(Date.now())
        if (Date.now() >= localStorage.getItem('tokenExpiry')) {
            let message = 'Token expired, refreshing'
            userManager.signinSilent().then((user) => {
                console.log("signed in", user);
                resolve(message)
            })
            .catch((err) => {
                console.log(err);
                reject(err)
            });
            
        }
        else{
            let secondsLeft = localStorage.getItem('tokenExpiry') - Date.now()
            let message = 'Token still valid, expires in: ' + secondsLeft + ' seconds'
            resolve(message);
        }
        }
    
);
*/
