import React, { ChangeEvent, ReactNode } from "react";
import { Redirect } from "react-router-dom";

// @mui/material
import { Container, Grid } from '@mui/material';
import MaterialButton from '@mui/material/Button';
import Menu from '@mui/material/Menu';

//AG Utils
import { CreateUrl, CallGetAPI, CallPutAPI, CallPostAPI } from 'Utils/ApiHelper.js';
import { NotificationManager } from 'react-notifications';
import { APIGetLoggerAlarmsModel, buildAPIGetLoggerAlarmsModel } from "models/APIGetLoggerAlarmsModel";
// DevExtreme Components
import { Chart, Crosshair, Font, Series, Size, Label as LabelChart, ArgumentAxis, TickInterval, Legend, CommonSeriesSettings, Point, LoadingIndicator, AxisLabel, ValueAxis } from 'devextreme-react/chart';

import { TabPanel, Item as TabItem } from 'devextreme-react/tab-panel';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

//Moment date/time formatting
//https://momentjs.com/docs/
import moment, { Duration } from 'moment';
import { isLCLLogger } from 'variables/chariotProductIds';
import ClipLoader from "react-spinners/ClipLoader";

//import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

//further info panels
import FirmwareUpdates from '../Devices/FirmwareUpdates';
import UndeployedLoggers from '../Devices/UndeployedLoggers';
import UpdatesPending from '../Devices/UpdatesPending';
import NonReporting from '../Devices/NonReportingDeviceList';
import PoorSignal from '../Devices/PoorSignal';
import LowBattery from '../Devices/LowBattery';
import InAlarm from '../Alarms/AlarmList';
import Devices from '../Devices/DeviceListHomepage';
import { deviceNormal, deviceConfigUpdate, deviceFirmwareUpdate, deviceUndeploy, deviceUndeployed, deviceDormant } from '../../variables/deviceStatusColours';
import SystemInfoBar from 'components/Navbars/SystemInfoBar';
import blueMarker from '../../assets/mapLocationMarkers/pinBlue.png';
import redMarker from '../../assets/mapLocationMarkers/pinRed.png';
import greenMarker from '../../assets/mapLocationMarkers/pinGreen.png'; 
import orangeMarker from '../../assets/mapLocationMarkers/pinOrange.png'; 
import yellowMarker from '../../assets/mapLocationMarkers/pinYellow.png'; 
import purpleMarker from '../../assets/mapLocationMarkers/pinPurple.png'; 
import lightBlueMarker from '../../assets/mapLocationMarkers/pinLightBlue.png'
import Map from 'devextreme-react/map';
import { adjustTime } from '../../Utils/AdjustTime'
//Useful URLS
// DevExtreme Responsive Box https://js.devexpress.com/Demos/WidgetsGallery/Demo/ResponsiveBox/Overview/React/DarkMoon/
// BarGuage https://js.devexpress.com/Demos/WidgetsGallery/Demo/Gauges/VariableNumberOfBars/React/Light/

//const style = createStyles(styles as Record<string, any>);

interface DailyData {
    totalDate: Date | null;
    total: number;
}

interface Stats {
    name: string;
    count: number;
    active: boolean;
    color: string;
}

interface Props {
    classes: {
        root: string;
        heading: string;
        cardTitleWhite: string;
    };
}


interface State {
    redirect: boolean;
    redirectPath: string | undefined;
    redirectProps: string | undefined;
    modal: boolean;
    statsActivity: boolean[];
    startDate: Date;
    endDate: Date;
    guageValues: number[];
    totalLoggers: number;
    totalLCLoggers: number;
    dashStats: Stats[];
    dailyReadings: DailyData[];
    dailyConnections: DailyData[];
    isLoading: boolean;
    showFurtherDetails: boolean;
    showLoggers: boolean;
    tabSelected: number;
    lowBatteryDevices: [];
    inactiveTimeFrame: string | null;
    timeFrameChange: boolean;
    mapKey: string;
    alarms: APIGetLoggerAlarmsModel[];
    mapIconLoggerFilter: number[];
    zoom: number;
    deviceData: DeviceData[];
}

interface DeviceData {
    id: number;
    serial: string;
    site: string;
    model: string;
    channels: string;
    lastConnected: Date | null;
    alarmState: string;
    siteId: number | null;
    siteName: string;
    siteRef: string;
    deployed: string;
    sendToLogger: boolean;
    updateRequested: Date | null;
    updateConfirmed: Date | null;
    firmwareToUpdate: number | null;
    firmwareSent: Date | null;
    firmwareConfirmed: Date | null;
    gpsLatitude: number | null;
    gpsLongitude: number | null;
    configurationId: string;
    undeploy: boolean;
    shutdown: boolean;
    dormant: boolean;
    company: string;
    modelId: string;
    channelsAvail: number;
    meterConfig: number;
    favourite: boolean;
}


const guageFormat = {
    type: 'fixedPoint',
    precision: 0,
};

const overViewStyle = {
    backgroundColor: "#f7f7f7",
    borderRadius: "5px",
    padding: "10px 0 0 0",
    width: "90%",
    boxShadow: "2px 2px 10px #00000060",
    marginBottom: "30px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}

let userTimeFrame = 1
if (sessionStorage.getItem('inactiveTimeframe') === "14 Days") {
    userTimeFrame = 14
} else if (sessionStorage.getItem('inactiveTimeframe') === "7 Days") {
    userTimeFrame = 7
} else if (sessionStorage.getItem('inactiveTimeframe') === "4 Days") {
    userTimeFrame = 4
} else if (sessionStorage.getItem('inactiveTimeframe') === "3 Days") {
    userTimeFrame = 3
}

function screen(width: number) {
    return (width < 900) ? 'sm' : 'lg';
}


export class MainDashboard extends React.Component<Props, State> {
    getValueChangedHandler: (productIndex: any) => (e: any) => void;

    constructor(props: Readonly<Props>) {
        super(props);

        const endDate = new Date();
        const startDate = new Date();
        startDate.setDate(endDate.getDate() - 7);
        endDate.setDate(endDate.getDate() - 1);

        this.state = {
            redirect: false,
            redirectPath: undefined,
            redirectProps: undefined,
            modal: false,
            startDate: startDate,
            endDate: endDate,
            totalLoggers: 0,
            totalLCLoggers: 0,
            dashStats: [],
            dailyReadings: [],
            dailyConnections: [],
            statsActivity: [],
            guageValues: [],
            isLoading: true,
            showFurtherDetails: false,
            showLoggers: false,
            mapData: [],
            snapshotMapData: [],
            apiData: [],
            poorSignalDevices: [],
            firmwareUpdateDevices: [],
            lowBatteryDevices: [],
            gps: [],
            gpsFiltered: false,
            showFilteredLoggers: false,
            tabSelected: 0,
            inactiveTimeFrame: sessionStorage.getItem('inactiveTimeframe'),
            timeFrameChange: false,
            mapKey: "",
            alarms: [],
            mapIconLoggerFilter: sessionStorage.getItem('selectedLoggers') ? sessionStorage.getItem('selectedLoggers')?.split(',').map(item => parseInt(item)) : [],
            zoom: 10,
            deviceData: [] 
        };
        
        this.getValueChangedHandler = (statIndex) => (e) => {
            const statsActivity = this.state.statsActivity.slice();
            
            statsActivity[statIndex] = e.value;
            this.setState({
                statsActivity,
                guageValues: this.state.dashStats
                    .map((p, i) => (this.state.statsActivity[i] ? p.count : 0))
                    .filter((c) => c !== null),
            });
            
        };

        this.showFurtherDetailsPopUp = this.showFurtherDetailsPopUp.bind(this)
        this.hideFurtherDetailsPopUp = this.hideFurtherDetailsPopUp.bind(this)
    }

    
    showFurtherDetailsPopUp(): void {
        this.setState({ showFurtherDetails: true });
    }

    hideFurtherDetailsPopUp(): void {
        this.setState({ showFurtherDetails: false });
    }

    blankFunction = function () {
        return undefined;
    };

    //called from componentDidMount or say a periodic refresh timer
    getDashboardDataFromAPI = () => {

        const me = this;

        const companyId = sessionStorage.getItem('companyId');

        //temp data arrays
        const dailyReadingsData = new Array<DailyData>();
        const dailyConnectionsData = new Array<DailyData>();
        const statsData = new Array<Stats>();

        //http://aquaguardapi.co.uk:8664/api/Dashboard/api/dashboard/dailyconnections?startDate=04%2F30%2F2022&endDate=05%2F30%2F2022

        if (companyId) {

            //Stats
            CallGetAPI(CreateUrl('/api/Dashboard/dashboardstats?companyid=' + companyId + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
                .then(data => {
                    if (data?.length > 0) {
                        this.setState({ apiData: data }) 
                        let total = 0;
                        let totalLC = 0;
                        let statscolor = deviceNormal;

                        // Copy the data records into stats
                        for (let i = 0; i < data.length; i++) {

                            if (data[i].name.toLowerCase() == 'total')
                            {
                                total = data[i].count;
                                continue;
                            }

                            if (data[i].name.toLowerCase() == 'totallc')
                            {
                                totalLC = data[i].count;
                                continue;
                            }

                            if (data[i].name.toLowerCase().includes('undeployed')) statscolor = deviceUndeployed;
                            if (data[i].name.toLowerCase().includes('firmware')) statscolor = deviceFirmwareUpdate;
                            if (data[i].name.toLowerCase().includes('config')) statscolor = deviceConfigUpdate;

                            const rec: Stats = {
                                name: data[i].name,
                                count: data[i].count,
                                active: true,
                                color: statscolor
                            };
                            statsData.push(rec);
                        }

                        const rec: Stats = {
                            name: 'Total',
                            count: total + totalLC,
                            active: true,
                            color: statscolor
                        };
                        statsData.push(rec);
                        
                        const updateStats = statsData.filter(item => {
                            if (this.state.inactiveTimeFrame === '24 Hours') {
                                return !['Inactive (7 Day)', 'Inactive (4 Day)', 'Inactive (3 Day)', 'Inactive (14 Day)'].includes(item.name);
                            } else if (this.state.inactiveTimeFrame === '3 Days') {
                                return !['Inactive (24 Hour)', 'Inactive (7 Day)', 'Inactive (4 Day)', 'Inactive (14 Day)'].includes(item.name);
                            } else if (this.state.inactiveTimeFrame === '4 Days') {
                                return !['Inactive (24 Hour)', 'Inactive (3 Day)', 'Inactive (7 Day)', 'Inactive (14 Day)'].includes(item.name);
                            } else if (this.state.inactiveTimeFrame === '7 Days') {
                                return !['Inactive (24 Hour)', 'Inactive (3 Day)', 'Inactive (4 Day)', 'Inactive (14 Day)'].includes(item.name);
                            } else if (this.state.inactiveTimeFrame === '14 Days') {
                                return !['Inactive (24 Hour)', 'Inactive (3 Day)', 'Inactive (4 Day)', 'Inactive (7 Day)'].includes(item.name);
                            }
                            else {
                                return !['Inactive (7 Day)', 'Inactive (4 Day)', 'Inactive (3 Day)', 'Inactive (14 Day)'].includes(item.name);
                            }
                        })
                       
                      
                           
                        
                        me.setState(
                            {
                                dashStats: updateStats,
                                statsActivity: statsData.map((p) => p.active),
                                
                                totalLoggers: total,
                                totalLCLoggers: totalLC,
                                showLoggers: true                   //Delay getloggers until data loaded
                            })
                    }
                    else {
                        me.setState(
                            {
                                dashStats: [],
                                showLoggers: true                   //Delay getloggers until data loaded
                            })
                    }
                })
                .catch((error: Error) => {
                    me.setState(
                        {
                            dashStats: [],
                            showLoggers: true                   //Delay getloggers until data loaded
                        });
                    console.log("Unauthorized!:{0}", error);
                });


            this.setState({ isLoading: false });

        }

        return;
    }


    //Api call for Poor signal devices 
    handlePoorSignalData = () => {

        const deviceData = new Array<DeviceData>();

        const me = this;


        CallGetAPI(CreateUrl('/api/Dashboard/poorsignal?companyid=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
            .then(data => {
                if (data?.length > 0) {
                    // Copy the data records into deviceData, adding the clickEvent
                    deviceData.length = 0; //clear dummy data
                    for (let i = 0; i < data.length; i++) {
                        const serial = data[i].serial;
                        const rec: DeviceData = {
                            serial: serial,
                        };
                        deviceData.push(rec);
                        
                    }
                    this.setState({ poorSignalDevices: deviceData })
                }

            })


    }
    handleFirmwareUpdateData = ():void => {

        const deviceData = new Array<DeviceData>();

        const me = this;
        CallGetAPI(CreateUrl('/api/aquaguard/GetFirmwareUpdateLoggers?companyId=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId') + "&deployed=false"), {})
            .then(data => {
                if (data?.length > 0) {
                    // Copy the data records into deviceData, adding the clickEvent
                    deviceData.length = 0; //clear dummy data
                    for (let i = 0; i < data.length; i++) {
                        const serial = data[i].serial;
                        const rec: DeviceData = {
                            serial: serial
                        };
                        deviceData.push(rec);

                    }
                    this.setState({ firmwareUpdateDevices: deviceData })                   
                }

            })
    

    }

    handleLowBatteryData = ():void => {

        const deviceData = new Array<DeviceData>();

        const me = this;
        CallGetAPI(CreateUrl('/api/Dashboard/lowbattery?companyid=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
            .then(data => {
                if (data?.length > 0) {
                    // Copy the data records into deviceData, adding the clickEvent
                    deviceData.length = 0; //clear dummy data
                    for (let i = 0; i < data.length; i++) {
                        const serial = data[i].serial;
                        const rec: DeviceData = {
                            serial: serial
                        };
                        deviceData.push(rec);

                    }
                    this.setState({ lowBatteryDevices: deviceData })
                }

            })


    }

    handleAlarmData = (): void => {
        let alarmData = new Array<APIGetLoggerAlarmsModel>();

        const me = this;

        CallGetAPI(CreateUrl('/api/aquaguard/ActiveAlarms?companyId=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
            .then(data => {
                if (data?.length > 0) {
                    // Copy the data records into deviceData, adding the clickEvent
                    alarmData = buildAPIGetLoggerAlarmsModel(data);
                    
                    me.setState(
                        {
                            alarms: alarmData,
                        })
                }
                else {
                    me.setState(
                        {
                            alarms: [],
                           
                        })
                }
            })
            .catch(function (ex) {
                console.log(ex);
                
            });
    }



    componentDidMount(): void {
        
     
        CallGetAPI(CreateUrl('/api/aquaguard/mapkey'), {})
            .then(data => {
               
                this.setState({ mapKey: data });

                // Call all functions simultaneously
                
                this.getDashboardDataFromAPI()
                this.handleDeviceData()
                
            })
            .catch(err => console.log(err));
       
    }


    componentDidUpdate(prevProps, prevState): void {
        if (prevState.showFilteredLoggers !== this.state.showFilteredLoggers) {
           
            this.updateMapData();
            
      }
        // Add similar conditions for other state variables if needed
    }
    
    updateMapData(): void {
        
            
            this.setState({ mapData: this.state.gps, gpsFiltered: false });
        
    }

    deviceMapClick = (item): void => {

        this.setState(
            {
                redirect: true,
                //redirectPath: this.state.tempDevice?.model != "LCL" ? '/portal/DeviceDetail' : '/portal/LCLDeviceDetail',
                redirectPath: !isLCLLogger(item.model) ? '/portal/DeviceOverview' : '/portal/LCLDeviceDetail',
                redirectProps: {
                    serial: item.serial
                }
            });
    }
    createSuccessNotification = (): void => {
        NotificationManager.success('Saved Changes', 'Success');

    };

    createErrorNotification = (): void => {
        NotificationManager.error('Error Saving Changes', 'Click me!', 5000, () => {
            alert('callback');
        })
    };

    handleTabClick = (index: number): void => {
       const prevTab = this.state.tabSelected
       
        if (prevTab === 0) {
            //    Saves any new favourites
            const me = this
            const requestOptions = {
               method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    userid: sessionStorage.getItem('userId'),
                    userGuid: sessionStorage.getItem('userGuid'),
                    favourites: sessionStorage.getItem('favourites')
                })
            };

            CallPostAPI(CreateUrl('/api/aquaguard/SetUserFavouritesIdString'), requestOptions)
                .then(data => {
                    if (!data) {
                       me.createErrorNotification();
                        
                 }
                })

                .catch(function (error) {
                    me.createErrorNotification();
                    
                });
        }
        this.setState({ tabSelected: index });   
        this.updateMapIcons(index)
        
    };

    

    updateMapIcons = (tabNum: number):void => {
        
        this.setState({ mapData: this.state.snapshotMapData}, () => {
            let filteredIconData = this.state.snapshotMapData
            
            if (tabNum === 1) {
                
                filteredIconData = this.state.mapData.filter((item) => {
                    return item.iconSrc === blueMarker
                })
                
                
            } else if
                (tabNum === 2) {
                
                filteredIconData = this.state.mapData.filter((item) => {
                    return item.iconSrc === lightBlueMarker
                })
            } else if
                (tabNum === 3) {
                
                filteredIconData = this.state.mapData.filter((item) => {
                    return item.iconSrc === purpleMarker
                })
            } else if
                (tabNum === 4) {
                
                filteredIconData = this.state.mapData.filter((item) => {
                    return item.iconSrc === orangeMarker
                })
            } else if
                (tabNum === 5) {
                
                filteredIconData = this.state.mapData.filter((item) => {
                    return item.iconSrc === redMarker
                })
            }
            
            this.setState({ mapData: filteredIconData })
            
        })
        
        
        
    }
    handleSelectedLoggers = async (data): void => {
        console.log('clicked')
        this.setState({ mapIconLoggerFilter: data })
        this.handleDeviceData(this.state.snapshotMapData)
        
    }



    handleDeviceData = async () => {
        const deviceData = new Array<DeviceData>();

        const me = this;
        let company = sessionStorage.getItem('companyId');

        if (sessionStorage.getItem('userLevel') == "identityadmin") {
            company = "0";
        }
        //this.setState({ loading: true });

        CallGetAPI(CreateUrl('/api/aquaguard/getloggers?companyId=' + company + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
            .then(async (data) => {
                if (data.length > 0) {
                    // Copy the data records into deviceData, adding the clickEvent
                    deviceData.length = 0; // Clear dummy data
                    for (let i = 0; i < data.length; i++) {

                        const rec: DeviceData = {
                            id: data[i].id,  // Fixed missing id
                            serial: data[i].serial, // Fixed missing serial
                            site: data[i].site.replace(/[^A-Za-z0-9*#%$& ]/g, ''),
                            model: data[i].model,
                            channels: data[i].channels,
                            lastConnected: data[i].lastConnected ? moment(adjustTime(data[i].lastConnected)).toDate() : null,
                            alarmState: data[i].alarmState.toString(),
                            siteId: data[i].siteId,
                            siteRef: data[i].siteName,
                            siteName: data[i].siteNameUserFriendly,
                            deployed: data[i].deployed != null ? "Deployed" : "Spare",
                            sendToLogger: data[i].sendToLogger,
                            updateRequested: data[i].updateRequested,
                            updateConfirmed: data[i].updateConfirmed,
                            firmwareToUpdate: data[i].firmwareToUpdate,
                            firmwareSent: data[i].firmwareSent,
                            firmwareConfirmed: data[i].firmwareConfirmed,
                            gpsLatitude: data[i].gpsLatitude,
                            gpsLongitude: data[i].gpsLongitude,
                            configurationId: data[i].configurationId,
                            undeploy: data[i].undeploy,
                            shutdown: data[i].shutdown,
                            dormant: data[i].dormant,
                            company: data[i].company,
                            modelId: data[i].modelId,
                            channelsAvail: data[i].channelsAvail,
                            meterConfig: data[i].meterConfig,
                            favourite: data[i].favourite
                        };

                        deviceData.push(rec);
                        deviceData.sort((a, b) => (a.favourite === b.favourite) ? 0 : a.favourite ? -1 : 1);
                    }

                    this.setState({ deviceData: deviceData });

                    if (deviceData) {
                        try {
                            // Wait for async operations
                            await this.handlePoorSignalData();
                            await this.handleFirmwareUpdateData();
                            await this.handleLowBatteryData();
                            await this.handleAlarmData();

                            const userSetInactivity = moment().subtract(userTimeFrame, 'days').valueOf();

                            const mapFilterData = deviceData.filter(item => {
                                if (this.state.mapIconLoggerFilter.length === 0) {
                                    return item;
                                } else {
                                    if (this.state.mapIconLoggerFilter.includes(item.id)) {
                                        return item;
                                    }
                                }
                            });

                            const gps = mapFilterData.map((item) => {
                                const lastConnected = item.lastConnected;

                                for (let i = 0; i < this.state.alarms.length; i++) {
                                    if (this.state.alarms[i].loggerSerial === item.serial) {
                                        item.alarm = true;
                                    }
                                }

                                for (let i = 0; i < this.state.poorSignalDevices.length; i++) {
                                    if (this.state.poorSignalDevices[i].serial === item.serial) {
                                        item.poorSignal = true;
                                    }
                                }

                                for (let i = 0; i < this.state.firmwareUpdateDevices.length; i++) {
                                    if (this.state.firmwareUpdateDevices[i].serial === item.serial) {
                                        item.firmwareUpdate = true;
                                    }
                                }

                                for (let i = 0; i < this.state.lowBatteryDevices.length; i++) {
                                    if (this.state.lowBatteryDevices[i].serial === item.serial) {
                                        item.lowBattery = true;
                                    }
                                }

                                if (item.alarm) {
                                    return {
                                        ...item,
                                        iconSrc: redMarker,
                                        location: [item.gpsLatitude, item.gpsLongitude],
                                        onClick: (): void => { this.deviceMapClick(item); }
                                    };
                                } else if (lastConnected < userSetInactivity) {
                                    return {
                                        ...item,
                                        iconSrc: blueMarker,
                                        location: [item.gpsLatitude, item.gpsLongitude],
                                        onClick: (): void => { this.deviceMapClick(item); }
                                    };
                                } else if (item.poorSignal) {
                                    return {
                                        ...item,
                                        iconSrc: lightBlueMarker,
                                        location: [item.gpsLatitude, item.gpsLongitude],
                                        onClick: (): void => { this.deviceMapClick(item); }
                                    };
                                } else if (item.firmwareUpdate) {
                                    return {
                                        ...item,
                                        iconSrc: orangeMarker,
                                        location: [item.gpsLatitude, item.gpsLongitude],
                                        onClick: (): void => { this.deviceMapClick(item); }
                                    };
                                } else if (item.lowBattery) {
                                    return {
                                        ...item,
                                        iconSrc: purpleMarker,
                                        location: [item.gpsLatitude, item.gpsLongitude],
                                        onClick: (): void => { this.deviceMapClick(item); }
                                    };
                                } else {
                                    return {
                                        ...item,
                                        iconSrc: greenMarker,
                                        location: [item.gpsLatitude, item.gpsLongitude],
                                        onClick: (): void => { this.deviceMapClick(item); }
                                    };
                                }
                            });

                            const updatedMapData = gps.filter(item => {
                                return !((item.location[0] > -1 && item.location[0] < 1) && (item.location[1] > -1 && item.location[1] < 1));
                            });

                            if (updatedMapData.length !== gps.length) {
                                this.setState({ gpsFiltered: true });
                            }

                            this.setState({
                                mapData: this.state.showFilteredLoggers ? gps : updatedMapData,
                                gps: gps,
                                snapshotMapData: this.state.showFilteredLoggers ? gps : updatedMapData,
                                zoom: 5,
                                isLoading: false
                            });
                        } catch (error) {
                            console.error('Error handling device data:', error);
                        }
                    }
                }
            });
    };


    
    updateTimeFrame = (newState: string): void => {
        this.setState({ inactiveTimeFrame: newState }, () => { this.getDashboardDataFromAPI()} )
    };

    

    
    render(): ReactNode {
        
        return (!this.state.redirect) ?
            (<div >
                
                {this.state.isLoading &&
                    <div style={{
                        position: 'relative', left: '40%', top: '50%',
                    }}>
                        <ClipLoader
                            size={50}
                            color={"#123abc"}
                            loading={this.state.isLoading}
                        />
                    </div>
                }

                {!this.state.isLoading &&
                    <div>
                        {/* GRID FORMATTING using MUI https://mui.com/material-ui/react-grid */}
                        <div style={{marginTop: "20px"} }>
                            <ResponsiveBox
                                singleColumnScreen="sm"
                                screenByWidth={screen}>
                                <Row ratio={1}></Row>
                                <Row ratio={2}></Row>
                                <Row ratio={1}></Row>
                                <Row ratio={1}></Row>
                                <Row ratio={1}></Row>
                                

                                <Col ratio={1} screen="lg"></Col>
                                <Col ratio={1} screen="lg"></Col>
                                <Col ratio={1} screen="lg"></Col>
                                <Col ratio={1} screen="lg"></Col>
                                <Col ratio={1} screen="lg"></Col>
                                <Col ratio={1}></Col>
                                <Item>
                                    <Location
                                        row={0}
                                        col={0}
                                        colspan={6}
                                        screen="lg"
                                    ></Location>
                                    <Location
                                        row={0}
                                        col={0}
                                        screen="sm"
                                    ></Location>
                                    <SystemInfoBar color="warning" styling={overViewStyle} />
                                </Item>
                                <Item>
                                    <Location
                                        row={1}
                                        col={0}
                                        screen="lg"
                                    ></Location>
                                    <Location
                                        row={0}
                                        col={0}
                                        screen="sm"
                                       
                                    ></Location>
                                    <div className="item"
                                        onClick={(): void => this.handleTabClick(0)}
                                        style={{
                                            borderLeft: "10px solid #00bf00",
                                            ...overViewStyle
                                        }}>
                                        <p>Total Loggers</p>
                                        <p style={{
                                            fontSize: "22px",
                                            marginLeft: "10px"
                                        }}><b>{this.state.dashStats[5] !== undefined ? this.state.dashStats[5].count : "Loading..."}</b>
                                        </p>
                                        
                                    </div>
                                </Item>
                                <Item>
                                    <Location
                                        row={1}
                                        col={1}
                                        screen="lg"
                                    ></Location>
                                    <Location
                                        row={1}
                                        col={2}
                                        screen="sm"
                                    ></Location>
                                    <div className="item"
                                        onClick={(): void => this.handleTabClick(1)}
                                        style={{
                                            borderLeft: "10px solid #009fe3",
                                            ...overViewStyle
                                        }}>
                                        <p>Inactive Loggers</p>
                                        <p style={{ fontSize: "22px", marginLeft: "10px" }}><b>{this.state.dashStats[0] !== undefined ? this.state.dashStats[0].count : "Loading..."}</b></p>

                                    </div>
                                </Item>
                                <Item>
                                    <Location
                                        row={1}
                                        col={2}
                                        screen="lg"
                                    ></Location>
                                    <Location
                                        row={3}
                                        col={2}
                                        screen="sm"
                                    ></Location>
                                    <div className="item"
                                        onClick={(): void => this.handleTabClick(2)}
                                        style={{
                                            borderLeft: "10px solid #00ADBA",
                                            ...overViewStyle
                                        }}>
                                        
                                        <p>Poor Signal</p>
                                        <p style={{ fontSize: "22px", marginLeft: "10px" }}><b>{this.state.dashStats[4] !== undefined ? this.state.dashStats[4].count : "Loading..."}</b></p>
                                    </div>
                                </Item>
                                
                                <Item>
                                    <Location
                                        row={1}
                                        col={3}
                                        screen="lg"
                                    ></Location>
                                    <Location
                                        row={4}
                                        col={2}
                                        screen="sm"
                                    ></Location>
                                    <div className="item"
                                        onClick={(): void => this.handleTabClick(3)}
                                        style={{
                                            borderLeft: "10px solid #6f2da8",
                                            ...overViewStyle
                                        }}>
                                        <p>Low Battery</p>
                                        <p style={{ fontSize: "22px", marginLeft: "10px" }}><b>{this.state.dashStats[3] !== undefined ? this.state.dashStats[3].count : "Loading..."}</b></p>

                                    </div>
                                </Item>
                                <Item>
                                    <Location
                                        row={1}
                                        col={4}
                                        screen="lg"
                                    ></Location>
                                    <Location
                                        row={5}
                                        col={2}
                                        screen="sm"
                                    ></Location>
                                    <div className="item"
                                        onClick={(): void => this.handleTabClick(4)}
                                        style={{
                                            borderLeft: "10px solid #ff6700",
                                            ...overViewStyle
                                        }}>
                                        <p>Updates Due</p>
                                        <p style={{ fontSize: "22px", marginLeft: "10px" }}><b>{this.state.dashStats[1] !== undefined ? this.state.dashStats[1].count : "Loading..."}</b></p>
                                        
                                    </div>
                                </Item>
                                <Item>
                                    <Location
                                        row={1}
                                        col={5}
                                        screen="lg"
                                    ></Location>
                                    <Location
                                        row={6}
                                        col={2}
                                        screen="sm"
                                    ></Location>
                                    <div className="item"
                                        onClick={(): void => this.handleTabClick(5)}
                                        style={{
                                            borderLeft: "10px solid red",
                                            ...overViewStyle
                                        }}>
                                        <p>In Alarm (Last 24hrs)</p>
                                        <p style={{ fontSize: "22px", marginLeft: "10px" }}><b>{this.state.dashStats[2] !== undefined ? this.state.dashStats[2].count : "Loading..."}</b></p>
                                    </div>
                                </Item>
                                {(this.state.gpsFiltered && !this.state.showFilteredLoggers) && <Item>
                                    
                                    <Location
                                        row={4}
                                        col={4}
                                        colspan={2}
                                        screen="lg"
                                    ></Location>
                                    <Location
                                        row={8}
                                        col={1}
                                        screen="sm"
                                    ></Location>
                                    
                                    <div style={{ marginTop: "30px", marginBottom: "0px", paddingBottom: "20px", marginLeft: "10px", padding: "10px", borderLeft: "10px solid red" , borderRadius: "5px", boxShadow: "2px 2px 10px #00000060" } }>
                                        <h5>Some logger locations have been filtered from the map</h5>
                                        <p>To display all loggers on the map please update the gps location of the missing loggers</p>
                                        <p>This is due to the loggers location being set to 0 latitude and 0 longitude</p>
                                        <MaterialButton variant="contained" onClick={() => { this.setState({ showFilteredLoggers: true, gpsFiltered: false }) }}>Show loggers with no gps data</MaterialButton>

                                    </div>
                                </Item>}
                                 <Item>
                                    <Location
                                        row={3}
                                        col={4}
                                        colspan={2}
                                        screen="lg"
                                    ></Location>
                                    <Location
                                        row={8}
                                        col={1}
                                        screen="sm"
                                    ></Location>
                                    <Map
                                        defaultZoom={this.state.zoom}
                                        markers={this.state.mapData}
                                        height="100%"
                                        width="100%"
                                        style={{ marginLeft: "5px", minHeight: "500px" }}
                                        controls={true}
                                        apiKey={this.state.mapKey}
                                    ></Map>
                                </Item>



                                {this.state.showLoggers &&
                                    <Item>
                                        <Location
                                            row={3}
                                            col={0}
                                            colspan={4}
                                            rowspan={2}
                                            screen="lg"
                                        ></Location>
                                        <Location
                                            row={7}
                                            col={0}
                                            colspan={4}
                                            rowspan={2}
                                            screen="sm"
                                        ></Location>
                                        {/* if you wish to hide an item based on the screen size, it is sufficient not to specify the item's location for that screen size.
                                     * https://codesandbox.io/s/overview-devextreme-responsive-box-forked-ffxrp4?file=/App.js
                                     * Hide Grids on Small Screens
                                    <Location
                                        row={1}
                                        col={0}
                                        colspan={2}
                                        screen="sm"
                                    ></Location>
                                    */}
                                        <div className="">
                                            <div className="">
                                                <TabPanel
                                                    height={'100%'}
                                                    loop={true}
                                                    showNavButtons={true}
                                                    style={{ marginRight: "5px"}}
                                                    selectedIndex={this.state.tabSelected}
                                                    onOptionChanged={(e): void => {
                                                        
                                                        if (e.name === 'selectedIndex') {
                                                            this.handleTabClick(e.value);
                                                            
                                                        }
                                                    }}
                                                >
                                                    
                                                    <TabItem title='All Loggers'>
                                                        <div>
                                                            <Devices deviceData={this.state.deviceData} handleSelectedLoggers={this.handleSelectedLoggers}  />
                                                        </div>
                                                        </TabItem>
                                                    
                                                    <TabItem title='Not Connecting (user defined)'>
                                                        <div>
                                                            <NonReporting changeOfTimeFrame={this.updateTimeFrame} />
                                                        </div>
                                                    </TabItem>
                                                    <TabItem title='Poor Signal'>
                                                        <div>
                                                            <PoorSignal history={{ goBack: this.blankFunction }} onReloadData={this.handlePoorSignalData}  />
                                                        </div>
                                                    </TabItem>
                                                    <TabItem title='Low Battery'>
                                                        <div>
                                                            <LowBattery history={{ goBack: this.blankFunction }}  />
                                                        </div>
                                                    </TabItem>
                                                    <TabItem title='Firmware Updates'>
                                                        <div>
                                                            <FirmwareUpdates history={{ showBackButton: false, goBack: this.blankFunction }}  />
                                                        </div>
                                                    </TabItem>
                                                    
                                                        <TabItem title='In Alarm'>
                                                            <div>
                                                            <InAlarm history={{ showBackButton: false, goBack: this.blankFunction }} />
                                                            </div>
                                                        </TabItem>
                                                    
                                                    
                                                </TabPanel>
                                            </div>
                                        </div>
                                    </Item>
                            }
                            </ResponsiveBox>

                        </div>
                    </div>
                }
            </div>)
            :
            (<Redirect push to={{
                pathname: this.state.redirectPath,
                state: this.state.redirectProps
            }} />);
    }
}
export default (MainDashboard);