import React, { ReactNode } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Modal from 'react-bootstrap/Modal'
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { withStyles, createStyles } from '@mui/styles';
import ApiFailed from '../../Utils/ApiFailed';
import ClipLoader from "react-spinners/ClipLoader";
import TextField from '@mui/material/TextField';
import { Container as BSContainer, Row as BSRow, Col as BSCol } from 'reactstrap';
import MaterialButton from '@mui/material/Button';
import { Redirect } from "react-router-dom";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Grid } from '@mui/material';
import { Select } from '@mui/material';
import Dialog from '@mui/material/Dialog';
// DevExtreme
import { DataGrid, Column, Export, GroupPanel, Grouping, FilterRow, Pager, Paging, StateStoring } from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

//Moment date/time formatting
//https://momentjs.com/docs/
import moment from 'moment';
import { adjustTime } from '../../Utils/AdjustTime'

import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';
import dxDataGrid, { dxDataGridRowObject } from "devextreme/ui/data_grid";

import { isLCLLogger } from 'variables/chariotProductIds';
import { NotificationManager } from 'react-notifications';

const styles: Record<string, any> = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

let modalTitle = "";

const style = createStyles(styles);

interface DeviceData {
    id: number;
    serial: string;
    site: string;
    model: string;
    channels: string;
    lastConnected: Date | null;
    alarmState: string;
    siteId: number | null;
    siteName: string;
    company: string;
}

interface Props {
    classes: {
        cardTitleWhite: string;
        cardCategoryWhite: string;
    };
    changeOfTimeFrame: (newState:string | null) => void;
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    visible: boolean;
    selectedLogger: number;
    devices: Array<DeviceData>;
    filterValue: Array<string | string[]>;
    tempDevice: DeviceData | undefined;
    anchorEl: EventTarget & HTMLButtonElement | null;
    deleteModal: boolean;
    modal: boolean;
    redirect: boolean;
    redirectPath: string | undefined;
    redirectProps: { serial: string | undefined; deviceId: number | undefined } | undefined;
    inactiveTimeframe: string | null;
    timeFrame: string | null;
    modalVisible: boolean;
}

class NonReportingDeviceList extends React.Component<Props, State> {

    gridRef: React.RefObject<DataGrid>;

    constructor(props: Props | Readonly<Props>) {
        super(props);
        // Ref to Datagrid to store Page, Sort & Filters
        this.gridRef = React.createRef();

        this.state = {
            tableHidden: false,
            visible: false,
            selectedLogger: 0,
            devices: [],
            loading: true,
            authorized: true,
            filterValue: [],
            tempDevice: undefined,
            anchorEl: null,
            deleteModal: false,
            modal: false,
            redirect: false,
            redirectPath: undefined,
            redirectProps: undefined,
            inactiveTimeframe: sessionStorage.getItem('inactiveTimeframe'),
            timeFrame: window.sessionStorage.getItem("inactiveTimeframe") != "null" ? window.sessionStorage.getItem("inactiveTimeframe") : "24 Hours",
            modalVisible: false
        };

    }
    get dataGrid(): dxDataGrid | undefined {
        return this.gridRef.current?.instance;
    }

    componentDidMount(): void {
        this.getApiData(this.state.inactiveTimeframe)     
    }

    getApiData(timeFrame: string): void {
        const deviceData = new Array<DeviceData>();
        let userTimeFrame = 1
        if (timeFrame === "14 Days") {
            userTimeFrame = 14
        } else if (timeFrame === "7 Days") {
            userTimeFrame = 7
        } else if (timeFrame === "4 Days") {
            userTimeFrame = 4
        }
        const me = this;

        CallGetAPI(CreateUrl('/api/aquaguard/GetNonReportingLoggers?companyId=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId') + "&startDate=" + moment().subtract(userTimeFrame, 'days').format("YYYY-MM-DD HH:mm:ss") + "&endDate=" + moment().format("YYYY-MM-DD HH:mm:ss")), {})
            .then(data => {
                if (data?.length > 0) {
                    // Copy the data records into deviceData, adding the clickEvent
                    deviceData.length = 0; //clear dummy data
                    for (let i = 0; i < data.length; i++) {
                        const serial = data[i].serial;
                        const id = parseInt(data[i].id);
                        const rec: DeviceData = {
                            id: id,
                            serial: serial,
                            site: data[i].site.replace(/[^A-Za-z0-9]/g, ''),
                            model: data[i].model,
                            channels: data[i].channels,
                            lastConnected: data[i].lastConnected ? adjustTime(moment(data[i].lastConnected).toDate()) : null,
                            alarmState: data[i].alarmState.toString(),
                            siteId: data[i].siteId,
                            siteName: data[i].siteName,
                            company: data[i].company
                        };
                        deviceData.push(rec);
                    }
                    
                    me.setState(
                        {
                            devices: deviceData,
                            tableHidden: false,
                            loading: false
                        })
                }
                else {
                    me.setState(
                        {
                            devices: [],
                            tableHidden: false,
                            loading: false
                        })
                }
            })
            .catch(function () {
                me.setState(
                    {
                        authorized: false
                    })
            });
    }

    handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: dxDataGridRowObject): void {   //React.MouseEvent<HTMLButtonElement, MouseEvent>
        const device = this.state.devices.find((row) => row.id === id.key);

        this.setState(
            {
                tempDevice: device,
                anchorEl: event.currentTarget
            });
    }

    closeDialog():void {
        this.setState({ modalVisible: false })
    }

    userTimeFrameChanged(e: React.ChangeEvent<HTMLInputElement>):void {
        this.setState({ timeFrame: e.target.value})
    }

    handleUpdateTimeFrame = (): void => {
        
        this.setState({modalVisible: true})
    }

    createSuccessNotification():void {
    NotificationManager.success('Saved Changes', 'Success');

    }

    createErrorNotification():void {
    NotificationManager.error('Error Saving Changes', 'Click me!', 5000, () => {
        alert('callback');
    });
    }

    updateUserPreferences():void {
    //********************
    //send model to API

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            UserId: sessionStorage.getItem('userId'),
            UserGuid: sessionStorage.getItem('userGuid'),
            FilterGroupId: sessionStorage.getItem('filterGroupId'),
            FlowUnits: sessionStorage.getItem('flowUnits'),
            UtcOffset: sessionStorage.getItem('utcOffset'),
            InactiveTimeframe: this.state.timeFrame
        })
    };

        this.setState({ modalVisible: false });
        this.props.changeOfTimeFrame(this.state.timeFrame);
    CallGetAPI(CreateUrl('/api/aquaguard/SetUserPreferences'), requestOptions)
        .then(data => {
            if (data.status === undefined) {
                // Update session store
                window.sessionStorage.setItem("inactiveTimeframe", this.state.timeFrame);
                this.getApiData(this.state.timeFrame)
                this.createSuccessNotification();
            }
            else {
                this.createErrorNotification();
                console.log(data.title);
            }
        })

        .catch(function (error) {
            this.createErrorNotification();
            console.log(error);
        });
}


    toggleModal = (): void => {
        this.setState({
            modal: !this.state.modal
        });
    }

    //edit row
    editDevice = (): void => {
        this.setState({
            modal: !this.state.modal,
            anchorEl: null
        });
        modalTitle = "Edit Device"
    }

    toggleDeleteModal = (): void => {
        this.setState({
            deleteModal: !this.state.deleteModal,
            anchorEl: null
        });
        modalTitle = "Warning"
    }

    handleClose = (): void => {
        this.setState({ anchorEl: null });
    }

    deleteRow = (): void => {

        const rows = this.state.devices

        const device = this.state.devices.find((device) => device.id === this.state.tempDevice?.id);

        //remove the old version of the row
        const filteredRows = rows.filter(function (row) { return row.id !== device?.id });


        this.setState({
            deleteModal: !this.state.deleteModal,
            devices: filteredRows
        });
    }

    //field changed events---
    deviceSiteChanged = (event: any): void => {
        const tempDevice = this.state.tempDevice;
        if (tempDevice) {
            tempDevice.site = event.target.value;
            this.setState({ tempDevice: tempDevice });
        }
    }

    //-------------------------

    //save changes, for both edit and new
    saveDevice = (): void => {

        const device = this.state.devices.find((device) => device.id === this.state.tempDevice?.id);
        const rows = this.state.devices

        if (device && this.state.tempDevice) {
            const deviceIndex = rows.indexOf(device);

            rows.splice(deviceIndex, 1, this.state.tempDevice);

            this.setState({
                modal: !this.state.modal,
                devices: rows
            });
        }

        //********************
        //send model to API


    }
    //do nothing, close the modal
    cancel = (): void => {
        this.toggleModal();
    }

    deviceDetailsClick = (): void => {

        this.setState(
            {
                redirect: true,
                //redirectPath: this.state.tempDevice?.model != "LCL" ? '/portal/DeviceDetail' : '/portal/LCLDeviceDetail',
                redirectPath: !isLCLLogger(this.state.tempDevice?.model) ? '/portal/DeviceDetail' : '/portal/LCLDeviceDetail',
                redirectProps: {
                    serial: this.state.tempDevice?.serial,
                    deviceId: this.state.tempDevice?.id
                }
            });
    }

    deviceOverviewClick = (): void => {
        this.setState(
            {
                redirect: true,
                redirectPath: '/portal/DeviceOverview',
                redirectProps: {
                    serial: this.state.tempDevice?.serial,
                    deviceId: this.state.tempDevice?.id
                }
            });
    }


    dateColumnCustomizeText(cellInfo: any): string {
        if (cellInfo.value == null)
            return "";
        else
            return moment(cellInfo.value).format("DD/MM/YYYY HH:mm:ss");
    }

    onExporting(e: any): void {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('NonReportingLoggers');

        exportDataGrid({
            component: e.component,
            worksheet: worksheet
        }).then(function () {
            workbook.xlsx.writeBuffer()
                .then(function (buffer: Buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), window.location.hostname.includes('zonelog.net') ? 'ZONELOG Export.xlsx' : 'Aquaguard Export.xlsx');
                });
        });
        e.cancel = true;
    }

    moreRender(key: dxDataGridRowObject): ReactNode {
        return (
            <IconButton size="small" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => { this.handleClick(e, key); }}><MoreHorizIcon /></IconButton>
        );
    }

    changeTimeFrame():void { 
    
        this.setState({
            timeFrame: sessionStorage.getItem('inactiveTimeframe'),
            modalVisible: true
        }, () => {
            this.getApiData()
        })
    }

    render(): ReactNode {
        const { classes } = this.props;
        
        return (
            (this.state.authorized) ? //if we are authorized, show page, else redirect to login page
                (
                     (!this.state.redirect) ?
                        (<div>
                            <Dialog
                                open={this.state.modalVisible}
                                onHide={() => this.closeDialog()}
                                dialogClassName="modal-100w"
                                aria-labelledby="example-custom-modal-styling-title">

                                <Modal.Header color="primary" style={{ borderRadius: "0" }}>
                                    <Modal.Title id="example-custom-modal-styling-title" style={{ paddingLeft: "20px" }}>
                                        Change non-reporting timeframe
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="show-grid">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <p style={{ marginTop: "15px" }}>Set the timeframe for non reporting loggers:</p>
                                                <Select
                                                    id="outlined-select"
                                                    label="Filter"
                                                    value={this.state.timeFrame}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>):void => this.userTimeFrameChanged(e)}
                                                    variant="outlined"
                                                    style={{ width: 200, float: "right" }}
                                                    >

                                                    <MenuItem value="24 Hours">24 Hours</MenuItem>
                                                    <MenuItem value="4 Days" >4 Days</MenuItem>
                                                    <MenuItem value="7 Days">7 Days</MenuItem>
                                                    <MenuItem value="14 Days">14 Days</MenuItem>

                                                </Select>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Modal.Body>
                                <Modal.Footer>
                                    <MaterialButton color="primary" onClick={():void => this.updateUserPreferences()}>Update Preferences</MaterialButton>
                                    <MaterialButton color="secondary" onClick={():void => this.closeDialog()}>Cancel</MaterialButton>
                                </Modal.Footer>
                            </Dialog>
                        <Modal
                            show={this.state.modal}
                            onHide={(): void => this.toggleModal()}
                            dialogClassName="modal-100w"
                            aria-labelledby="example-custom-modal-styling-title"
                            centered
                                >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-custom-modal-styling-title">
                                    {modalTitle}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="show-grid">
                                <BSContainer>
                                    <BSRow>
                                        <BSCol xs={6} md={4}>
                                            <TextField
                                                id="outlined-input"
                                                defaultValue={this.state.tempDevice?.site}
                                                label="Site"
                                                onChange={this.deviceSiteChanged}
                                                variant="outlined"
                                            />
                                        </BSCol>

                                    </BSRow>
                                </BSContainer>
                            </Modal.Body>
                            <Modal.Footer>
                                    <MaterialButton color="primary" onClick={(): void => this.saveDevice()}>Save</MaterialButton>
                                    <MaterialButton color="secondary" onClick={(): void => this.toggleModal()}>Cancel</MaterialButton>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.deleteModal}
                            onHide={(): void => this.toggleDeleteModal()}
                            dialogClassName="modal-100w"
                            aria-labelledby="example-custom-modal-styling-title"
                            centered
                                >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-custom-modal-styling-title">
                                    {modalTitle}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="show-grid">
                                Delete row?
                            </Modal.Body>
                            <Modal.Footer>
                                    <MaterialButton color="primary" onClick={(): void => this.deleteRow()}>Continue</MaterialButton>
                                    <MaterialButton color="secondary" onClick={(): void => this.toggleDeleteModal()}>Cancel</MaterialButton>
                            </Modal.Footer>
                        </Modal>

                        {this.state.loading &&
                            <div style={{
                                position: 'absolute', left: '50%', top: '50%',
                                transform: 'translate(-50%, -50%)'
                            }}>
                                <ClipLoader
                                    size={150}
                                    color={"#123abc"}
                                    loading={this.state.loading}
                                />
                            </div>
                        }
                        {!this.state.loading &&
                            <div>

                                {this.state.tableHidden &&

                                        <ApiFailed />
                                }
                                {!this.state.tableHidden &&
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <Card>
                                                    <CardHeader color="primary">
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <div style={{marginLeft: "7px"} } >
                                                            <h4 className={classes.cardTitleWhite}>Non Reporting Devices</h4>
                                                            <p className={classes.cardCategoryWhite}>
                                                                Active devices with no connections, based on user defined time period
                                                            </p>
                                                            
                                                        </div>
                                                            <div style={{display: "flex", flexDirection: "column", justifyContent: "center"} }>
                                                            <MaterialButton variant="contained" style={{height: "35px", marginRight: "10px"}} color="primary" onClick={(): void => this.changeTimeFrame()}>Change timeframe</MaterialButton>
                                                            </div>
                                                            </div>
                                                </CardHeader>
                                                <CardBody>
                                                    <DataGrid
                                                    dataSource={this.state.devices}
                                                    keyExpr="id"
                                                    ref={this.gridRef}
                                                    defaultFilterValue={this.state.filterValue}
                                                    onExporting={this.onExporting} >
                                                            {/*<StateStoring enabled={true} type="localStorage" storageKey="deviceListGrid" />*/}
                                                    <Export enabled={true} />
                                                    <GroupPanel visible={true} />
                                                    <Grouping autoExpandAll={true} />
                                                    <FilterRow visible={true} />
                                                    <Column dataField="id" visible={false} dataType="number" />
                                                    <Column caption="Company" dataField="company" dataType="string" groupIndex={0} />
                                                    <Column dataField="siteName" groupIndex={0} />
                                                    <Column dataField="serial" dataType="string" />
                                                    <Column dataField="site" dataType="string" />
                                                    <Column dataField="model" dataType="string" />
                                                    <Column dataField="channels" dataType="string" />
                                                    <Column dataField="lastConnected" dataType="datetime" customizeText={this.dateColumnCustomizeText}  sortIndex="1" sortOrder={'Desc'}/>
                                                    <Column dataField="alarmState" dataType="number" />

                                                    <Column type="buttons" cellRender={this.moreRender.bind(this)} fixed={true} fixedPosition="right" />

                                                    <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                                    <Paging defaultPageSize={10} />
                                                    </DataGrid>
                                                </CardBody>
                                            </Card>
                                        </GridItem>
                                    </GridContainer>
                                }
                            </div>
                        }
                        <Menu
                            id="simple-menu"
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={Boolean(this.state.anchorEl)}
                            onClose={this.handleClose}
                        >
                            {!isLCLLogger(this.state.tempDevice?.model) &&
                                <MenuItem onClick={this.editDevice}>Quick Edit</MenuItem>
                            }
                            {!isLCLLogger(this.state.tempDevice?.model) &&
                                <MenuItem onClick={this.deviceOverviewClick}>Overview</MenuItem>
                            }
                            <MenuItem onClick={this.deviceDetailsClick}>Details</MenuItem>
                            {!isLCLLogger(this.state.tempDevice?.model) &&
                                <MenuItem onClick={this.toggleDeleteModal}>Delete</MenuItem>
                            }
                        </Menu>
                    </div>)
                    :
                     (<Redirect push to={{
                        pathname: this.state.redirectPath,
                        state: this.state.redirectProps
                    }}/>)
                )
                :
                (
                    <Redirect to={'/Login'} />
                )
        )
    }
}

export default withStyles(style)(NonReportingDeviceList);

