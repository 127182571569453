import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { withStyles, createStyles } from '@mui/styles';
import ApiFailed from '../../Utils/ApiFailed';
import { DataGrid, Column, FilterRow, Pager, Paging, StateStoring, HeaderFilter } from 'devextreme-react/data-grid';

//Moment date/time formatting
//https://momentjs.com/docs/
import Moment from 'moment';
import { adjustTime } from '../../Utils/AdjustTime'

import ClipLoader from "react-spinners/ClipLoader";
//modal window imports
//import InputLabel from '@mui/material/InputLabel';
//import Select from '@mui/material/Select';
//import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Modal from 'react-bootstrap/Modal'
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Redirect } from "react-router-dom";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Select from 'react-select'
// import makeAnimated from 'react-select/animated';
import { CreateUrl, CallGetAPI, CallPutAPI, CallDeleteAPI } from 'Utils/ApiHelper.js';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from "devextreme/data/data_source";


const styles: Record<any, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);

let modalTitle = "";

const userOptions = [
    //{ value: 'browser', label: 'Browser' },
    { value: 'user', label: 'User' },
    { value: 'useradmin', label: 'UserAdmin' }
];

const userOptionsRes = [
    //{ value: 'browser', label: 'Browser' },
    { value: 'restricted', label: 'Restricted' },
    { value: 'user', label: 'User' },
    { value: 'useradmin', label: 'UserAdmin' }
];

interface User {
    id: number;
    userGuid: string;
    email: string;
    level: string;
    lockout: string;
    companyId: number;
    lastLogin: Date | null;
    config: string | null;
}

interface Props {
    classes: {
        cardTitleWhite: string;
        formControl: string;
        cardCategoryWhite: string;
    };
}

interface UserFilter {
    id: number;
    userGUID: string;
    filterGroupId: number;
    flowUnits: string;
    utcOffset: number;
    inactiveTimeframe: string;
}

interface Group {
    id: number | undefined;
    groupId: number | null;
    groupName: string | undefined;
    memberId: number | undefined;
    groupType: string | undefined;
    groupTypeId: number | undefined;
    generation: number | undefined;
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    visible: boolean;
    modal: boolean;
    selectedUser: number;
    colour: string;
    edit: boolean;
    editUser: boolean;
    orgUser: string;
    tempUser: User;
    tempUserlevel: { label: string; value: string };
    anchorEl: HTMLElement | null;
    redirect: boolean;
    redirectPath: string;
    redirectProps: {
        userName: string;
        userId: number;
    } | null;
    modalTitle: string;
    deleteModal: boolean;
    viewDetail: boolean;
    passwordModal: boolean;
    filterModal: boolean;
    filterGroups: Array<Group>;
    selectedFilterGroup: number;
    selectedUserInfo: UserFilter | null;
}

export class Users extends Component<Props, State> {
    store: ArrayStore;
    gridRef: React.RefObject<DataGrid>;

    constructor(props: Readonly<Props>) {

        super(props);

        this.store = new ArrayStore({
            key: 'id',
            data: []
        });

        this.state = {
            loading: true,
            tableHidden: false,
            visible: false,
            modal: false,
            selectedUser: 0,
            colour: '',
//            users: { columns: [], rows: [] },
//            regions: [],
//            devices: [],
            tempUser: {
                id: 0,
                userGuid: '',
                email: '',
                level: '',
                lockout: '',
                companyId: 0,
                lastLogin: null,
                config: ''
            },
            tempUserlevel: {label: '', value: ''},
            editUser: false,
            edit: false,
            orgUser: '',
            anchorEl: null,
            deleteModal: false,
            passwordModal: false,
            authorized: true,
            redirect: false,
            redirectPath: '',
            redirectProps: null,
            modalTitle: "",
            viewDetail: false,
            filterModal: false,
            filterGroups: [],
            selectedFilterGroup: 0,
            selectedUserInfo: null
        };

        this.gridRef = React.createRef();
        this.store.clear();

    }



    createSuccessNotification = (): void => {
        NotificationManager.success('Saved Changes', 'Success')

    };

    createErrorNotification = (): void => {
        NotificationManager.error('Error Saving Changes', 'Click me!', 5000, () => {
            alert('callback');
        });
    };

    createFailedInsertErrorNotification = (): void => {
        NotificationManager.error('Error creating new record', '', 5000);
    };

    componentDidMount(): void {

        this.reloadData(null);
    }

    // if editId is not null, then reload() re-displays Modal with record id = editId after refresh
    reloadData = (editId: number | null): void => {
        const me = this;

      
        CallGetAPI(CreateUrl('/api/aquaguard/Users?companyId=' + sessionStorage.getItem('companyId')), {})
            .then(data => {
                
                if (data.length > 0) {
                    console.log(data)
                    // Copy the data records into deviceData, adding the clickEvent
                    const records = [];
                    for (let i = 0; i < data.length; i++) {
                        const id = data[i].id;
                        
                        const rec = {
                            id: id,
                            email: data[i].email,
                            level: data[i].level,
                            lockout: data[i].lockout ? 'Locked Out' : '',
                            companyId: data[i].companyId,
                            userGuid: data[i].userGUID,
                            lastLogin: adjustTime(data[i].lastLogin),
                            config: data[i].canConfig,
                            more: <IconButton onClick={(e): void => { me.handleClick(e, id) }} size="large"><MoreHorizIcon /></IconButton>

                        };
                        records.push({ type: 'insert', data: rec, index: i });
                    }

                    me.store.push(records);

                    me.setState(
                        {
                            tableHidden: false,
                            loading: false
                        })
                }
                else {
                    me.setState(
                        {
                            tableHidden: false,
                            loading: false
                        })
                }
            })
            .catch(function () {
                me.setState(
                    {
                        authorized: false
                    })
            });


    }

   

    handleClose = (): void => {
        this.setState({ anchorEl: null });
    }

    //new row
    newUser = (): void => {
        this.setState({
            modal: true,
            editUser: false,
            tempUser: {
                id: 0,
                userGuid: '',
                email: '',
                level: 'User',
                lockout: '',
                companyId: 1,
                lastLogin: null,
                config: ''
            },
            modalTitle: "New User"
        });

    }
    //edit row
    editUser = (): void => {
        this.setState({
            editUser: true,
            modal: !this.state.modal,
            anchorEl: null,
            modalTitle: "Edit User",
            orgUser: this.state.tempUser.email,
        });
        
    }

    filterGroup():void {
        this.setState({
            filterModal: !this.state.filterModal,
            loading: true,
            anchorEl: null,
            modalTitle: "Filter user to group",

        })
        const companyId = sessionStorage.getItem('companyId')
        const groups: Array<Group> = []
        CallGetAPI(CreateUrl('/api/aquaguard/users?companyId=' + companyId), {})
            .then(data => {
                console.log(data)
                const filterUser = data.filter(user => user.email === this.state.tempUser.email)
                const user = filterUser[0]
               console.log(user)
                this.setState({
                    selectedUserInfo: user
                })
                
                CallGetAPI(CreateUrl('/api/aquaguard/GetAllGroupsForUser?userId=' + user.id), {})
                    .then(data => {
                        
                        // Create Null version for no filter select
                        

                        /// pushes a additional group name for remove the filter group - if group filter is to be removed
                        groups.push({
                            id: 0,
                            generation: 0,
                            groupId: 0,
                            groupName: "Remove filters",
                            groupType: "USER",
                            groupTypeId: 0,
                            memberId: 0
                        })
                        for (let i = 0; i < data.length; i++) {
                            groups.push(data[i])
                        }
                        this.setState({ filterGroups: groups, loading: false })
                    })
                    .catch(error => console.log(error))
                
            })
            .catch(err => console.log(err))
        
    }

    //save changes, for both edit and new
    saveUser = (): void => {

        const me = this;
        let canConfig = false
        if (me.state.tempUser.level === 'useradmin') {
            canConfig = true
        } else if (me.state.tempUser.level === 'restricted') {
            canConfig = false
        } else if (me.state.tempUser.level === 'user') {
            canConfig = me.state.tempUser.config === 'Config Allowed' ? true : false
        }
        const tempUser = me.state.tempUser
        tempUser.config = canConfig ? 'Config Allowed' : ''
        me.setState({tempUser: tempUser})
        
        if (this.state.editUser === false) { //adding a new User

            this.setState({
                modal: false,
                loading: true,
            });

            //********************
            //send model to API
          
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    Username: me.state.tempUser.email,
                    CompanyGuid: sessionStorage.getItem('companyGuid'),
                    Level: me.state.tempUser.level,
                    CanConfig: canConfig
                })
            };

            let apiURLroot = '';
            if (me.state.tempUser.level == 'user') apiURLroot = '/api/aquaguard/User';
            if (me.state.tempUser.level == 'useradmin') apiURLroot = '/api/aquaguard/AdminUser';
            
            CallGetAPI(CreateUrl(apiURLroot), requestOptions)
                .then(data => {
                    
                    if (data.status === undefined) {
                        const user = me.state.tempUser;
                        user.id = data.id
                        user.userGuid = data.userGuid;
                        user.email = data.email;
                        user.level = data.level;
                        user.lockout = data.lockout;
                        user.config = data.canConfig
                        me.store.push([{ type: 'insert', key: user.id, data: user }]);

                        me.setState({
                            tempUser: user,
                            loading: false,
                        });
                        me.createSuccessNotification()
                    }
                    else {
                        me.createFailedInsertErrorNotification();
                        me.toggleModal();
                        console.log(data.title);
                        me.setState({
                            loading: false,
                        });
                    }
                })

                .catch(function (error) {
                    me.createFailedInsertErrorNotification();
                    console.log(error)
                    me.toggleModal();
                    me.setState({
                        loading: false,
                    });
                });

        }
        else {//modifying an existing User

            //********************
            //send model to API
            
            //save User
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    username: me.state.orgUser,
                    newUsername: me.state.tempUser.email,
                    userLevel: me.state.tempUser.level,
                    lockedout: me.state.tempUser.lockout == "Locked Out",
                    canConfig: canConfig
                })
            };
            
            CallPutAPI(CreateUrl('/api/Aquaguard/User/' + me.state.tempUser.id), requestOptions)
                .then(async response => {
                    if (response.status == 204) {

                        me.createSuccessNotification()

                        me.store.push([{ type: 'update', key: me.state.tempUser.id, data: me.state.tempUser }]);

                        me.setState({
                            modal: !me.state.modal,
                            loading: false,
                        });
                    }
                    else {
                        me.toggleModal();
                        me.setState({
                            loading: false,
                        });
                    }
                    

                })
                .catch(function (error) {
                    me.createErrorNotification()
                    me.toggleModal();
                    console.log(error)
                    me.setState({
                        loading: false,
                    });
                });

        }
        this.reloadData(null)
    }


    //do nothing, close the modal
    cancel = (): void => {
        this.toggleModal();
    }

    toggleModal = (): void => {
        this.setState({
            modal: !this.state.modal,
            anchorEl: null
        });
    }

    toggleFilterModal = (): void => {
        this.setState({
            filterModal: !this.state.filterModal,
            
        })
    }

    toggleDeleteModal = (): void => {
        this.setState({
            deleteModal: !this.state.deleteModal,
            anchorEl: null
        });
        modalTitle = "Warning"
    }

    detailsClick = (): void => {
        this.setState(
            {
                viewDetail: true,
            });
    }

    deleteRow = (): void => {

        const me = this;

        //delete from database

        CallDeleteAPI(CreateUrl('/api/Aquaguard/User?id=' + me.state.tempUser.id), {})
            .then(async response => {
                if (response.ok) {
                    me.createSuccessNotification()

                    //remove the old version of the row
                    this.store.push([{ type: 'remove', key: me.state.tempUser.id }]);

                }
                me.toggleDeleteModal();
            })
            .catch(function (error) {
                me.createErrorNotification()
                console.log(error)
            });
    }

    //field changed events---
    userNameChanged = (event: any): void => {
        const tempUser = this.state.tempUser;
        tempUser.email = event.target.value;
        this.setState( {
            tempUser: tempUser
            });
    }
    userLevelChanged = (selectedOption: any): void => {
        const tempUser = this.state.tempUser;
        const selLevel = userOptions.filter((level) => level.value == selectedOption.value)[0];
        tempUser.level = selectedOption.value;
        this.setState( {
            tempUser: tempUser,
            tempUserlevel: selLevel,
            });
    }
    userLockoutChanged = (event: any): void => {
        const tempUser = this.state.tempUser;
        tempUser.lockout = event.target.checked ? event.target.value : '';
        this.setState( {
            tempUser: tempUser
            });
    }

    userConfigChanged = (event: any): void => {
        const tempUser = this.state.tempUser;
        tempUser.config = event.target.checked ? event.target.value : '';
        this.setState({
            tempUser: tempUser
        });
    }
    //-------------------------

    togglePasswordModal = (): void => {
        this.setState({
            passwordModal: !this.state.passwordModal,
            anchorEl: null
        });
        modalTitle = "Password Reset";
    }

    resetPassword = (): void => {

        const me = this;

        //reset user's password

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                UserId: this.state.tempUser.id,
                UserGuid: this.state.tempUser.userGuid,
                newPassword: "Passw0rd!",
                oldPassword: "",
            })
        };

        CallGetAPI(CreateUrl('/api/aquaguard/UserPassword'), requestOptions)
            .then(() => {           
                    me.createSuccessNotification();

                    me.setState({
                        passwordModal: !me.state.passwordModal,
                    });
                },
                // Reject
                () => {
                    me.createErrorNotification();
                    me.setState({
                        passwordModal: !me.state.passwordModal,
                    });
                })
            .catch(function (error) {
                me.createErrorNotification();
                console.log(error);
                me.setState({
                    passwordModal: !me.state.passwordModal,
                });
            });
    }

    handleClick(event: any, id: number): void {

        this.store.byKey(id)
            .then((user) => {

                const selLevel = userOptions.filter((level) => level.value == user.level)[0];
                this.setState(
                    {
                        tempUser: {
                            id: user.id,
                            userGuid: user.userGuid,
                            email: user.email,
                            companyId: user.companyId,
                            level: user.level,
                            lastLogin: user.lastLogin,
                            lockout: user.lockout,
                        },
                        tempUserlevel: selLevel,
                        anchorEl: event.currentTarget
                    });
                },
                (error) => {
                    console.log(error);
                });
    }

    handleFilterChange(e): void {
        

        this.setState({ selectedFilterGroup: e.value })
    }

    confirmFilter(): void {

        /// Saves User data to user preferences model with new FilterGroup applied
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                UserId: this.state.selectedUserInfo?.id,
                UserGuid: this.state.selectedUserInfo?.userGUID,
                FilterGroupId: this.state.selectedFilterGroup,
                FlowUnits: this.state.selectedUserInfo?.flowUnits,
                UtcOffset: this.state.selectedUserInfo?.utcOffset,
                InactiveTimeframe: this.state.selectedUserInfo.inactiveTimeframe
            })
        };

        console.log(this.state.selectedUserInfo)

        CallGetAPI(CreateUrl('/api/aquaguard/SetUserPreferences'), requestOptions)
            .then(data => {
                if (data.status === undefined) {
                    this.createSuccessNotification();
                }
                else {
                    this.createErrorNotification();
                    console.log(data.title);
                }
            })

            .catch(function (error) {
                
                console.log(error);
            });
        this.toggleFilterModal()
    }

    dateColumnCustomizeText(cellInfo: any): string {
        if (cellInfo.value == null)
            return "";
        else
            return Moment(cellInfo.value).format("DD/MM/YYYY HH:mm");
    }

    configCustomizeText(data): string {
        const value = data.value ? "Yes" : "No"
        
       return value
    }

    moreRender(Task: any): React.ReactNode {
        
        return (
            <IconButton size="small" onClick={(e): void => { this.handleClick(e, Task.data.id); }}><MoreHorizIcon /></IconButton>
        );
    }

    

    render(): React.ReactNode {
        const { classes } = this.props;


        return (this.state.authorized) ? //if we are authorized, show page, else redirect to login page
            (
                (!this.state.viewDetail) ?
                (<div>
                        <Modal
                            show={this.state.filterModal}
                            onHide={(): void => this.toggleFilterModal()}
                            dialogClassName="modal-50w"
                            aria-labelledby="example-custom-modal-styling-title"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-custom-modal-styling-title">
                                    {this.state.modalTitle}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="show-grid">
                                <p>Please select the group you would like to filter this user to</p>
                                
                                <div style={{width: "100%"} }>
                                    <Select
                                        options={this.state.filterGroups.map(item => ({ value: item.groupId, label: item.groupName }))}
                                        onChange={(e):void => this.handleFilterChange(e)}
                                    >
                                    </Select>
                                    
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button color="primary" variant="contained" style={{ marginRight: "10px" }} onClick={(): void => this.confirmFilter()}>Continue</Button>
                                <Button color="secondary" variant="contained" onClick={(): void => this.toggleFilterModal()}>Cancel</Button>
                            </Modal.Footer>
                        </Modal>
                        
                    <Modal
                            show={this.state.deleteModal}
                            onHide={(): void => this.toggleDeleteModal()}
                            dialogClassName="modal-50w"
                            aria-labelledby="example-custom-modal-styling-title"
                            centered
                        >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                    {this.state.modalTitle}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="show-grid">
                            Delete user?
                </Modal.Body>
                        <Modal.Footer>
                                <Button color="primary" variant="contained" style={{ marginRight: "10px" }} onClick={(): void => this.deleteRow()}>Continue</Button>
                                <Button color="secondary" variant="contained" onClick={(): void => this.toggleDeleteModal()}>Cancel</Button>
                        </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.passwordModal}
                            onHide={(): void => this.togglePasswordModal()}
                            dialogClassName="modal-50w"
                            aria-labelledby="example-custom-modal-styling-title"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-custom-modal-styling-title">
                                    {this.state.modalTitle}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="show-grid">
                                User {this.state.tempUser.email} will have password reset to <b>Passw0rd!</b> and be required to reset a secure password when they next login.<br/>
                                Do you want to continue?
                            </Modal.Body>
                            <Modal.Footer>
                                <Button color="primary" variant="contained" style={{ marginRight: "10px" }} onClick={(): void => this.resetPassword()}>Continue</Button>
                                <Button color="secondary" variant="contained" onClick={(): void => this.togglePasswordModal()}>Cancel</Button>
                            </Modal.Footer>
                        </Modal>

                    <Modal
                        show={this.state.modal}
                            onHide={(): void => this.toggleModal()}
                        dialogClassName="modal-50w"
                            aria-labelledby="example-custom-modal-styling-title"
                            centered
                        >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                    {this.state.modalTitle}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="show-grid">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} >
                                        <div style={{ padding: 10 }}>
                                            <TextField
                                                id="outlined-input"
                                                label="Name (Email)"
                                                value={this.state.tempUser.email}
                                                onChange={this.userNameChanged}
                                                variant="outlined"
                                                style={{width: "100%"} }
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <div style={{ padding: 10 }}>
                                            <Select
                                                    id="outlined-select"
                                                    label="Level"
                                                    defaultValue={this.state.tempUserlevel}
                                                    onChange={this.userLevelChanged}
                                                    variant="outlined"
                                                    options={this.state.tempUserlevel === 'restricted' ? userOptionsRes: userOptions}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <div style={{ padding: 10, display: 'flex', flexDirection: 'column' }}>
                                           <FormControlLabel
                                                    control={
                                                         <Checkbox
                                                            id="outlined-checkbox"
                                                            value="Locked Out"
                                                            checked={this.state.tempUser.lockout == "Locked Out"}
                                                            onChange={this.userLockoutChanged}
                                                    />
                                                }
                                                label="Locked Out"
                                            />
                                            {this.state.tempUser.level !== 'useradmin' && this.state.tempUser.level !== 'restricted' && <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id="outlined-checkbox"
                                                        value={"Config Allowed"}
                                                        checked={this.state.tempUser.config == "Config Allowed"}
                                                        onChange={this.userConfigChanged}
                                                    />
                                                }
                                                label="Allow user to configure loggers"
                                            />}
                                        </div>
                                    </Grid>
                            </Grid>
                        </Modal.Body>
                        <Modal.Footer>
                                <Button color="primary" variant="contained" style={{marginRight: "10px"}} onClick={(): void => this.saveUser()}>Save</Button>
                            <Button color="secondary" variant="contained" onClick={(): void => this.toggleModal()}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>
                        
                    {this.state.loading &&
                        <div style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}>
                            <ClipLoader
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>

                    }
                    {!this.state.loading &&

                        <div>


                            {this.state.tableHidden &&
                                    <ApiFailed />
                            }
                            {!this.state.tableHidden &&
                                <GridContainer >
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Card>
                                            <CardHeader color="primary" className="view view-cascade  d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
                                        <h4 className={classes.cardTitleWhite}>Users</h4>
                                                    <IconButton aria-label="add" style={{color: "white"}} onClick={(): void => this.newUser()} size="large">
                                                    <AddCircleOutlineIcon />
                                                </IconButton>
                                            </CardHeader>
                                            <CardBody>
                                            <DataGrid

                                                repaintChangesOnly={true}
                                                dataSource={new DataSource({ store: this.store })}
                                                ref={this.gridRef} >
                                                <StateStoring enabled={true} type="localStorage" storageKey="UserListGrid" />
                                                <FilterRow visible={true} />
                                                <HeaderFilter visible={true} />

                                                <Column dataField="email" visible={true} dataType="string" />
                                                <Column dataField="level" visible={true} dataType="string" />
                                                <Column dataField="lockout" visible={true} dataType="boolean" />
                                                <Column dataField="config" visible={true} customizeText={this.configCustomizeText} dataType="string" caption="Can Config" />
                                                <Column dataField="lastLogin" visible={true} dataType="date" customizeText={this.dateColumnCustomizeText} allowHeaderFiltering={false}/>

                                                <Column fixed={true} fixedPosition='right' type="buttons" cellRender={this.moreRender.bind(this)} />
                                                           

                                                <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                                <Paging defaultPageSize={10} />
                                            </DataGrid>
                                            </CardBody>
                                        </Card>
                                    </GridItem>
                                        
                                </GridContainer>
                            }

                        </div>
                    }


                    <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleClose}
                            disableScrollLock={true}
                    >
                            <MenuItem onClick={this.editUser.bind(this)}>Quick Edit</MenuItem>
                            {this.state.tempUser.level === 'user' && <MenuItem onClick={this.filterGroup.bind(this)}>Filter user view to group</MenuItem>}
                            <MenuItem onClick={this.detailsClick}>Details</MenuItem>
                            <MenuItem onClick={this.togglePasswordModal}>Force password reset</MenuItem>
                        <MenuItem onClick={this.toggleDeleteModal}>Delete</MenuItem>
                    </Menu>

                    <NotificationContainer />
                        
                </div>
                )
                :

           (<Redirect to={{
            pathname: '/admin/UserDetail',
            state: { 
                userName: this.state.tempUser.email,
                userId: this.state.tempUser.id
            }
        }}  />)
           )
            :
            (
                <Redirect to={'/Login'} />
            );

    }

}

export default withStyles(style)(Users);

