import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { withStyles, createStyles } from '@mui/styles';
import ApiFailed from '../../Utils/ApiFailed';
import { DataGrid, Column, FilterRow, Pager, Paging, StateStoring, HeaderFilter } from 'devextreme-react/data-grid';

//Moment date/time formatting
//https://momentjs.com/docs/
import Moment from 'moment';

import ClipLoader from "react-spinners/ClipLoader";
//modal window imports
import Grid from '@mui/material/Grid';
import Modal from 'react-bootstrap/Modal'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { TextField, Select, Switch, Menu, MenuItem, Button, FormControl } from '@mui/material';
import FilePicker from 'components/File/FilePicker';
import BackupIcon from '@mui/icons-material/Backup';
import Chip from '@mui/material/Chip';
import { Redirect } from "react-router-dom";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
// import makeAnimated from 'react-select/animated';
import { CreateUrl, CallGetAPI, CallPutAPI, CallDeleteAPI } from 'Utils/ApiHelper.js';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from "devextreme/data/data_source";
import CustomTabs from '../../components/CustomTabs/CustomTabs';
import zIndex from '@mui/material/styles/zIndex';


const styles: Record<any, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);
let fileReader: FileReader;

interface Download {
    id: number;
    title: string;
    category: string;
    filename: string;
    filetype: string;
    product: string;
    model: string;
    adminonly: boolean;
    description: string;
    filecontent: string | null;
}

interface Props {
    classes: {
        cardTitleWhite: string;
        formControl: string;
        cardCategoryWhite: string;
    };
}


interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    visible: boolean;
    filterProduct: string;
    modal: boolean;
    deleteModal: boolean;
    colour: string;
    editDownload: boolean;
    tempDownload: Download;
    anchorEl: HTMLElement | null;
    modalTitle: string;
    allowSave: boolean;
    file: ArrayBuffer;
    fileDownloadUrl: string;
    numEntry: number;
    numManual: number;
    numSpec: number;
    numProg: number;
    numRel: number;
    inProgress: boolean;
}

export class Support extends Component<Props, State> {
    store: ArrayStore;
    gridRef: React.RefObject<DataGrid>;
    dofileDownload: HTMLAnchorElement | null;

    constructor(props: Readonly<Props>) {

        super(props);

        this.store = new ArrayStore({
            key: 'id',
            data: []
        });

        this.state = {
            loading: true,
            tableHidden: false,
            visible: false,
            filterProduct: "All",
            modal: false,
            deleteModal: false,
            colour: '',
            editDownload: false,
            tempDownload: {
                id: 0,
                title: "",
                category: "",
                filename: "No file selected",
                filetype: "",
                product: "",
                model: "All",
                adminonly: false,
                description: "",
                filecontent: null
            },
            anchorEl: null,
            authorized: true,
            modalTitle: "",
            allowSave: false,
            file: new ArrayBuffer(0),
            fileDownloadUrl: "",
            numEntry: 0,
            numManual: 0,
            numSpec: 0,
            numProg: 0,
            numRel: 0,
            inProgress: false,
        };

        this.gridRef = React.createRef();
        this.store.clear();

    }



    createSuccessNotification = (): void => {
        NotificationManager.success('Saved Changes', 'Success')

    };

    createDownloadNotification = (): void => {
        NotificationManager.success('File download in progress', 'Success')

    };

    createErrorNotification = (): void => {
        NotificationManager.error('Error Saving Changes', 'Click me!', 5000, () => {
            alert('callback');
        });
    };

    createFailedInsertErrorNotification = (err: string): void => {
        NotificationManager.error(err, 'Error creating new record', 5000);
    };

    componentDidMount(): void {

        this.reloadData(null);
    }

    // if editId is not null, then reload() re-displays Modal with record id = editId after refresh
    reloadData = (editId: number | null): void => {
        const me = this;

      
        CallGetAPI(CreateUrl('/api/aquaguard/Downloads'), {})
            .then(data => {
                if (data.length > 0) {

                    let numManual = 0, numProg = 0, numSpec = 0, numRel = 0;
                    // Copy the data records into deviceData, adding the clickEvent
                    const records = [];
                    for (let i = 0; i < data.length; i++) {
                        if (!data[i].adminOnly || (data[i].adminOnly && sessionStorage.getItem("userLevel") == "useradmin")) {
                            records.push({ type: 'insert', data: data[i], index: i });

                            switch (data[i].category) {
                                case 'Manual':
                                    numManual++;
                                    break;
                                case 'Spec':
                                    numSpec++;
                                    break;
                                case 'Program':
                                    numProg++;
                                    break;
                                case 'Release Note':
                                    numRel++;
                                    break;
                            }
                        }
                    }

                    me.store.push(records);

                    me.setState(
                        {
                            numEntry: data.length,
                            numManual: numManual,
                            numProg: numProg,
                            numSpec: numSpec,
                            numRel: numRel,
                            tableHidden: false,
                            loading: false
                        })
                }
                else {
                    me.setState(
                        {
                            tableHidden: false,
                            loading: false
                        })
                }
            })
            .catch(function () {
                me.setState(
                    {
                        authorized: true,
                        tableHidden: true,
                        loading: false

                    })
            });


    }

    productChanged = (event: any): void => {

        let numEntry = 0, numManual = 0, numProg = 0, numSpec = 0, numRel = 0;
        const product = event.target.value;

        (this.store as any)._array.forEach((item: Download) => {
            if (item.product == product || item.product == 'All' || product == 'All') {
                numEntry++;
                switch (item.category) {
                    case 'Manual':
                        numManual++;
                        break;
                    case 'Spec':
                        numSpec++;
                        break;
                    case 'Program':
                        numProg++;
                        break;
                    case 'Release Note':
                        numRel++;
                        break;
                }
            }

        });


        this.setState({
            numEntry: numEntry,
            numManual: numManual,
            numProg: numProg,
            numSpec: numSpec,
            numRel: numRel,
            filterProduct: product,
        });
    }


    handleClose = (): void => {
        this.setState({ anchorEl: null });
    }

    checkRemoveDownload = (): void => {
        this.setState({
            anchorEl: null,
            deleteModal: true
        });
    }

    removeDownload = (): void => {

        const me = this;

        CallDeleteAPI(CreateUrl('/api/Aquaguard/Downloads/' + this.state.tempDownload.id), {})
            .then(async response => {
                if (response.status == 200) {

                    me.createSuccessNotification()

                    me.store.push([{ type: 'remove', key: me.state.tempDownload.id }]);

                    me.setState({
                        deleteModal: false,
                    });
                }
                else {
                    me.setState({
                        deleteModal: false,
                    });
                }
                console.log(response)

            })
            .catch(function (error) {
                me.createErrorNotification()
                console.log(error)
                me.setState({
                    deleteModal: false,
                });
            });
    }

    //do nothing, close the modal
    cancel = (): void => {
        this.setState({
            anchorEl: null
        });
        if (this.state.modal)
            this.toggleModal();

    }

    toggleModal = (): void => {
        this.setState({
            modal: !this.state.modal
        });
    }


    removeFile = (): void => {
        const tmpDownload = this.state.tempDownload;
        tmpDownload.filename = 'No file selected';
        tmpDownload.filetype = "";
        this.setState(
            {
                tempDownload: tmpDownload,
            });
    }

    // Download content as file
    doDownload = (id: number): void => {
        const me = this;
        this.setState({ inProgress: true });

        this.store.byKey(id)
            .then((dl) => {

                me.setState({ tempDownload: dl });

                CallGetAPI(CreateUrl('/api/aquaguard/Downloads/' + dl.id), {})
                    .then(data => {
                        if (data.fileContent.length > 0) {

                            const blob = me.b64toBlob(data.fileContent);
                            const fileDownloadUrl = URL.createObjectURL(blob);
                            this.setState({ fileDownloadUrl: fileDownloadUrl, anchorEl: null },
                                () => {
                                    me.dofileDownload?.click();
                                    URL.revokeObjectURL(fileDownloadUrl);  // free up storage--no longer needed.
                                    me.setState({ fileDownloadUrl: "", inProgress: false })
                                })

                            me.createDownloadNotification();
                        }
                        else {
                            console.log("No file content");
                            me.setState({ inProgress: false });
                        }
                    })
                    .catch(function (err) {
                        console.log(err);
                        me.setState({ inProgress: false });
                    });
            });

    }

    b64toBlob = (b64Data: any, contentType = '', sliceSize = 512): Blob => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    //-------------------------

    handleClick(event: any, id: number): void {
        this.store.byKey(id)
            .then((dl) => {

                this.setState(
                    {
                        tempDownload: dl,
                        anchorEl: event.currentTarget,
                    });
                },
                (error) => {
                    console.log(error);
                });
    }

    itemRender(item: any): React.ReactNode {
        return (
            <div>
                <a href="#" onClick={(number): void => this.doDownload(item.key)}>{item.value}</a>
            </div>
            )
    }


    descRender(item: any): React.ReactNode {
        return (
            <div style={{ width: (item.column.width - 20), height: 100, whiteSpace: "normal", overflowWrap: 'break-word', overflowY: "scroll" }}>{item.value}</div>
            )
    }



    render(): React.ReactNode {
        const { classes } = this.props;


        return (this.state.authorized) ? //if we are authorized, show page, else redirect to login page
            (
                <div>

                    <Modal
                        show={this.state.deleteModal}
                        onHide={(): void => this.setState({ deleteModal: false})}
                        dialogClassName="modal-100w"
                        aria-labelledby="example-delete-modal-styling-title"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-delete-modal-styling-title">
                                Confirm deletion
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="show-grid">
                            Remove {this.state.tempDownload.filename} from available downloads?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button color="primary" onClick={(): void => this.removeDownload()} >Remove</Button>
                            <Button color="secondary" onClick={(): void => this.setState({ deleteModal: false })}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>

                    {(this.state.loading || this.state.inProgress) &&
                        <div style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 1000
                        }}>
                            <ClipLoader
                                size={150}
                                color={"#123abc"}
                                loading={true}
                            />
                        </div>

                    }
                    {!this.state.loading &&

                        <div>


                            {this.state.tableHidden &&
                                    <ApiFailed />
                            }
                            {!this.state.tableHidden &&
                                <GridContainer >
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Card>
                                            <CardHeader color="primary" className="view view-cascade  d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
                                                <h4 className={classes.cardTitleWhite}>Support Downloads</h4>
                                            </CardHeader>
                                            <CardBody>
                                        <div style={{ paddingLeft: "20px" }}>
                                            Filter support items for specific product:
                                            <Select
                                                id="outlined-category-name"
                                                style={{ margin: 0, paddingBottom: 0 }}
                                                value={this.state.filterProduct}
                                                onChange={this.productChanged.bind(this)}
                                            >
                                                <MenuItem value="">Select....</MenuItem>
                                                <MenuItem value="All">All</MenuItem>
                                                <MenuItem value="CHFL">CharIOT</MenuItem>
                                                <MenuItem value="TOR-N">TOR-N</MenuItem>
                                                <MenuItem value="SWG">SWG</MenuItem>
                                                <MenuItem value="HYG">HYG</MenuItem>
                                                <MenuItem value="Textlog">Textlog</MenuItem>
                                                <MenuItem value="Other">Other</MenuItem>
                                            </Select>
                                        </div>
                                                <CustomTabs
                                                    headerColor="info"
                                                    tabs={[
                                                        {
                                                            tabName: "All types",
                                                            tabAlerts: this.state.numEntry,
                                                            tabContent: (

                                                                <DataGrid

                                                                    repaintChangesOnly={true}
                                                                    dataSource={new DataSource({ store: this.store })}
                                                                    ref={this.gridRef} >
                                                                    <StateStoring enabled={true} type="localStorage" storageKey="SupportListGrid" />
                                                                    <FilterRow visible={true} />
                                                                    <HeaderFilter visible={true} />


                                                                    <Column dataField="title" visible={true} dataType="string" cellRender={this.itemRender.bind(this)}/>
                                                                    <Column dataField="category" visible={true} dataType="string" groupIndex={1} />
                                                                    <Column dataField="filename" visible={true} dataType="string" width={200} allowHeaderFiltering={false}/>
                                                                    <Column dataField="filetype" visible={true} dataType="string" allowHeaderFiltering={false}/>
                                                                    {this.state.filterProduct == "All" ?
                                                                        <Column dataField="product" visible={true} dataType="string" />
                                                                    :
                                                                        <Column dataField="product" visible={true} dataType="string" selectedFilterOperation="=" filterValues={["All", this.state.filterProduct]} />
                                                                    }
                                                                    <Column dataField="model" visible={true} dataType="string" />
                                                                    <Column dataField="description" visible={true} dataType="string" width={200} cellRender={this.descRender} allowHeaderFiltering={false}/>

                                                                    <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                                                    <Paging defaultPageSize={10} />
                                                                </DataGrid>
                                                            )
                                                        },
                                                        {
                                                            tabName: "Manuals",
                                                            tabAlerts: this.state.numManual,
                                                            tabContent: (
                                                                <DataGrid

                                                                    repaintChangesOnly={true}
                                                                    dataSource={new DataSource({ store: this.store })}
                                                                    ref={this.gridRef} >
                                                                    <FilterRow visible={false} />
                                                                    <HeaderFilter visible={true} />

                                                                    <Column dataField="title" visible={true} dataType="string" cellRender={this.itemRender.bind(this)}/>
                                                                    <Column dataField="category" visible={true} dataType="string" filterValue="Manual" selectedFilterOperation="=" />
                                                                    <Column dataField="filename" visible={true} dataType="string" width={200} allowHeaderFiltering={false}/>
                                                                    <Column dataField="filetype" visible={true} dataType="string" allowHeaderFiltering={false}/>
                                                                    <Column dataField="product" visible={true} dataType="string" />
                                                                    <Column dataField="model" visible={true} dataType="string" />
                                                                    <Column dataField="description" visible={true} dataType="string" width={200} cellRender={this.descRender} allowHeaderFiltering={false} />

                                                                    <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                                                    <Paging defaultPageSize={10} />
                                                                </DataGrid>
                                                                 )
                                                        },
                                                        {
                                                            tabName: "Specifications",
                                                            tabAlerts: this.state.numSpec,
                                                            tabContent: (
                                                                <DataGrid

                                                                    repaintChangesOnly={true}
                                                                    dataSource={new DataSource({ store: this.store })}
                                                                    ref={this.gridRef} >
                                                                    <FilterRow visible={false} />
                                                                    <HeaderFilter visible={true} />

                                                                    <Column dataField="title" visible={true} dataType="string" cellRender={this.itemRender.bind(this)}/>
                                                                    <Column dataField="category" visible={true} dataType="string" filterValue="Spec" selectedFilterOperation="=" />
                                                                    <Column dataField="filename" visible={true} dataType="string" width={200} allowHeaderFiltering={false}/>
                                                                    <Column dataField="filetype" visible={true} dataType="string" allowHeaderFiltering={false} />
                                                                    <Column dataField="product" visible={true} dataType="string" />
                                                                    <Column dataField="model" visible={true} dataType="string" />
                                                                    <Column dataField="description" visible={true} dataType="string" width={200} cellRender={this.descRender} allowHeaderFiltering={false}/>

                                                                    <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                                                    <Paging defaultPageSize={10} />
                                                                </DataGrid>
                                                              )
                                                        },
                                                        {
                                                            tabName: "Utility Programs",
                                                            tabAlerts: this.state.numProg,
                                                            tabContent: (
                                                                <DataGrid

                                                                    repaintChangesOnly={true}
                                                                    dataSource={new DataSource({ store: this.store })}
                                                                    ref={this.gridRef} >
                                                                    <FilterRow visible={false} />
                                                                    <HeaderFilter visible={true} />

                                                                    <Column dataField="title" visible={true} dataType="string" cellRender={this.itemRender.bind(this)}/>
                                                                    <Column dataField="category" visible={true} dataType="string" filterValue="Program" selectedFilterOperation="=" />
                                                                    <Column dataField="filename" visible={true} dataType="string" width={200} allowHeaderFiltering={false}/>
                                                                    <Column dataField="filetype" visible={true} dataType="string" allowHeaderFiltering={false}/>
                                                                    <Column dataField="product" visible={true} dataType="string" />
                                                                    <Column dataField="model" visible={true} dataType="string" />
                                                                    <Column dataField="description" visible={true} dataType="string" width={200} cellRender={this.descRender} allowHeaderFiltering={false}/>

                                                                    <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                                                    <Paging defaultPageSize={10} />
                                                                </DataGrid>
                                                              )
                                                        },
                                                        {
                                                            tabName: "Release Notes",
                                                            tabAlerts: this.state.numRel,
                                                            tabContent: (
                                                                <DataGrid

                                                                    repaintChangesOnly={true}
                                                                    dataSource={new DataSource({ store: this.store })}
                                                                    ref={this.gridRef} >
                                                                    <FilterRow visible={false} />

                                                                    <Column dataField="title" visible={true} dataType="string" cellRender={this.itemRender.bind(this)}/>
                                                                    <Column dataField="category" visible={true} dataType="string" filterValue="Release Note" selectedFilterOperation="=" />
                                                                    <Column dataField="filename" visible={true} dataType="string" width={200} allowHeaderFiltering={false}/>
                                                                    <Column dataField="filetype" visible={true} dataType="string" allowHeaderFiltering={false}/>
                                                                    <Column dataField="product" visible={true} dataType="string" />
                                                                    <Column dataField="model" visible={true} dataType="string" />
                                                                    <Column dataField="description" visible={true} dataType="string" width={200} cellRender={this.descRender} allowHeaderFiltering={false}/>

                                                                    <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                                                    <Paging defaultPageSize={10} />
                                                                </DataGrid>
                                                              )
                                                        },
                                                    ]}
                                                />
                                            </CardBody>
                                        </Card>
                                    </GridItem>

                                </GridContainer>
                            }

                        </div>
                    }


                    <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleClose}
                            disableScrollLock={true}
                    >
                            <MenuItem onClick={this.doDownload.bind(this)}>Download</MenuItem>
                    </Menu>

                    <NotificationContainer />
                    <a style={{display: 'none'}}
                        download={this.state.tempDownload.filename}
                        href={this.state.fileDownloadUrl}
                        ref={e => this.dofileDownload = e}
                    >download it</a>

                </div>
                )

            :
            (
                <Redirect to={'/Login'} />
            );

    }

}

export default withStyles(style)(Support);

