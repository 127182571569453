
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/NewDashboard2023.tsx";
import DndDashboard from "views/Dashboard/DndDashboard.tsx";
import DeviceList from "views/Devices/DeviceList.tsx";
import Groups from "views/Groups/Groups.tsx";
import Sites from "views/Sites/Sites.tsx";
import Reports from "views/Reports/reports"
import Regions from "views/Regions/Regions.tsx";
import MapView from "views/Maps/MapView.tsx";
import Users from "views/Users/Users.tsx";
import SiteDetail from "./views/Sites/SiteDetail.tsx";
import SiteAlarmConfigs from "./views/Sites/SiteAlarms.tsx";
import DeviceDetail from "views/Devices/DeviceDetail.tsx";
import DeviceOverview from "views/Devices/DeviceOverview.tsx";
import GroupDetail from "./views/Groups/GroupDetail.js";
import AlarmList from "views/Alarms/AlarmList.tsx";
import ConnectionsList from "views/Connections/ConnectionsList.tsx";
import NonReportingDeviceList from "views/Devices/NonReportingDeviceList.tsx"
import Contacts from "views/Contacts/Contacts.tsx";
import ContactDetail from "views/Contacts/ContactDetail.tsx";
import Tasks from "views/Tasks/Tasks.tsx";
import UnprocessedBlocks from "views/LogBlocks/UnprocessedBlocksList";
import DeploymentList from "views/Devices/DeploymentList";
import UndeployedDeviceList from "views/Devices/UndeployedLoggers";
import DormantDeviceList from "views/Devices/DormantDeviceList";
import FirmwareUpdates from "views/Devices/FirmwareUpdates";
import UpdatesPending from "views/Devices/UpdatesPending";
import Companies from "views/IdentityAdmin/Companies";
import AdminUsers from "views/IdentityAdmin/AdminUsers";
import Downloads from "views/IdentityAdmin/Downloads";
import Support from "views/Support/Support"
import MultiLoggerGraph from "views/Groups/MultiLoggerGraph";
//import LCLDeviceDetail from "views/Devices/LCLDeviceDetail";
import LCLDeviceDetail from "views/Devices/NewLCLDeviceDetail";
import Firmware from "views/IdentityAdmin/Firmware";
import MQTTResends from "views/IdentityAdmin/MQTTResends";
import SystemInfos from "views/IdentityAdmin/SystemInfos";
import LaiwaDevicesOverview from 'views/IdentityAdmin/LaiwaDevicesOverview'
import SmartCameraOverview from 'views/IdentityAdmin/SmartCameraOverview'

// @mui/icons-material
import Dashboard from "@mui/icons-material/Dashboard";
import Loggers from "@mui/icons-material/SettingsRemote";
import Alarms from '@mui/icons-material/NotificationImportant';
import MapIcon from '@mui/icons-material/Map';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TextureIcon from '@mui/icons-material/Texture';
import PeopleIcon from '@mui/icons-material/People';
import ListAltIcon from '@mui/icons-material/ListAlt';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import PersonIcon from '@mui/icons-material/Person';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import MessageIcon from '@mui/icons-material/Message';
import AssessmentIcon from '@mui/icons-material/Assessment';






const dashboardRoutes = [

    {
        path: "/dashboard",
        name: "Dashboard",
        icon: Dashboard,
        component: DashboardPage,
        layout: "/portal",
        visible: true,
        roles:['user', 'useradmin', 'restricteduser', 'identityadmin'],
        alerts: 0
    },
    {
        path: "/DndDashboard",
        name: "DndDashboard",
        icon: Dashboard,
        component: DndDashboard,
        layout: "/portal",
        visible: true,
        roles: ['identityadmin'],
        alerts: 0
    },
    {
        path: "/map",
        name: "Map",
        icon: MapIcon,
        component: MapView,
        layout: "/portal",
        visible: true,
        roles: ['user', 'useradmin', 'restricteduser'],
        alerts: 0
    },
    {
        path: "/Regions",
        name: "Regions",
        icon: TextureIcon,
        component: Regions,
        layout: "/portal",
        visible: true,
        roles:['user', 'useradmin'],
        alerts: 0
    },
    {
        path: "/Groups",
        name: "Groups",
        icon: GroupWorkIcon,
        component: Groups,
        layout: "/portal",
        visible: true,
        roles:['user', 'useradmin'],
        alerts: 0
    },
    {
        path: "/GroupDetail",
        name: "GroupDetail",
        icon: LocationOnIcon,
        component: GroupDetail,
        layout: "/portal",
        visible: false,
        roles: ['user', 'useradmin'],
        alerts: 0
    },
    {
        path: "/multiLoggerGraph",
        name: "MultiLoggerGraph",
        icon: AutoGraphIcon,
        component: MultiLoggerGraph,
        layout: "/portal",
        visible: false,
        roles: ['user', 'useradmin', 'identityadmin', 'restricteduser'],
        alerts: 0
    },
    {
        path: "/Sites",
        name: "Sites",
        icon: LocationOnIcon,
        component: Sites,
        layout: "/portal",
        visible: true,
        roles: ['user', 'useradmin', 'restricteduser'],
        alerts: 0
    },
    //{
    //    path: "/reports",
    //    name: "Reports",
    //    icon: AssessmentIcon,
    //    component: Reports,
    //    layout: "/portal",
    //    visible: true,
    //    roles: ['user', 'useradmin', 'restricteduser'],
    //    alerts: 0
    //},
    {
        path: "/SiteDetail",
        name: "SiteDetail",
        icon: LocationOnIcon,
        component: SiteDetail,
        layout: "/portal",
        visible: false,
        roles: ['user', 'useradmin', 'restricteduser'],
        alerts: 0
    },
    {
        path: "/SiteAlarmConfigs",
        name: "SiteAlarmConfigs",
        icon: LocationOnIcon,
        component: SiteAlarmConfigs,
        layout: "/portal",
        visible: false,
        roles: ['user', 'useradmin', 'restricteduser'],
        alerts: 0
    },
    {
        path: "/devices",
        name: "Devices",
        icon: Loggers,
        component: DeviceList,
        layout: "/portal",
        id: "devices",
        visible: true,
        roles: ['user', 'useradmin', 'identityadmin', 'restricteduser'],
        alerts: 0
    },
    {
        path: "/DeviceDetail",
        name: "DeviceDetail",
        icon: Loggers,
        component: DeviceDetail,
        layout: "/portal",
        id: "deviceDetail",
        visible: false,
        roles: ['user', 'useradmin', 'identityadmin', 'restricteduser'],
        alerts: 0
    },
    {
        path: "/LCLDeviceDetail",
        name: "LCLDeviceDetail",
        icon: Loggers,
        component: LCLDeviceDetail,
        layout: "/portal",
        id: "lclDeviceDetail",
        visible: false,
        roles: ['user', 'useradmin', 'identityadmin', 'restricteduser'],
        alerts: 0
    },    {
        path: "/DeviceOverview",
        name: "DeviceOverview",
        icon: Loggers,
        component: DeviceOverview,
        layout: "/portal",
        id: "deviceOverview",
        visible: false,
        roles: ['user', 'useradmin', 'identityadmin', 'restricteduser'],
        alerts: 0
    },
    {
        path: "/DeploymentList",
        name: "DeploymentList",
        icon: Loggers,
        component: DeploymentList,
        layout: "/portal",
        id: "DeploymentList",
        visible: false,
        roles: ['user', 'useradmin', 'identityadmin', 'restricteduser'],
        alerts: 0
    },
    {
        path: "/UndeployedLoggers",
        name: "UndeployedLoggers",
        icon: Loggers,
        component: UndeployedDeviceList,
        layout: "/portal",
        id: "UndeployedDeviceList",
        visible: false,
        roles: ['user', 'useradmin', 'identityadmin', 'restricteduser'],
        alerts: 0
    },
    {
        path: "/FirmwareUpdates",
        name: "FirmwareUpdates",
        icon: Loggers,
        component: FirmwareUpdates,
        layout: "/portal",
        id: "FirmwareUpdates",
        visible: false,
        roles: ['user', 'useradmin'],
        alerts: 0
    },
    {
        path: "/UpdatesPending",
        name: "UpdatesPending",
        icon: Loggers,
        component: UpdatesPending,
        layout: "/portal",
        id: "UpdatesPending",
        visible: false,
        roles: ['user', 'useradmin'],
        alerts: 0
    },
    {
        path: "/AlarmList",
        name: "AlarmList",
        icon: Alarms,
        component: AlarmList,
        layout: "/portal",
        id: "alarmList",
        visible: false,
        roles: ['user', 'useradmin', 'restricteduser'],
        alerts: 0
    },
    {
        path: "/ConnectionsList",
        name: "ConnectionsList",
        icon: Loggers,
        component: ConnectionsList,
        layout: "/portal",
        id: "connectionsList",
        visible: false,
        roles: ['user', 'useradmin', 'restricteduser'],
        alerts: 0
    },
    {
        path: "/NonReportingDeviceList",
        name: "NonReportingDeviceList",
        icon: Loggers,
        component: NonReportingDeviceList,
        layout: "/portal",
        id: "NonReportingDeviceList",
        visible: false,
        roles: ['user', 'useradmin', 'restricteduser'],
        alerts: 0
    },
    {
        path: "/DormantDeviceList",
        name: "DormantDeviceList",
        icon: Loggers,
        component: DormantDeviceList,
        layout: "/portal",
        id: "DormantDeviceList",
        visible: false,
        roles: ['user', 'useradmin'],
        alerts: 0
    },
    {
        path: "/Contacts",
        name: "Contacts",
        icon: PeopleIcon,
        component: Contacts,
        layout: "/portal",
        visible: true,
        roles: ['user', 'useradmin', 'restricteduser'],
        alerts: 0
    },
    {
        path: "/ContactDetail",
        name: "ContactDetail",
        icon: PeopleIcon,
        component: ContactDetail,
        layout: "/portal",
        visible: false,
        roles: ['user', 'useradmin', 'restricteduser'],
        alerts: 0
    },
    {
        path: "/UnprocessedBlocks",
        name: "UnprocessedBlocks",
        icon: PeopleIcon,
        component: UnprocessedBlocks,
        layout: "/portal",
        visible: false,
        roles: ['user', 'useradmin'],
        alerts: 0
    },
    {
        path: "/Support",
        name: "Support",
        icon: FilePresentIcon,
        component: Support,
        layout: "/portal",
        visible: true,
        roles: ['user', 'useradmin', 'restricteduser'],
        alerts: 0
    },
    //{
    //    path: "/LogEvents",
    //    name: "Log Readings",
    //    icon: Dashboard,
    //    component: LogEvents,
    //    layout: "/admin"
    //}
    //,
    //{
    //    path: "/user",
    //    name: "User Profile",
    //    icon: Person,
    //    component: UserProfile,
    //    layout: "/admin"
    //},
    //{
    //    path: "/notifications",
    //    name: "Notifications",
    //    icon: Notifications,
    //    component: NotificationsPage,
    //    layout: "/admin"
    //}
    //,
    {
        path: "/Users",
        name: "Users",
        icon: PersonIcon,
        component: Users,
        layout: "/portal",
        visible: true,
        roles: ['useradmin'],
        alerts: 0
    },
    {
        path: "/Tasks",
        name: "Tasks",
        icon: ListAltIcon,
        component: Tasks,
        layout: "/portal",
        visible: true,
        roles: ['useradmin'],
        alerts: 0
    },
    {
        path: "/Companies",
        name: "Companies",
        icon: GroupWorkIcon,
        component: Companies,
        layout: "/portal",
        visible: true,
        roles: ['identityadmin'],
        alerts: 0
    },
    {
        path: "/AdminUsers",
        name: "Manage Users",
        icon: PeopleIcon,
        component: AdminUsers,
        layout: "/portal",
        visible: true,
        roles: ['identityadmin'],
        alerts: 0
    },
    {
        path: "/MqttResends",
        name: "MQTT Resends",
        icon: AllInboxIcon,
        component: MQTTResends,
        layout: "/portal",
        visible: true,
        roles: ['identityadmin'],
        alerts: 0
    },
    {
        path: "/Laiwa",
        name: "Laiwa Devices",
        icon: Loggers,
        component: LaiwaDevicesOverview,
        layout: "/portal",
        visible: false,
        roles: ['identityadmin', 'user'],
        alerts: 0
    },
    {
        path: "/smartcamera",
        name: "Smart Camera POC",
        icon: Loggers,
        component: SmartCameraOverview,
        layout: "/portal",
        visible: false,
        roles: ['identityadmin', 'user'],
        alerts: 0
    },
    {
        path: "/Downloads",
        name: "Downloads",
        icon: FilePresentIcon,
        component: Downloads,
        layout: "/portal",
        visible: true,
        roles: ['identityadmin'],
        alerts: 0
    },
    {
        path: "/Firmware",
        name: "Firmware",
        icon: FilePresentIcon,
        component: Firmware,
        layout: "/portal",
        visible: true,
        roles: ['identityadmin'],
        alerts: 0
    },
    {
        path: "/SystemInfos",
        name: "System Messages",
        icon: MessageIcon,
        component: SystemInfos,
        layout: "/portal",
        visible: true,
        roles: ['identityadmin'],
        alerts: 0
    }
    
];

export default dashboardRoutes;
