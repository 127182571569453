import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { withStyles, createStyles } from '@mui/styles';
import ApiFailed from '../../Utils/ApiFailed';
import { DataGrid, Column, Export, GroupPanel, Grouping, FilterRow, Pager, Paging, StateStoring, HeaderFilter } from 'devextreme-react/data-grid';

//Moment date/time formatting
//https://momentjs.com/docs/
import Moment from 'moment';
import { adjustTime } from '../../Utils/AdjustTime'

import ClipLoader from "react-spinners/ClipLoader";
//modal window imports
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Modal from 'react-bootstrap/Modal'
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';

import { Redirect } from "react-router-dom";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
// import makeAnimated from 'react-select/animated';
import { CreateUrl, CallGetAPI, CallPutAPI, CallDeleteAPI } from 'Utils/ApiHelper.js';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from "devextreme/data/data_source";


const styles: Record<any, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);

let modalTitle = "";

const userOptions = [
    //{ value: 'browser', label: 'Browser' },
    { value: 'user', label: 'User' },
    { value: 'useradmin', label: 'User Administrator' },
];
const userOptionsAdmin = [
    { value: 'restricted', label: 'Restricted' },
    { value: 'user', label: 'User' },
    { value: 'useradmin', label: 'User Administrator' },
    { value: 'identityadmin', label: 'Super Administrator' },
];

interface User {
    id: number;
    userGuid: string;
    userName: string;
    level: string;
    companyGuid: string;
    companyName: string;
    servers: Array<string>;
    lockedOut: boolean;
    lastLogin: Date | null;
    config: boolean;
}

interface Company {
    id: number;
    companyGuid: string;
    companyName: string;
}

interface Props {
    classes: {
        cardTitleWhite: string;
        formControl: string;
        cardCategoryWhite: string;
    };
}


interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    visible: boolean;
    modal: boolean;
    selectedUser: number;
    colour: string;
    edit: boolean;
    editUser: boolean;
    orgUser: string;
    tempUser: User;
    anchorEl: HTMLElement | null;
    redirect: boolean;
    redirectPath: string;
    redirectProps: {
        refresh: boolean;
    } | null;
    modalTitle: string;
    deleteModal: boolean;
    gwModal: boolean;
    viewDetail: boolean;
    passwordModal: boolean;
    newGateway: string;
    roleModal: boolean;
    userRole: string;
    lockedOut: boolean;
    
}

export class AdminUsers extends Component<Props, State> {
    store: ArrayStore;
    gridRef: React.RefObject<DataGrid>;
    companies: Array<Company> = [];

    constructor(props: Readonly<Props>) {

        super(props);

        this.store = new ArrayStore({
            key: 'id',
            data: []
        });

        this.state = {
            loading: true,
            tableHidden: false,
            visible: false,
            modal: false,
            selectedUser: 0,
            colour: '',
//            users: { columns: [], rows: [] },
//            regions: [],
//            devices: [],
            tempUser: {
                id: 0,
                userGuid: '',
                userName: '',
                level: '',
                companyGuid: '',
                companyName: '',
                servers: [],
                lockedOut: false,
                lastLogin: null,
                config: false
            },
            editUser: false,
            edit: false,
            orgUser: '',
            anchorEl: null,
            deleteModal: false,
            gwModal: false,
            passwordModal: false,
            authorized: true,
            redirect: false,
            redirectPath: '',
            redirectProps: null,
            modalTitle: "",
            viewDetail: false,
            newGateway: '',
            roleModal: false,
            userRole: "",
            lockedOut: false,
            
        };

        this.gridRef = React.createRef();
        this.store.clear();

    }

    createSuccessNotification = (): void => {
        NotificationManager.success('Saved Changes', 'Success')

    };

    createErrorNotification = (): void => {
        NotificationManager.error('Error Saving Changes', 'Click me!', 5000, () => {
            alert('callback');
        });
    };

    createFailedInsertErrorNotification = (): void => {
        NotificationManager.error('Error creating new record', '', 5000);
    };

    componentDidMount(): void {

        this.reloadData(null);
        if (sessionStorage.getItem('portalUpdate') === 'true') {
            this.clearState()
        }
    }

    clearState = (): void => {
        this.gridRef.current?.instance.state(null);
    }

    // if editId is not null, then reload() re-displays Modal with record id = editId after refresh
    reloadData = (editId: number | null): void => {
        const me = this;

      
        CallGetAPI(CreateUrl('/api/aquaguard/AdminUsers'), {})
            .then(data => {
                if (data.length > 0) {
                    
                    // Copy the data records into deviceData, adding the clickEvent
                    const records: Array<{
                        type: "insert" | "update" | "remove";
                        data: any;
                        index: number | undefined
                    }> = [];
                    me.store.clear();
                    for (let i = 0; i < data.length; i++) {
                        const id = data[i].id;

                        //no need to show other identity admin users
                        if (data[i].level.toLowerCase() != 'identityadmin') {
                        const rec: User = {
                            id: id,
                            userGuid: data[i].userId,
                            userName: data[i].userName,
                            level: data[i].level.toLowerCase(),
                            companyGuid: data[i].companyGuid,
                            companyName: data[i].companyName,
                            servers: data[i].servers,
                            lockedOut: data[i].lockedOut,
                            lastLogin: adjustTime(data[i].lastLogin),
                            config: data[i].canConfig
                        };
                            records.push({ type: 'insert', data: rec, index: i });
                            
                        }
                    }

                    me.store.push(records);

                    me.setState(
                        {
                            tableHidden: false,
                            loading: false,
                        })
                }
                else {
                    me.setState(
                        {
                            tableHidden: false,
                            loading: false
                        })
                }
            })
            .catch(function () {
                me.setState(
                    {
                        authorized: false
                    })
            });


        CallGetAPI(CreateUrl('/api/aquaguard/Companies'), {})
            .then(data => {
                if (data.length > 0) {

                    me.companies = [];
                    // Copy the data records into companies
                    for (let i = 0; i < data.length; i++) {
                        const id = data[i].id;

                        const rec = {
                            id: id,
                            companyGuid: data[i].companyGuid,
                            companyName: data[i].name,

                        };

                        me.companies.push(rec);
                    }

                    me.companies.sort((a: Company, b: Company) => {
                        if (a.companyName < b.companyName) { return -1; }
                        if (a.companyName > b.companyName) { return 1; }
                        return 0;
                    });
                }
            })

    }

    handleClose = (): void => {
        this.setState({ anchorEl: null });
    }

    //new row
    newUser = (): void => {
        this.setState({
            modal: true,
            editUser: false,
            tempUser: {
                id: 0,
                userGuid: '',
                userName: '',
                level: 'user',
                companyGuid: '',
                companyName: '',
                servers: [],
                lockedOut: false,
                lastLogin: null,
                config: false
            },
            modalTitle: "New User"
        });

    }

    //edit row
    editUser = (): void => {
        this.setState({
            editUser: true,
            modal: !this.state.modal,
            anchorEl: null,
            modalTitle: "Edit User",
            orgUser: this.state.tempUser.userName,
        });
    }

    //save changes, for both edit and new
    saveUser = (): void => {

        const me = this;

        if (this.state.editUser === false) { //adding a new User

            //********************
            //send model to API
          
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    Username: me.state.tempUser.userName,
                    CompanyGuid: me.state.tempUser.companyGuid,
                    Level: me.state.tempUser.level,
                    CanConfig: me.state.tempUser.level === 'user' ? false : true
                })
            };

            let apiURLroot = '';
            if (me.state.tempUser.level == 'user') apiURLroot = '/api/aquaguard/User';
            if (me.state.tempUser.level == 'useradmin') apiURLroot = '/api/aquaguard/AdminUser';


            CallGetAPI(CreateUrl(apiURLroot), requestOptions)
                .then(data => {
                    if (data.status === undefined) {

                        const user = me.state.tempUser;
                        user.id = data.id
                        user.userGuid = data.userGUID;
                        user.companyGuid = data.fkCompany.companyGUID;
                        user.userName = data.email;
                        user.level = data.level;
                        user.companyName = data.fkCompany.name;
                        user.servers = data.servers;
                        user.lockedOut = data.lockout,
                        user.lastLogin = data.lastLogin,

                        me.store.push([{ type: 'insert', key: user.id, data: user }]);

                        me.toggleModal();
                        me.setState({
                            tempUser: user,
                            loading: false,
                        });
                        me.createSuccessNotification()

                        //to refresh the grid properly
                        //me.reloadData(null);

                    }
                    else {
                        me.createFailedInsertErrorNotification();
                        me.toggleModal();
                        console.log(data.title);
                        me.setState({
                            loading: false,
                        });
                    }
                })

                .catch(function (error) {
                    me.createFailedInsertErrorNotification();
                    console.log(error)
                    me.toggleModal();
                    me.setState({
                        loading: false,
                    });
                });

        }
        else {//modifying an existing User

            //********************
            //send model to API
            
            //save User
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    username: me.state.orgUser,
                    newUsername: me.state.tempUser.userName,
                    userLevel: me.state.tempUser.level,
                    
                })
            };


            CallPutAPI(CreateUrl('/api/Aquaguard/User/' + me.state.tempUser.id), requestOptions)
                .then(async response => {
                    if (response.status == 204) {

                        me.createSuccessNotification()

                        me.store.push([{ type: 'update', key: me.state.tempUser.id, data: me.state.tempUser }]);

                        me.setState({
                            modal: !me.state.modal,
                            loading: false,
                        });
                    }
                    else {
                        me.toggleModal();
                        me.setState({
                            loading: false,
                        });
                    }
                    console.log(response)

                })
                .catch(function (error) {
                    me.createErrorNotification()
                    me.toggleModal();
                    console.log(error)
                    me.setState({
                        loading: false,
                    });
                });

        }
    }

    addGateway = (): void => {

        const me = this;

        //********************
        //send model to API

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                userId: this.state.tempUser.userGuid,
                userName: this.state.tempUser.userName,
                companyId: this.state.tempUser.companyGuid,
                userlevel: this.state.tempUser.level,
                serverUrl: this.state.newGateway,
                sendmail: true
            })
        };

        CallGetAPI(CreateUrl('/api/aquaguard/RegisterUserGateway'), requestOptions)
            .then(data => {
                if (data.status === undefined) {
                    const user = me.state.tempUser;
                    user.servers.push(me.state.newGateway);

                    me.store.push([{ type: 'update', key: user.id, data: user }]);

                    me.setState({
                        tempUser: user,
                    });
                    me.toggleGwModal();
                    me.createSuccessNotification()
                }
                else {
                    me.createFailedInsertErrorNotification();
                    me.toggleGwModal();
                    console.log(data.title);

                }
            })

            .catch(function (error) {
                me.createFailedInsertErrorNotification();
                console.log(error)
                me.toggleGwModal();
                me.setState({
                    loading: false,
                });
            });

    }

    //do nothing, close the modal
    cancel = (): void => {
        this.toggleModal();
    }

    toggleModal = (): void => {
        this.setState({
            modal: !this.state.modal
        });
    }

    toggleDeleteModal = (): void => {
        this.setState({
            deleteModal: !this.state.deleteModal,
            anchorEl: null
        });
        modalTitle = "Remove User";

        this.reloadData(null);
    }

    toggleGwModal = (): void => {
        this.setState({
            gwModal: !this.state.gwModal,
            anchorEl: null
        });
    }

    detailsClick = (): void => {
        this.setState(
            {
                viewDetail: true,
            });
    }

    deleteRow = (): void => {

        const me = this;

        //delete user
        CallDeleteAPI(CreateUrl('/api/Aquaguard/User?id=' + me.state.tempUser.id), {})
            .then(async response => {
                if (response.status == undefined) {

                    me.reloadData(null);

                    me.createSuccessNotification()

                    //remove the old version of the row
                    //this.store.push([{ type: 'remove', key: me.state.tempUser.id }]);


                }
                //me.reloadData(null);
                me.toggleDeleteModal();
                console.log(response)

            })
            .catch(function (error) {
                me.createErrorNotification()
                console.log(error)
            });
    }

    //field changed events---
    userNameChanged = (event: any): void => {
        const tempUser = this.state.tempUser;
        tempUser.userName = event.target.value;
        this.setState( {
            tempUser: tempUser
            });
    }

    userCompanyChanged = (selectedOption: any): void => {
        const tempUser = this.state.tempUser;

        tempUser.companyGuid = selectedOption.target.value;
        tempUser.companyName = this.companies.filter(c => c.companyGuid == tempUser.companyGuid)[0].companyName;
        this.setState( {
            tempUser: tempUser
            });
    }

    userLevelChanged = (selectedOption: any): void => {
        const tempUser = this.state.tempUser;

        tempUser.level = selectedOption.target.value;
        this.setState({
            tempUser: tempUser,
        });
    }

    gatewayChanged = (event: any): void => {
        this.setState({
            newGateway: event.target.value
        });
    }

    //-------------------------

    togglePasswordModal = (): void => {
        this.setState({
            passwordModal: !this.state.passwordModal,
            anchorEl: null
        });
        modalTitle = "Password Reset";
    }

    resetPassword = (): void => {

        const me = this;

        //reset user's password

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                UserId: this.state.tempUser.id,
                UserName: this.state.tempUser.userName,
                newPassword: "Passw0rd!",
                oldPassword: "",
                userGuid: this.state.tempUser.userGuid,
            })
        };

        CallGetAPI(CreateUrl('/api/aquaguard/UserPassword'), requestOptions)
            .then(() => {           
                    me.createSuccessNotification();

                    me.setState({
                        passwordModal: !me.state.passwordModal,
                    });
                },
                // Reject
                () => {
                    me.createErrorNotification();
                    me.setState({
                        passwordModal: !me.state.passwordModal,
                    });
                })
            .catch(function (error) {
                me.createErrorNotification();
                console.log(error);
                me.setState({
                    passwordModal: !me.state.passwordModal,
                });
            });
    }

    impersonateUser = (): void => {

        window.sessionStorage.setItem('impersonateCompany', this.state.tempUser.companyGuid.toString());
        window.sessionStorage.setItem('impersonateUser', this.state.tempUser.userGuid.toString());

        // Force refresh to update NavBar
        window.location.reload();

        this.setState({
            anchorEl: null,
 //           redirect: true,
 //           redirectPath: '/portal/dashboard',
        });
    }

    updateRole = (): void => {

        const me = this;

        //reset user's role
        if (this.state.userRole != this.state.tempUser.level
            || this.state.lockedOut != this.state.tempUser.lockedOut )
        {

            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    UserName: this.state.tempUser.userName,
                    NewUserName: this.state.tempUser.userName,
                    userlevel: this.state.userRole,
                    lockedOut: this.state.lockedOut,
                    
                })
            };

            CallPutAPI(CreateUrl('/api/aquaguard/User/' + this.state.tempUser.id), requestOptions)
                .then(async response => {
                    if (response.status == 204) {
                        me.createSuccessNotification();

                        const user = me.state.tempUser;
                        user.level = me.state.userRole;
                        user.lockedOut = me.state.lockedOut;
                        
                        me.store.push([{ type: 'update', key: me.state.tempUser.id, data: user }]);

                        me.setState({
                            roleModal: !me.state.roleModal,
                        });
                    }
                },
                    // Reject
                    () => {
                        me.createErrorNotification();
                        me.setState({
                            roleModal: !me.state.roleModal,
                        });
                    })
                .catch(function (error) {
                    me.createErrorNotification();
                    console.log(error);
                    me.setState({
                        roleModal: !me.state.roleModal,
                    });
                });
        }
        else
        {
            this.toggleRoleModal();
        }
    }

    toggleRoleModal = (): void => {
        this.setState({
            roleModal: !this.state.roleModal,
            userRole: this.state.tempUser?.level,
            lockedOut: this.state.tempUser?.lockedOut,
            anchorEl: null
        });
        modalTitle = "Update User role";
    }

    roleChanged = (event: any): void => {
        this.setState({
            userRole: event.target.value
        });
    }

    lockoutChanged = (event: any): void => {
        this.setState({
            lockedOut: event.target.checked
        });
    }

    


    handleClick(event: any, id: number): void {
        console.log(event)
        this.store.byKey(id)
        
            .then((user) => {

                this.setState(
                    {
                        tempUser: user,
                        anchorEl: event.currentTarget
                    });
                },
                (error) => {
                    console.log(error);
                });
    }

    dateColumnCustomizeText(cellInfo: any): string {
        if (cellInfo.value == null)
            return "";
        else
            return Moment(cellInfo.value).format("DD/MM/YYYY HH:mm");
    }

    configColumnCustomizeText(cellInfo: any): string {
        const text = cellInfo.value ? "Yes" : "No"
        return text
    }

    renderServers(cellInfo: any): React.ReactNode {

        const render: Array<React.ReactFragment> = [];
        if (cellInfo.value != null)
        {
            if ((cellInfo.value as Array<string>).length == 0) {
                render.push(<p>ALL</p>)
            }
            else {
                (cellInfo.value as Array<string>).map((s, i) => {
                    render.push(<p key={i}>{s}</p>)
                });
            }
        }
        return (<div>{render}</div>)
    }

    moreRender(Task: any): React.ReactNode {
        return (
            <IconButton size="small" onClick={(e): void => { this.handleClick(e, Task.data.id); }}><MoreHorizIcon /></IconButton>
        );
    }

    render(): React.ReactNode {
        const { classes } = this.props;


        return (this.state.authorized) ? //if we are authorized, show page, else redirect to login page
            (
              (!this.state.redirect) ?
               (<div>

                    <Modal
                        show={this.state.gwModal}
                        onHide={(): void => this.toggleGwModal()}
                        dialogClassName="modal-50w"
                        aria-labelledby="example-custom-modal-styling-title"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                Add new API Gateway
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="show-grid">
                            <Grid container spacing={2}>
                                <Grid item xs={12} >
                                    <div style={{ padding: 10 }}>
                                        <TextField
                                            id="outlined-input"
                                            label="Gateway URL"
                                            value={this.state.newGateway}
                                            onChange={this.gatewayChanged}
                                            variant="outlined"
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Modal.Body>
                        <Modal.Footer>
                                <Button color="primary" variant="contained" style={{marginRight: "10px"}} onClick={(): void => this.addGateway()}>Add Gateway</Button>
                                <Button color="secondary" variant="contained" onClick={(): void => this.toggleGwModal()}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>

                        <Modal
                            show={this.state.roleModal}
                            onHide={(): void => this.toggleRoleModal()}
                            dialogClassName="modal-50w"
                            aria-labelledby="example-custom-modal-styling-title"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-custom-modal-styling-title">
                                    Update User Role
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="show-grid">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} >
                                        <div style={{ padding: 10 }}>
                                        <p>User Role</p>
                                            <Select
                                                id="outlined-select"
                                                label="New Role"
                                                value={this.state.userRole}
                                                onChange={this.roleChanged}
                                                variant="outlined"
                                                style={{ width: "100%" }}
                                            >
                                                {userOptionsAdmin.map((option): React.ReactNode => {
                                                    return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                                })
                                                }
                                            </Select>
                                            
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <div style={{ padding: 10, display: "flex", flexDirection: "column" }}>
                                        <div>
                                            Locked Out:
                                            <Checkbox
                                                id="outlined-select"
                                                checked={this.state.lockedOut}
                                                onChange={this.lockoutChanged}
                                                style={{ width: 50 }}
                                                />
                                            </div>
                                            
                                        </div>
                                    </Grid>
                                </Grid>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button color="primary" variant="contained" style={{ marginRight: "10px" }} onClick={(): void => this.updateRole()}>Update User</Button>
                                <Button color="secondary" variant="contained" onClick={(): void => this.toggleRoleModal()}>Cancel</Button>
                            </Modal.Footer>
                        </Modal>
                        
                    <Modal
                        show={this.state.deleteModal}
                        onHide={(): void => this.toggleDeleteModal()}
                        dialogClassName="modal-50w"
                        aria-labelledby="example-custom-modal-styling-title"
                        centered
                    >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            {modalTitle}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="show-grid">
                        Are you sure?
                    </Modal.Body>
                    <Modal.Footer>
                                <Button color="primary" variant="contained" style={{ marginRight: "10px" }} onClick={(): void => { this.deleteRow()}}>Continue</Button>
                            <Button color="secondary" variant="contained" onClick={(): void => this.toggleDeleteModal()}>Cancel</Button>
                    </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.passwordModal}
                        onHide={(): void => this.togglePasswordModal()}
                        dialogClassName="modal-50w"
                        aria-labelledby="example-custom-modal-styling-title"
                        centered
                    >

                    <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                {modalTitle}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="show-grid">
                            User {this.state.tempUser.userName} will have password reset to <b>Passw0rd!</b> and be required to reset a secure password when they next login.<br/>
                            Do you want to continue?
                        </Modal.Body>
                        <Modal.Footer>
                                <Button color="primary" variant="contained" style={{ marginRight: "10px" }} onClick={(): void => this.resetPassword()}>Continue</Button>
                            <Button color="secondary" variant="contained" onClick={(): void => this.togglePasswordModal()}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modal}
                        onHide={(): void => this.toggleModal()}
                        dialogClassName="modal-50w"
                        aria-labelledby="example-custom-modal-styling-title"
                        centered
                        >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            {"Add a Company User"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="show-grid">
                            <Grid container spacing={2}>
                                <Grid item xs={12} >
                                    <div style={{ padding: 10 }}>
                                        <InputLabel>Name (Email):</InputLabel>
                                        <TextField
                                            id="outlined-input"
                                            value={this.state.tempUser.userName}
                                            onChange={this.userNameChanged}
                                            variant="outlined"
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} >
                                    <div style={{ padding: 10 }}>
                                        <InputLabel>Select Company:</InputLabel>
                                        <Select
                                            id="outlined-select"
                                            label="company"
                                            value={this.state.tempUser.companyGuid}
                                            onChange={this.userCompanyChanged}
                                            variant="outlined"
                                            style={{width: "100%"}}
                                        >
                                        { this.companies.map((option): React.ReactNode => {
                                            return <MenuItem key={option.companyGuid} value={option.companyGuid}>{option.companyName}</MenuItem>
                                            })
                                        }
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid item xs={12} >
                                    <div style={{ padding: 10 }}>
                                        <InputLabel>Select Role:</InputLabel>
                                        <Select
                                            id="outlined-select"
                                            label="Level"
                                            //defaultValue={this.state.tempUserlevel}
                                            value={this.state.tempUser.level}
                                            onChange={this.userLevelChanged}
                                            variant="outlined"
                                            style={{ width: "100%" }}
                                            //options={userOptions}
                                        >
                                            {userOptions.map((option): React.ReactNode => {
                                                return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                            })
                                            }
                                        </Select>
                                    </div>
                                </Grid>
                        </Grid>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button color="primary" variant="contained" style={{ marginRight: "10px" }} onClick={(): void => this.saveUser()}>Save</Button>
                        <Button color="secondary" variant="contained" onClick={(): void => this.toggleModal()}>Cancel</Button>
                    </Modal.Footer>
                    </Modal>

                    {this.state.loading &&
                    <div style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <ClipLoader
                            size={150}
                            color={"#123abc"}
                            loading={this.state.loading}
                        />
                    </div>
                }
                {!this.state.loading &&
                    <div>
                        {this.state.tableHidden &&

                                <ApiFailed />
                        }
                        {!this.state.tableHidden &&
                            <GridContainer >
                                <GridItem xs={12} sm={12} md={12}>
                                    <Card>
                                        <CardHeader color="primary" className="view view-cascade  d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
                                        <h4 className={classes.cardTitleWhite}>Manage Users</h4>
                                                    <IconButton aria-label="add" style={{color: "white"}} onClick={(): void => this.newUser()} size="large">
                                                <AddCircleOutlineIcon />
                                            </IconButton>
                                        </CardHeader>
                                        <CardBody>
                                        <DataGrid
                                            allowColumnResizing={true}
                                            repaintChangesOnly={true}
                                            dataSource={new DataSource({ store: this.store })}
                                            ref={this.gridRef} >
                                            <Export enabled={true} />
                                            <StateStoring enabled={true} type="localStorage" storageKey="UserListGrid" />
                                            <FilterRow visible={true} />
                                            <GroupPanel visible={true} />
                                            <Grouping autoExpandAll={true} />
                                            <HeaderFilter visible={true} />

                                            <Column caption="Company" dataField="companyName" visible={true} dataType="string" groupIndex={0} />
                                            <Column caption="User" dataField="userName" visible={true} dataType="string" />
                                            <Column dataField="level" visible={true} caption="Role" dataType="string" />
                                            <Column dataField="lockedOut" visible={true} caption="Locked Out" dataType="string" />
                                            <Column dataField="lastLogin" visible={true} caption="Last Login" dataType="datetime" customizeText={this.dateColumnCustomizeText}/>
                                            <Column dataField="servers" visible={false} caption="API Servers" dataType="string" cellRender={this.renderServers.bind(this)} />
                                            <Column dataField="config" visible={true} caption="Can config" dataType="string" customizeText={this.configColumnCustomizeText} />
                                            <Column fixed={true} fixedPosition='right' type="buttons" cellRender={this.moreRender.bind(this)} />
                                                        

                                            <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                            <Paging defaultPageSize={20} />
                                        </DataGrid>
                                        </CardBody>
                                    </Card>
                                </GridItem>

                            </GridContainer>
                        }

                    </div>
                }
                <Menu
                    id="simple-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}
                        disableScrollLock={true}
                >
                        {
                            /*<MenuItem onClick={this.editUser.bind(this)}>Quick Edit</MenuItem>*/
                        }
                        {
                            /*<MenuItem onClick={this.toggleGwModal}>Add New Gateway</MenuItem>*/
                        }
                        {/*<MenuItem onClick={this.detailsClick}>Details</MenuItem>*/}
                        <MenuItem onClick={this.impersonateUser}>Impersonate</MenuItem>
                        <MenuItem onClick={this.toggleRoleModal.bind(this)}>Edit User</MenuItem>
                        <MenuItem onClick={this.togglePasswordModal}>Force password reset</MenuItem>
                        <MenuItem onClick={this.toggleDeleteModal}>Delete</MenuItem>
                </Menu>

                <NotificationContainer />

                </div>)
              :
                (<Redirect push to={{
                    pathname: this.state.redirectPath,
                    state: this.state.redirectProps
                }} />)
            )
            :
            (
                <Redirect to={'/Login'} />
            );

    }

}

export default withStyles(style)(AdminUsers);

